export const setSubscribermutation = (orgId, name, email, phone, category,) => {
    return {
        query: `
            mutation CreateSubscriber($subscriberInput: SubscriberInput!) {
                createSubscriber(subscriberInput: $subscriberInput) {
                    _id
                    name
                    email
                    phone
                }
            }
        `,
        variables: {
            subscriberInput: {
                org_id: orgId,
                name: name,
                email: email,
                phone: phone,
                category: category,
            },
        },
    };
};
export const setExcelSubscribermutation = (orgId, category, data) => {
    return {
        query: `
        mutation CreateSubscriber($orgId: ID!, $category: ID!, $subscriberInput: [SubscriberExcelInput!]) {
            createSubscriberExcel(org_id: $orgId, category: $category, subscriberInput: $subscriberInput) {
              _id
              name
            }
          }
        `,
        variables: {
            orgId,
            category,
            subscriberInput: data,
        },
    };
};

export const resetSubscribermutation = (subscriberId, name, email, phone, category) => {
    var updateSubscriberInput = {
        name:name,
        phone: phone,
        email: email,
        category: category
    }
    return {
        query: `
        mutation UpdateSubscriber($subscriberId: ID!, $updateSubscriber: SubscriberInput2!) {
            updateSubscriber(subscriber: { subscriberId: $subscriberId, updateSubscriber: $updateSubscriber }) {
              _id
              name
              email
              phone
              category
            }
          }
        `,
        variables: {
            subscriberId: subscriberId,
            updateSubscriber: updateSubscriberInput,
        },
    };
};

export const delSubscribermutation = (subscriberId) => {
    return {
        query: `
        mutation {
            deleteSubscriber(subscriberId: "${subscriberId}") {
              _id
              name
              deleted
            }
          }
        `,
        variables: {
            subscriberId: subscriberId,
        },
    };
};

