// Define your GraphQL queries here
export const getCategoryQuery = (orgId, page, rowPerPage) => {
  return {
    query: `
      query {
        categorysPagination(org_id: "${orgId}", page: ${parseInt(page)}, rowPerPage: ${parseInt(rowPerPage)}) {
          totalCategories
          categorys {
            _id,
            name,
            department {
              _id,
              name
            }
          }
        }
      }
    `,
    variables: {
      org_id: orgId,
      page: parseInt(page),
      rowPerPage: parseInt(rowPerPage),
    },
  };
};
export const getAllCategoryQuery = (orgId) => {
  return {
    query: `
      query {
        categorys(org_id: "${orgId}") {
          _id
          name
        }
      }
    `,
    variables: {
      org_id: orgId,
    },
  };
};

