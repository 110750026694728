export const setSendEmailmutation = (orgId, temp_name, temp_body, temp_type, category) => {
    return {
        query: `
            mutation CreateSendEmail($sendEmailInput: SendEmailInput!) {
                createSendEmail(sendEmailInput: $sendEmailInput) {
                    _id
                    temp_body
                    temp_name
                    temp_type
                }
            }
        `,
        variables: {
            sendEmailInput: {
                org_id: orgId,
                temp_name: temp_name,
                temp_body: temp_body,
                temp_type: temp_type,
                category: category,
            },
        },
    };
};
export const setExcelSendEmailmutation = (orgId, category, data) => {
    return {
        query: `
        mutation CreateSendEmail($orgId: ID!, $category: ID!, $sendEmailInput: [SendEmailExcelInput!]!) {
            createSendEmailExcel(org_id: $orgId, category: $category, sendEmailInput: $sendEmailInput) {
              _id
              name
            }
          }
        `,
        variables: {
            orgId,
            category,
            sendEmailInput: data,
        },
    };
};

export const resetSendEmailmutation = (sendEmail_id, name, email, phone, category) => {
    var sendEmailId = sendEmail_id
    var updateSendEmailInput = {
        name: name,
        email: email,
        phone: phone,
        category: category
    }
    return {
        query: `
        mutation UpdateSendEmail($sendEmailId: ID!, $updateSendEmail: SendEmailInput2!) {
            updateSendEmail(sendEmail: { sendEmailId: $sendEmailId, updateSendEmail: $updateSendEmail }) {
              _id
              name
              email
              phone
              category
            }
          }
        `,
        variables: {
            sendEmailId,
            updateSendEmail: updateSendEmailInput,
        },
    };
};

export const delSendEmailmutation = (sendEmailId) => {
    return {
        query: `
        mutation {
            deleteSendEmail(sendEmailId: "${sendEmailId}") {
              _id
              temp_name
              deleted
            }
          }
        `,
        variables: {
            sendEmailId: sendEmailId,
        },
    };
};
//------------------------------------------------------------------Campaign--------------------//
export const setSendIndividualEmailmutation = (name, subject, email_type, reciver_id, sending_account_id, template, template_id, category, org_id, attachmentType, attachment) => {
    return {
        query: `
            mutation sendCampaignIndividual($campaignInput: CampaignInput!) {
                sendCampaignIndividual(campaignInput: $campaignInput) {
                    _id
                   name
                   reciver_id
                }
            }
        `,
        variables: {
            campaignInput: {
                name: name,
                subject: subject,
                email_type: email_type,
                reciver_id: reciver_id,
                sending_account_id: sending_account_id,
                template: template,
                template_id: template_id,
                category: category,
                org_id: org_id,
                attachmentType: attachmentType,
                attachment: attachment,
            },
        },
    };
};
export const setSendCampaignEmailmutation = (name, subject, email_type, reciver_id, sending_account_id, template, template_id, category, org_id, attachmentType, attachment) => {
    return {
        query: `
            mutation sendCampaignCategory($campaignInput: CampaignInput!) {
                sendCampaignCategory(campaignInput: $campaignInput) {
                    _id
                   name
                   reciver_id
                }
            }
        `,
        variables: {
            campaignInput: {
                name: name,
                subject: subject,
                email_type: email_type,
                reciver_id: reciver_id,
                sending_account_id: sending_account_id,
                template: template,
                template_id: template_id,
                category: category,
                org_id: org_id,
                attachmentType: attachmentType,
                attachment: attachment,
            },
        },
    };
};
export const setSendBulkEmailmutation = (name, subject, email_type, reciver_id, sending_account_id, template, template_id, category, org_id, attachmentType, attachment) => {
    return {
        query: `
            mutation sendCampaignBulk($campaignInput: CampaignInput!) {
                sendCampaignBulk(campaignInput: $campaignInput) {
                    _id
                   name
                   reciver_id
                }
            }
        `,
        variables: {
            campaignInput: {
                name: name,
                subject: subject,
                email_type: email_type,
                reciver_id: reciver_id,
                sending_account_id: sending_account_id,
                template: template,
                template_id: template_id,
                category: category,
                org_id: org_id,
                attachmentType: attachmentType,
                attachment: attachment,
            },
        },
    };
};


