// Define your GraphQL queries here
export const getSubscriberQuery = (orgId, page, rowPerPage, search) => {
  return {
    query: `
      query {
        subscribersPagination(org_id: "${orgId}", page: ${parseInt(page)}, rowPerPage: ${parseInt(rowPerPage)}, search: "${search}") {
          totalSubscribers
          subscribers {
            _id,
            name,
            email,
            phone,
            category_details {
              _id,
              name
            }
          }
        }
      }
    `,
    variables: {
      org_id: orgId,
      page: parseInt(page),
      rowPerPage: parseInt(rowPerPage),
      search: search
    },
  };
};
export const getAllSubscriberQuery = (orgId) => {
  return {
    query: `
      query {
        subscribers(org_id: "${orgId}") {
          _id
          name
        }
      }
    `,
    variables: {
      org_id: orgId,
    },
  };
};
