// redux/reducers.js

import { 
  ALL_TEMPLATE,
  TEMPLATE_LENGTH,
  SEARCH_TEMPLATE
} from './Constant';

const initialState = {
    allTemplate: [],
    templength: '',
    searchTemplate: [],
  };

  export default function reducer(state = initialState, action) {
    switch (action.type) {
    case ALL_TEMPLATE:
      return {
        ...state,
        allTemplate: action.payload, searchTemp: action.payload,
      };
    case TEMPLATE_LENGTH:
      return {
        ...state,
        templength: action.payload
      };
    case SEARCH_TEMPLATE:
      return {
        ...state,
        searchTemp: action.payload,
      };
    default:
      return state;
  }
};
