import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "../components/Vieworg";
import {
  close_snack_bar
} from "../../common/snackbar/action";
import {
  viewAccessByUser,
  uploadOrgLogo,
  viewUserOrgDetails
} from "../actions";
export class Controller extends Component {
  render() {
    return (
      <Login {...this.props} />
    );
  }
};
export const mapStateToProps = store => {
  return {
    login: store.login,
    loader: store.loader,
    snackbar: store.snackbar,
  };
};
export const mapDispatchToProps = dispatch => {
  return {
   
    viewAccessByUser: (user_id) => {
      dispatch(viewAccessByUser(user_id));
    },
    uploadOrgLogo: (org_logo, org_name, org_email, org_num, org_gstno, user_id) => {
      dispatch(uploadOrgLogo(org_logo, org_name, org_email, org_num, org_gstno, user_id));
    },
    viewUserOrgDetails: ( user_id, org_id) => {
      dispatch(viewUserOrgDetails(user_id, org_id));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);