import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "../Components/DocTemplate";
import {
    viewAllOrgCategory,
} from "../../Category/Action";
import {
    createTemplate,
    editTemplate,
    deleteTemplate,
    searchTemplate,
    viewAllTemplate,
    createExcelTemplate
} from "../Action";
export class Controller extends Component {
    render() {
        return (
            <Login {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        subscriber: store.subscriber,
        snackbar: store.snackbar,
        category: store.category,
        department: store.department,
        template: store.template,
    };
};
export const mapDispatchToProps = dispatch => {
    return {

        viewAllOrgCategory: (orgId) => {
            dispatch(viewAllOrgCategory(orgId));
        },
        viewAllTemplate: (orgId, page, rowPerPage, search) => {
            dispatch(viewAllTemplate(orgId, page, rowPerPage, search,"document"));
        },
        createTemplate: (orgId, temp_name, temp_body, temp_type, category) => {
            dispatch(createTemplate(orgId, temp_name, temp_body, temp_type, category,"document"));
        },
        createExcelTemplate: (orgId, category, data) => {
            dispatch(createExcelTemplate(orgId, category, data,"document"));
        },
        editTemplate: (orgId, subscriberId, name, email, phone, category, page, rowPerPage,) => {
            dispatch(editTemplate(orgId, subscriberId, name, email, phone, category, page, rowPerPage,"document"));
        },
        deleteTemplate: (orgId, categoryId, page, rowPerPage) => {
            dispatch(deleteTemplate(orgId, categoryId, page, rowPerPage,"document"));
        },
        searchTemplate: (allCategory, e) => {
            dispatch(searchTemplate(allCategory, e));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);