// redux/reducers.js

import {
    ALL_ACCOUNTS,
    ACCOUNT_LENGTH,
    SEARCH_ACCOUNT
} from './Constant';

const initialState = {
    all_account: [],
    account_length: '',
    search_account:[]
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ALL_ACCOUNTS:
            return {
                ...state,
                all_account: action.payload,search_account: action.payload
            };

        case ACCOUNT_LENGTH:
            return {
                ...state,
                account_length: action.payload,
            };

        case SEARCH_ACCOUNT:
            return {
                ...state,
                search_account: action.payload,
            };

        default:
            return state;
    }
};
