// redux/reducers.js

import {
  ALL_DEPARTMENT,
  DEPARTMENT_LENGTH,
  SEARCH_DEPARTMENT,
  DEPARTMENT
} from './Constant';

const initialState = {
  alldepartment: [],
  department: [],
  deptlength: '',
  searchDept: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ALL_DEPARTMENT:
      return {
        ...state,
        alldepartment: action.payload, searchDept: action.payload,
      };
    case DEPARTMENT:
      return {
        ...state,
        department: action.payload, searchDept: action.payload,
      };
    case DEPARTMENT_LENGTH:
      return {
        ...state,
        deptlength: action.payload
      };
    case SEARCH_DEPARTMENT:
      return {
        ...state,
        searchDept: action.payload,
      };
    default:
      return state;
  }
};
