import React, { Component } from 'react';
import {
    Grid,
    Typography,
    Select,
    MenuItem,
    TextField,
    Button,
} from '@mui/material';
import EmailEditor from 'react-email-editor';
import { Link, useNavigate } from 'react-router-dom';
// import Loader from '../../../common/loader/components/loader';
import TabletMacOutlinedIcon from '@mui/icons-material/TabletMacOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DesktopMacOutlinedIcon from '@mui/icons-material/DesktopMacOutlined';
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import ReactQuill from 'react-quill';
import LoaderCon from "../../../common/loader/containers/loader_cont";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import 'react-quill/dist/quill.snow.css';
// import "./style.css"
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import '../../../common/introCss.scss'

export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const navigate = useNavigate();

        return (
            <Component
                navigate={navigate}
                {...props}
            />
        );
    };

    return Wrapper;
};
class Addtemplate extends Component {
    constructor(props) {
        super(props);
        this.quillRef = null;
        this.state = {
            addtemp: false,
            list: "",
            fields: [],
            Vnum: '',
            theme: 'snow',
            view: 'desktop',
            category: '',
            templateName: '',
            templateBody: '',
            temp_type: "design",
            Preview: false,
            display: false,
            HTMLtemplate: false,
            Texttemplate: false,
            emaileditor: false,
            selectedView: 'desktop',
            visibility: false,
            // list: [
            //     {
            //         orgName: "category1",
            //         gstinNo: "hh",
            //         Email: "category@gmail.com",
            //     },
            //     {
            //         orgName: "category2",
            //         gstinNo: "hh",
            //         Email: "category2@gmail.com",
            //     },
            //     {
            //         orgName: "category3",
            //         gstinNo: "hh",
            //         Email: "category3@gmail.com",
            //     },
            // ],
            pushValue: [],
            isHTML: RegExp.prototype.test.bind(/(<([^>]+)>)/i),

        };
        this.emailEditorRef = React.createRef();
        this.handleTextChange = (value) => {
             
            this.setState({ templateBody: value });
        }
    }

    handleEditorChange = (value) => {
        this.setState({ value });
    };


    handleUndo = () => {
        if (this.quillRef) {
            const quill = this.quillRef.getEditor();
            quill.history.undo();
        }
    };
    handleRedo = () => {
        if (this.quillRef) {
            const quill = this.quillRef.getEditor();
            quill.history.redo();
        }
    };

    startIntro = () => {

        const intro = introJs();
        localStorage.setItem("create_email_intro", true)
        intro.setOptions({
            steps: [
                {
                    intro: 'Welcome to Email Template! Craft and manage impactful emails effortlessly, whether you are a pro or just starting.',
                },
                {
                    element: '#category', // Replace with the actual element ID or class
                    intro: 'Getting Started: Choose Your Category',
                },
                {
                    element: '#name',
                    intro: 'Personalize Your Campaign: Name Your Template',
                },
                {
                    element: '#option',
                    intro: 'Choose Your Creation Method: Three Options Available',
                },
                {
                    element: '#m1',
                    intro: 'Direct HTML! Code If you are comfortable with HTML, you can write your code directly',
                },
                {
                    element: '#m2',
                    intro: 'Text Editor! Use our text editor to create visually appealing emails without the need for coding. ',
                },
                {
                    element: '#m3',
                    intro: 'HTML Editor! For those who want a balance between control and simplicity, our HTML editor offers a user-friendly interface',
                },
                {
                    element: '#submit',
                    intro: 'Submit and Save: Your Template, Your Way',
                },
            ],
        });
        intro.start();
    };
    //  handleUndo = () => {
    //     if (this.quillRef.current) {
    //       this.quillRef.current.getEditor().history.undo();
    //     }
    //   };

    //  handleRedo = () => {
    //   if (this.quillRef.current) {
    //     this.quillRef.current.getEditor().history.redo();
    //   }
    // };
    renderfield = () => {
        const fields = []
        //   this.setState(()=>this.state.fields.length=i)
 
        for (let index = 0; index < this.state.Vnum; index++) {
            fields.push(


                Math.floor(Math.random() * 10)


            )
        }
         
        return fields
    }


    handleChange = (field, value) => {
        this.setState({ [field]: value });
    };

    handleSubmit = (e) => {
        e.preventDefault();
 
    };


    handleWindowResize = () => {
        const windowWidth = window.innerWidth;

        if (windowWidth >= 1024) {
            this.setState({ view: 'desktop' });
        } else if (windowWidth >= 768) {
            this.setState({ view: 'tablet' });
        } else {
            this.setState({ view: 'mobile' });
        }
    };

    exportHtml = (name, category) => {
        const unlayer = this.emailEditorRef.current?.editor;
        unlayer?.exportHtml((data) => {
            const { html } = data;
            this.setState({ templateBody: html })
             
            this.props.createTemplate(this.props.login.org_id, name, html, this.state.temp_type, category)
            this.props.navigate('/Template')
        });
        this.setState({ name: "", templateBody: "", category: "" })
    };
    componentDidMount() {
        this.props.viewAllOrgCategory(this.props.login.org_id)
        if (localStorage.getItem('create_email_intro') !== "true") {
            this.startIntro()
        }
        // this.props.viewTemplate(this.props.login.org_id)
    }



    render() {
        const metaViewport = document.querySelector('meta[name="viewport"]');
        const { view } = this.state;
        const categories = ['Category 1', 'Category 2', 'Category 3'];
        const { display, HTMLtemplate, Preview, templateBody, Texttemplate, isHTML, Vnum, emaileditor } = this.state
        const modules = {
            toolbar: {
                container:
                    [
                        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                        [{ size: [] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' },
                        { 'indent': '-1' }, { 'indent': '+1' }],
                        ['link', 'image', 'video'],
                        ['clean']
                    ],
            },
        };
        return (
            <Grid>
                <Grid container justifyContent={'left'}>
                    <div style={{ display: "flex", flexDirection: "row", }}>
                        <Link style={{ textDecoration: "none" }} to="/Template">
                            <Typography className='topic2' style={{ color: '#7A7E86' }}>Email Template</Typography>
                        </Link>
                        <ArrowForwardIosIcon style={{ marginTop: '20px' }} />
                        <Link style={{ textDecoration: "none" }} to="">
                            <Typography className='topic' style={{ color: 'black', marginLeft: '-25px' }}>Add Email Template</Typography>
                        </Link>
                    </div>
                </Grid>
                <LoaderCon />
                <Grid container className='page'>
                    <Grid item xs={12} className='box'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} id="category">
                                <Typography style={{ fontWeight: 'bold' }}>
                                    Category
                                </Typography>

                                <Select
                                    fullWidth
                                    value={this.state.category}
                                    onChange={(e) => {
                                        this.setState({ category: e.target.value });
                                    }}
                                    className='select'
                                    sx={{
                                        '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                                            boxShadow: '0px 2px 0px lightgray',
                                        },
                                        marginTop: '-20px',
                                    }}
                                >
                                    {Array.isArray(this.props.category.allCategory) && this.props.category.allCategory.map((element, index) => {
                                        return (<MenuItem key={index + 1} value={element._id}>{element.name}</MenuItem>)
                                    })}
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={6} id="name">
                                <Typography style={{ fontWeight: 'bold' }} >
                                    Name
                                </Typography>
                                <TextField
                                    variant='outlined'
                                    type="text"
                                    fullWidth
                                    placeholder='Enter template name'
                                    className='textfield'
                                    sx={{
                                        '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                                            boxShadow: '0px 2px 0px lightgray',
                                        },
                                    }}
                                    onChange={(event) => {
                                        this.setState({ name: event.target.value });

                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={(HTMLtemplate || Texttemplate) ? 5.9 : 12} style={{ backgroundColor: '#F9F9F9', padding: '20px', borderRadius: '8px', marginTop: '40px' }} id="option">
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: 'space-between'
                                }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                        }}>
                                        <Button
                                            id='m1'
                                            onClick={() => {
                                                this.setState({ HTMLtemplate: true, temp_type: 'html', Texttemplate: false, Preview: "" })
                                            }}
                                            style={{
                                                backgroundColor: HTMLtemplate ? "#d6d6d6" : null,
                                                color: "balck",
                                                fontSize: "10px"
                                            }}
                                            variant="Text">
                                            Html template
                                        </Button>
                                        <Button
                                            id='m2'
                                            onClick={() => {
                                                this.setState({ HTMLtemplate: false, temp_type: "text", Texttemplate: true, Preview: "" })
                                            }}
                                            style={{
                                                backgroundColor: Texttemplate ? "#d6d6d6" : null,
                                                color: "balck",
                                                fontSize: "10px"
                                            }}
                                            variant="Text">
                                            Text template
                                        </Button>
                                        <Button
                                            id='m3'
                                            onClick={() => {
                                                this.setState({ HTMLtemplate: false, temp_type: "design", Texttemplate: false, Preview: "" })
                                            }}
                                            style={{
                                                backgroundColor: (HTMLtemplate || Texttemplate) ? null : "#d6d6d6",
                                                color: "balck",
                                                fontSize: "10px"
                                            }}
                                            variant="Text">
                                            Design  template
                                        </Button>
                                    </div>
                                    {this.state.temp_type === "text" && <div>
                                        <button style={{
                                            backgroundColor: "transparent", border: "none", marginRight: "15px"
                                        }} onClick={() => this.handleUndo()}><UndoOutlinedIcon /></button>
                                        <button style={{
                                            backgroundColor: "transparent", border: "none"
                                        }} onClick={() => this.handleRedo()}><RedoOutlinedIcon /></button>
                                    </div>}
                                </div>
                                {HTMLtemplate ?
                                    <TextField
                                        fullWidth
                                        style={{
                                            margin: "10px"
                                        }}
                                        label={HTMLtemplate ? "Enter HTML code here..." : "Enter Text here..."}
                                        multiline
                                        rows={15}
                                        variant="standard"
                                        value={this.state.templateBody}
                                        onChange={(e) => {
                                            this.handleChange('templateBody', e.target.value)
                                            if (HTMLtemplate === true) {
                                                this.setState({ Preview: this.state.templateBody })
                                            }
                                        }
                                        }

                                    /> :
                                    (Texttemplate ?
                                        <ReactQuill
                                            multiline
                                            rows={10}
                                            theme={this.state.theme}
                                            ref={(el) => { this.quillRef = el; }}
                                            // value={this.state.text}

                                            onChange={(e) => this.handleTextChange(e)}
                                            modules={modules}
                                        />
                                        :
                                        <EmailEditor
                                            ref={this.emailEditorRef}
                                            onReady={this.onReady}
                                        />
                                    )
                                }
                            </Grid>
                            {(HTMLtemplate || Texttemplate) &&
                                <>
                                    <Grid item xs={.2}></Grid>
                                    <Grid item xs={12} sm={5.9} style={{ backgroundColor: '#F9F9F9', padding: '20px', borderRadius: '8px', marginTop: '40px' }}>
                                        <div style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
                                            <div className="view-selector">
                                                <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => { this.setState({ view: 'desktop' }); }}>
                                                    <DesktopMacOutlinedIcon />
                                                </button>
                                                <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => this.setState({ view: 'tablet' })}>
                                                    <TabletMacOutlinedIcon />
                                                </button>
                                                <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => { this.setState({ view: 'mobile' }); }}>
                                                    <PhoneAndroidOutlinedIcon />
                                                </button>
                                            </div>

                                            <iframe
                                                className={`container ${view}`}
                                                title="External Page"
                                                allowFullScreen
                                                srcDoc={isHTML(templateBody) ? templateBody : null}
                                                style={{ width: '100%', height: '100%', border: 'none' }}
                                            ></iframe>
                                        </div>
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <center>
                                    {/* <Link style={{ textDecoration: "none" }} to="/Template"> */}
                                    <Button variant="contained" className='add'
                                        id='submit'
                                        disabled={this.state.category === "" || this.state.name === ""}
                                        onClick={() => {
                                            this.setState({ display: true })
                             
                                            if (this.state.temp_type === "design") {
                                                this.exportHtml(this.state.name, this.state.category)
                                            }
                                            else {
                                                this.props.createTemplate(this.props.login.org_id, this.state.name, this.state.templateBody, this.state.temp_type, this.state.category)
                                                this.setState({ category: "", name: "", templateBody: "" })
                                                this.props.navigate('/Template')

                                            }
                                        }}>
                                        <Typography style={{ color: 'white' }}>Add Template</Typography>
                                    </Button>
                                    {/* </Link> */}

                                </center>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >

        );
    }
}

export default withRouter(Addtemplate);
