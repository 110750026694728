import {
    LOGIN,
    LOGOUT,
    SET_ALL_ORG,
    SET_ORG_ID,
    SET_ORG_DETAILS,
    SET_USER,
    SET_ORG,
    SET_ORG_USER_DEATILS,
    SET_USER_DETAILS,
    HANDLEDRAWER,
    SETMOBILE,
    CLEAR_LOGIN,
    SET_ALL_DATA,
    SET_ORG_USER_DETAILS,
    SET_ORG_DETAILS2,
    VIEW_ALL_STATUS,
    VIEW_ALL_PRIORITY,
    VIEW_ALL_DEPARTMENT,
    SET_DOC,
    SET_WALET_BY_ID,
    SWITCH
    // VIEW_ALL_PROJECT_TYPE
} from "./constant";
import UNIVERSAL from "../config/config";
import { setLoader, unsetLoader } from "../common/loader/action";
import { set_snack_bar } from "../common/snackbar/action";
import 'firebase/storage';
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";



export function fetchToken(token) {
    return (dispatch) => {
        console.log(token)
        dispatch(setLoader());
        return fetch(UNIVERSAL.SSO_URL + "api/v1/loginActivity/fetch_data_token", {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token,
            }
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                if (responseJson.statusCode === (200 || 201)) {
                    dispatch(setTokenData(responseJson.data))
                    dispatch(unsetLoader());
                }
                else if (responseJson.statusCode === (401 || 403 || 409)) {
                    dispatch(onLogout())
                    dispatch(unsetLoader());
                }
                else {
                    dispatch(unsetLoader());
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function setTokenData(payload) {
    payload.user_id.token = payload.token;
    payload.user_id.org_id = payload.org_id._id;
    payload.user_id.type = payload.access_res.type;
    payload.user_id.product = payload.access_res.product;
    localStorage.setItem("token", payload.token);
    console.log(payload)
    return {
        type: LOGIN,
        payload: payload.user_id,
    };
}

export function SwitchAc() {
    return {
        type: SWITCH,
    };
}
export function googleLogin() {
    return (dispatch) => {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        // firebase
        // .auth()
        signInWithPopup(auth, provider)
            .then((res) => {
                // console.log(res);
                let payload = {
                    name: res.user.displayName,
                    email: res.user.email,
                    phone: res.user.phoneNumber,
                    profile_pic: res.user.photoURL
                };
                dispatch(social_login(payload));
                // console.log(res);
            })
            .catch((error) => {
                console.log(error);
            });
    };
}

export function facebookLogin() {
    return (dispatch) => {
        const provider = new FacebookAuthProvider();
        const auth = getAuth();
        // firebase
        //   .auth()
        signInWithPopup(auth, provider)
            .then((res) => {
                // var credential = res.credential;

                // The signed-in user info.
                var user = res.user;

                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                // var accessToken = credential.accessToken;
                let payload = {
                    name: user.displayName,
                    email: user.email,
                    phone: user.phoneNumber,
                    profile_pic: user.photoURL

                };
                dispatch(social_login(payload));
                // console.log(user);
                // console.log("true")

            })
            .catch((error) => {
                console.log(error);
                if (error.email != null || error.email !== "") {
                    let payload = {
                        // name: user.displayName,
                        email: error.email,
                        // contact_no: user.phoneNumber,
                        // profile_pic:user.photoURL

                    };
                    // dispatch(social_login(payload));
                }

                // console.log("error")
            });
    };
}





export function viewAllOrgbyMobile(mobile) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_org_by_mobile", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                mobile: mobile,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    // console.log(responseJson);
                    // dispatch(setuser(responseJson.result));
                    dispatch({ type: SET_ALL_ORG, payload: responseJson.result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    dispatch(unsetLoader());
                } else {
                    dispatch({ type: SET_ALL_ORG, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                dispatch(unsetLoader());
                console.error(error);
            });
    };
}
export function social_login(login) {
    // console.log("social_login", login);
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "social_login", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: login.email,
                name: login.name,
                phone: login.phone,
                profile_pic: login.profile_pic,
                //   type: "U",
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                //   console.log("asilllllllllllllllllllllllllllll",responseJson);
                if (responseJson.status) {
                    dispatch(setAdminLogin(responseJson.result));
                    dispatch(set_snack_bar(true, responseJson.message));
                    // }
                } else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function phone_login(phone) {
    return (dispatch) => {
        console.log("phone", phone)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "phone_login", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",

            },
            body: JSON.stringify({
                phone: phone,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status) {
                    console.log(responseJson.result);
                    dispatch({ type: SETMOBILE, payload: responseJson.result.phone });
                    dispatch(set_snack_bar(true, responseJson.message));
                } else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function setUserDetails(payload) {
    return {
        type: SETMOBILE,
        payload: payload,
    };
}
export function verify_otp(phone, otp) {
    return (dispatch) => {
        console.log('verify_otp action creator called');
        console.log("verify", otp)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "verify_otp", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                phone: phone,
                otp: parseInt(otp),
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("otpppppppppppppppppppp", responseJson)
                if (responseJson.status) {
                    dispatch(setAdminLogin(responseJson.result));
                    console.log("hlwwwwwwwwwwwwwwwwwwwwwwww", responseJson.result);
                    dispatch(set_snack_bar(true, responseJson.message));
                } else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewAccessByUser(user_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_access_by_user", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: user_id
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("otpppppppppppppppppppp", responseJson)
                if (responseJson.status) {
                    dispatch({ type: SET_ORG_USER_DETAILS, payload: responseJson.result });
                    dispatch(set_snack_bar(true, responseJson.message));
                } else {
                    dispatch({ type: SET_ORG_USER_DETAILS, payload: [] });
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}


export function clear_login() {
    return {
        type: CLEAR_LOGIN,
    };
}


export function setAdminLogin(payload) {
    // console.log(payload.user_name)
    localStorage.setItem("user_id", payload._id);
    localStorage.setItem("mobile", payload.mobile);
    localStorage.setItem("email", payload.email_id);
    localStorage.setItem("name", payload.name);
    return {
        type: LOGIN,
        payload: payload,
    };
}
export function setUserType(payload) {
    // console.log(payload.user_name)
    localStorage.setItem("type", payload.type);
    localStorage.setItem("org_id", payload.org_id);

    return {
        type: SET_ORG,
        payload: payload,
    };
}

export function handleDrawerToggle(mobileOpen) {
    mobileOpen = !mobileOpen;
    return {
        type: HANDLEDRAWER,
        payload: mobileOpen,
    };
}
export function onLogout() {
    // localStorage.removeItem("user_id");
    // localStorage.removeItem("phone");
    // localStorage.removeItem("email");
    // localStorage.removeItem("name");
    localStorage.removeItem("token");
    return {
        type: LOGOUT,
    };
}


export function setOrgId(org_id) {
    localStorage.setItem("org_id", org_id);
    return {
        type: SET_ORG_ID,
        payload: org_id,
    };
}
export function uploadOrgLogo(org_logo, org_name, org_email, org_num, org_gstno, user_id) {
    return dispatch => {
        dispatch(setLoader(true));
        if (org_logo !== "") {
            const storageRef = ref(getStorage(), "/org/" + org_logo.name + ".png")
            const uploadimage = uploadBytesResumable(storageRef, org_logo);
            uploadimage.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },

                function () {
                    getDownloadURL(uploadimage.snapshot.ref).then(function (org_logo) {
                        console.log(org_logo)
                        if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(createOrg(org_logo, org_name, org_email, org_num, org_gstno, user_id));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
        else {
            dispatch(createOrg(org_logo, org_name, org_email, org_num, org_gstno, user_id));
        }
    }

}
export function createOrg(org_logo, org_name, org_email, org_num, org_gstno, user_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "create_organization", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                org_logo: org_logo,
                org_name: org_name,
                org_email: org_email,
                org_num: org_num,
                org_gstno: org_gstno,
                user_id: user_id,
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(viewAccessByUser(user_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(viewAccessByUser(user_id));
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    }
}
//Uploadpic
export function uploadImage(admin_id, name, profile_pic) {
    return dispatch => {
        console.log("addtrainer", admin_id, name, profile_pic)
        dispatch(setLoader(true));
        if (profile_pic !== "") {
            const storageRef = ref(getStorage(), "/tags/" + admin_id + Math.random() + ".png")
            const uploadimage = uploadBytesResumable(storageRef, profile_pic);
            uploadimage.on(
                "state_changed",
                function (snapshot) {
                },
                function (error) {
                    console.log(error)
                    //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                    dispatch(setLoader(false));
                },

                function () {
                    getDownloadURL(uploadimage.snapshot.ref).then(function (profile_pic) {
                        console.log(profile_pic)
                        if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
                            // console.log("Adsdsad")
                            dispatch(updateUserProfile(admin_id, name, profile_pic));
                            dispatch(set_snack_bar(true, "Image Uploaded"))
                        }
                        else {
                            dispatch(set_snack_bar(true, "Image not Uploaded"));
                        }
                    });
                }
            );
        }
        else {
            dispatch(updateUserProfile(admin_id, name, profile_pic));
        }
    }

}

// Profile
export function updateUserProfile(admin_id, name, profile_pic) {
    return (dispatch) => {
        console.log("placementadd", admin_id, name, profile_pic)
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "update_user_profile", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: admin_id,
                name: name,
                profile_pic: profile_pic
            })
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.status) {
                    dispatch(setAdminLogin(responseJson.result));
                    dispatch(set_snack_bar(true, responseJson.message));
                    dispatch(unsetLoader());
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function viewUserOrgDetails(user_id, org_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_organization_user", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                user_id: user_id,
                org_id: org_id,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("asilllllllllllllllllllllllllllllllllllllllla", responseJson)
                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    // dispatch(setAdminLogin(responseJson.result.userdetails));
                    dispatch(setUserType(responseJson.result))
                    dispatch({ type: SET_ORG_USER_DETAILS, payload: responseJson.result })
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    // dispatch(unsetLoader(responseJson.result.item_category));
                } else {
                    // dispatch({ type: SET_ORG_USER_DETAILS, payload: [] })
                    dispatch({ type: SET_ORG_USER_DETAILS, payload: {} })

                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function viewWalletByWalletId(wallet_id) {
    console.log("aaaaaaaaaa", wallet_id)
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "view_wallet_by_wallet_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                wallet_id: wallet_id,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log("asilllllllllllllllllllllllllllllllllllllllla", responseJson)
                if (responseJson.status) {
                    // dispatch(setuser(responseJson.result));
                    // dispatch(setAdminLogin(responseJson.result.userdetails));
                    // dispatch({ type: SET_ORG_USER_DETAILS, payload: responseJson.result });
                    dispatch({ type: SET_WALET_BY_ID, payload: responseJson.result });
                    // dispatch({ type: SET_ORG, payload: responseJson.result.org_result });
                    // dispatch(set_snack_bar(responseJson.status, "OTP Sent Successfully"));
                    // dispatch(unsetLoader(responseJson.result.item_category));
                } else {
                    // dispatch({ type: SET_ORG_USER_DETAILS, payload: [] })
                    dispatch({ type: SET_WALET_BY_ID, payload: [] })
                    // dispatch({ type: SET_ORG, payload: [] })
                    // dispatch(set_snack_bar(responseJson.status, "Invalid User"));
                }
                dispatch(unsetLoader());
            })
            .catch((error) => {
                console.error(error);
            });
    };
}

export function login_auth(object_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch("https://sso-backend-zpicrijtna-el.a.run.app/v1/login_auth", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                object_id: object_id
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("otpppppppppppppppppppp", responseJson)
                if (responseJson.status) {
                    dispatch(setAdminLogin(responseJson.result));
                    var payload = {
                        type: responseJson.result.access_details.type,
                        org_id: responseJson.result.access_details.org_id
                    }
                    dispatch(setAccessResult(payload));
                    dispatch({ type: SET_ORG_DETAILS2, payload: responseJson.result.org_details });
                    dispatch(set_snack_bar(true, responseJson.message));
                } else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function setAccessResult(payload) {
    // console.log(payload.user_name)
    localStorage.setItem("type", payload.type);
    localStorage.setItem("org_id", payload.org_id);
    return {
        type: SET_ORG,
        payload: payload,
    };
}


export function logOutByToken(token) {
    return (dispatch) => {
        // console.log(token, "token");
        dispatch(setLoader());
        return fetch(UNIVERSAL.SSO_URL + "api/v1/auth/logout_by_token", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                token: token,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                // console.log(responseJson)
                if (responseJson.statusCode == 200) {
                    dispatch(onLogout())
                } else {
                    dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, responseJson.message));

            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteLoginObj(token) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.SSO_URL + "api/v1/org/switch_org", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token: token,
            },

        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log(responseJson)
                if (responseJson.statusCode === (200 || 201)) {

                    console.log("Switch Org Succesfully");
                    window.location.href = ("https://sso.technoboot.co/" + responseJson.data._id)
                    dispatch(unsetLoader());
                    // dispatch(set_snack_bar(responseJson.status, responseJson.message));
                }
                else if (responseJson.statusCode === 401) {
                    console.log("data Not Found");
                    dispatch(unsetLoader());
                }
            })
            .catch((error) => {
                // dispatch(unsetLoader());
                // dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}