// utils.js

// Import any necessary actions or utilities
import UNIVERSAL from "../config/config";
import { setLoader, unsetLoader, setProgress, unsetProgress } from "../common/loader/action";
import { set_snack_bar } from "../common/snackbar/action";

export const query = async (dispatch, requestBody, successMessage) => {
    dispatch(setProgress());

    try {
        const response = await fetch(UNIVERSAL.BASEURL + "graphql", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query:
                    "query {" +
                    requestBody +
                    "}"
            }),
        });

        const result = await response.json();

        dispatch(unsetProgress());
        dispatch(set_snack_bar(true, successMessage));

        return result;
    } catch (error) {
        console.error(error);
        dispatch(unsetProgress());
        dispatch(set_snack_bar(true, error));
        throw error;
    }
};

export const mutation = async (dispatch, requestBody, successMessage) => {

    dispatch(setLoader());
    try {
        const response = await fetch(UNIVERSAL.BASEURL + "graphql", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                query:
                    "mutation {" +
                    requestBody +
                    "}"
            }),
        });

        const result = await response.json();

        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, successMessage));

        return result;
    } catch (error) {
        console.error(error);
        dispatch(unsetLoader());
        dispatch(set_snack_bar(true, error));


        throw error;
    }
};
