// Define your GraphQL queries here
export const getTemplateQuery = (orgId, page, rowPerPage, search, type) => {
  return {
    query: `
      query {
        templatesPagination(org_id: "${orgId}", page: ${parseInt(page)}, rowPerPage: ${parseInt(rowPerPage)}, search: "${search}", type: "${type}") {
          totalTemplates
          templates {
            _id,
            temp_name,
            temp_body,
            temp_type,
            category_details {
              _id,
              name
            }
          }
        }
      }
    `,
    variables: {
      org_id: orgId,
      type: type,
      page: parseInt(page),
      rowPerPage: parseInt(rowPerPage),
      search: search
    },
  };
};
export const getAllTemplateQuery = (orgId) => {
  return {
    query: `
      query {
        templates(org_id: "${orgId}") {
          _id
          temp_name
          temp_body
          type
        }
      }
    `,
    variables: {
      org_id: orgId,
    },
  };
};
export const getAllCatTemplateQuery = (categoryId) => {
  return {
    query: `
      query {
        templatesByCategoryId(categoryId: "${categoryId}") {
          _id
          temp_name
          temp_body
        }
      }
    `,
    variables: {
      categoryId: categoryId,
    },
  };
};
