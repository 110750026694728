// redux/reducers.js

import { 
  ALL_SUBSCRIBER,
  SUBSCRIBER_LENGTH,
  SEARCH_SUBSCRIBER
} from './Constant';

const initialState = {
    allSubscriber: [],
    sublength: '',
    searchSubscriber: [],
  };

  export default function reducer(state = initialState, action) {
    switch (action.type) {
    case ALL_SUBSCRIBER:
      return {
        ...state,
        allSubscriber: action.payload, searchSub: action.payload,
      };
    case SUBSCRIBER_LENGTH:
      return {
        ...state,
        sublength: action.payload
      };
    case SEARCH_SUBSCRIBER:
      return {
        ...state,
        searchSub: action.payload,
      };
    default:
      return state;
  }
};
