import React, { Component } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
// import { JumpCircleLoading } from 'react-loadingg';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
export default class Loader extends Component {
  render() {
    const { loader } = this.props;
    return (
      <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
        {loader.open && (
          <LinearProgress color='inherit' />
        )}
      </Stack>
    );
  }
}