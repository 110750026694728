import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes, } from "react-router-dom";
import Controller from "../containers/controller_cont";
import Login from "../../auth/container/superadminCont";
import Drawer_option_con from "../containers/drawer_cont";
import Error from "../../404";
// import BreakingNews from "../../pages/News/Container/breakingNewscont";
// import Dashboard from "../../pages/Dashbord/Components/Dashboard";
import Organization from "../../pages/mail/components/Organization";
import Mail from "../../pages/SendMail/Container/SendEmailCont";
import Accountsetting from "../../pages/mail/container/AccountsettingCont";
import Template from "../../pages/Template/Container/EmailTemplateCont";
import Category from "../../pages/Category/Containers/CategoryConst";
import User from "../../pages/mail/container/userCont";
import Admin from "../../pages/mail/container/AdminCont";
import AddTemplate from "../../pages/Template/Container/AddEmailTemplateCont";
import Atlsianlogin from "../../pages/mail/components/atlsianlogin";
import Vieworg from "../../auth/container/VieworgCont";
import Department from "../../pages/Department/Containers/DepartmentConst"
import Users from "../../pages/Users/Containers/UserCont";
import Subscriber from "../../pages/Subscriber/Container/SubscriberContainer";
import AccountS from "../../pages/AccountSetting/Container/AccountSCont";
import Dashboard from "../../pages/Dashbord/Container/DashboardCont";
import Documenttemplate from "../../pages/Template/Container/DoclTemplateCont"
import AddDocumenttemplate from "../../pages/Template/Container/AddDocumentTemplateCont"
import Summary from '../../pages/SendMail/Components/Summary'

// import 
class Routers extends Component {
  render() {
    const { login, onLogout } = this.props;
    let body;

    if (login.isHome) {
      body = (
        <div>
          <main
            style={{
              flexGrow: 1,
            }}
          >
            <Controller />

            <Routes>

              <Route exact path="/" element={<Login />} />
              <Route exact path="/verify/:id" element={<Login />} />
              {/* <Route exact path="/" element={<EmailLogin />} /> */}
              <Route exact path="/Organization" element={<Organization />} />
              <Route exact path="*" element={<Error />} />


            </Routes>

          </main>
        </div>
      );
    }
    else if (login.orgHome) {
      console.log(login.type)
      if (login.type === "Owner" || login.type === "A") {
        // Admin
        body = (
          <div
            style={{
              // display: "flex",
            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                marginTop: "6%",
                marginLeft: "15em"
              }}
            >
              <Controller />
              {/* <Route exact path="/" element={<Dashboard />} /> */}
              <Routes>
                <Route exact path="/Category" element={<Category />} />
                {/* <Route exact path="/" element={'Dashboard'} /> */}
                <Route exact path="/verify/:id" element={<Dashboard />} />
                <Route exact path="/Dashboard" element={<Dashboard />} />
                <Route exact path="/" element={<Dashboard />} />
                <Route exact path="/Department" element={<Department />} />
                <Route exact path="/Users" element={<Users />} />
                <Route exact path="/Subscriber" element={<Subscriber />} />
                <Route exact path="/Mail" element={<Mail />} />
                <Route exact path="/AccountS" element={<AccountS />} />
                <Route exact path="/Template" element={<Template />} />
                <Route exact path="/document_Template" element={<Template />} />
                <Route exact path="/AddTemplate" element={<AddTemplate />} />
                <Route exact path="/AddDocumentTemplate" element={<AddDocumenttemplate />} />
                <Route exact path="/Documenttemplate" element={<Documenttemplate />} />
                <Route exact path="/Summary" element={<Summary />} />
                <Route exact path="/Subscriber" element={<User />} />
                {/* <Route exact path="/admin" element={<Admin />} /> */}
              </Routes>
            </main>

          </div>
        );
      }
      else if (login.type === "Admin" ) {
        // Admin
        body = (
          <div
            style={{
              // display: "flex",
            }}
          >
            <Drawer_option_con type={login.type} onLogout={onLogout} name={login.name} />
            <main
              style={{
                flexGrow: 1,
                // marginTop: "6%",
                marginLeft: "15em"
              }}
            >
              <Controller />
              {/* <Route exact path="/" element={<Login />} /> */}
              <Routes>
                <Route exact path="/Category" element={<Category />} />
                {/* <Route exact path="/" element={''} /> */}
                <Route exact path="/verify/:id" element={<Dashboard />} />
                <Route exact path="/Dashboard" element={<Dashboard />} />
                <Route exact path="/" element={<Dashboard />} />
                <Route exact path="/Department" element={<Department />} />
                {/* <Route exact path="/Users" element={<Users />} /> */}
                <Route exact path="/Subscriber" element={<Subscriber />} />
                <Route exact path="/Mail" element={<Mail />} />
                <Route exact path="/AccountS" element={<AccountS />} />
                <Route exact path="/Template" element={<Template />} />
                <Route exact path="/document_Template" element={<Template />} />
                <Route exact path="/AddTemplate" element={<AddTemplate />} />
                <Route exact path="/AddDocumentTemplate" element={<AddDocumenttemplate />} />
                <Route exact path="/Documenttemplate" element={<Documenttemplate />} />
                <Route exact path="/Summary" element={<Summary />} />
                <Route exact path="/Subscriber" element={<User />} />
                {/* <Route exact path="/admin" element={<Admin />} /> */}
              </Routes>
            </main>

          </div>
        );
      }
    }
    else {
      body = (
        <div
        >
          <main>
            <Controller />
            <Routes>
              <Route exact path="/" element={<Vieworg />} />
            </Routes>
          </main>
        </div>
      );
    }


    return <Router>{body}</Router>;
  }
}

export default Routers;
