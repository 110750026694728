import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from "redux-thunk";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// import Reducer from './src/screens/redux/Reducers';
import login from './auth/reducer';
import snackbar from './common/snackbar/reducer';
import loader from './common/loader/reducer';
import category from './pages/Category/Reducer';
import department from './pages/Department/Reducer';
// import all_news from "./pages/News/reducer";
import mail from "./pages/mail/reducer"
import subscriber from "../src/pages/Subscriber/Reducer"
import account from "./pages/AccountSetting/Reducer"
import user from "./pages/Users/Reducer"
import template from "./pages/Template/Reducer"
import sendEmail from "./pages/SendMail/Reducer"
import dashboard from "./pages/Dashbord/Reducer"





const persistConfig = {
  key: 'root',
  storage,
}

//import banner from "./src/screens/Home/Reducers";

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reducer = combineReducers({
  snackbar,
  loader,
  login,
  mail,
  category,
  department,
  subscriber,
  account,
  user,
  template,
  sendEmail,
  dashboard,
});

const persistedReducer = persistReducer(persistConfig, reducer)
const configureStore = (initialState) => createStoreWithMiddleware(persistedReducer, initialState);
const store = configureStore();
// const persistor = persistStore(store)
// let store = createStore(persistedReducer)
let persistor = persistStore(store)
export default { store, persistor }

