import React, { Component } from "react";
import { Card, Typography, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import "../../Dashbord/Components/dashboard.css";
import cat from "../../image/Cat.png";
import usr from "../../image/users.png";
import sub from "../../image/sub.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Dialog, TextField } from "@mui/material";
import Chart from 'react-apexcharts'
import { Line } from "recharts";
import { Link } from "react-router-dom";


export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addsummary: false,
      addsubscriber: false,
      editsubscriber: false,

      options: {
        chart: {
          id: 'apexchart-example'
        },
        xaxis: {
          categories: ['Subscriber', 'Unsubscriber']
        },
        colors: ['#41B883', '#161412'], // Add your desired bar colors here
      },
      series: [{
        name: 'series-1',
        data: [30, 40,]
      }],
      count: 0,
      count1: 0,
      count2: 0,
    }
  }

  componentDidMount() {
    this.props.ViewDashboardData(this.props.login.org_id);
    this.props.viewProductUser(this.props.login.org_id, "email")
    this.intervalId = setInterval(() => {
      if (this.state.count < this.props.user.admin_user.length) {
        this.setState((prevState) => ({ count: prevState.count + 1 }));
      } else {
        clearInterval(this.intervalId);
      }
    }, 100); // Increase every second (adjust as needed)
    this.intervalId1 = setInterval(() => {
      if (this.state.count1 < this.props.dashboard.category_length) {
        if (this.props.dashboard.category_length === 0) {
          this.setState((prevState1) => ({ count1: 0 }));
        }
        else {
          this.setState((prevState1) => ({ count1: prevState1.count1 + 1 }));
        }
      } else {
        clearInterval(this.intervalId1);
      }
    }, 100);
    this.intervalId2 = setInterval(() => {
      if (this.state.count2 < this.props.dashboard.subscriber_length) {
        if (this.props.dashboard.subscriber_length === 0) {
          this.setState((prevState2) => ({ count2: 0 }));
        }
        else {
          this.setState((prevState2) => ({ count2: prevState2.count2 + 50 }));

        }
      } else {
        clearInterval(this.intervalId2);
      }
    }, 100);
  }


  render() {
    return (
      <>
        <Grid className="main-container">
          <Grid>
            <Link to={"/Users"} style={{ textDecoration: "none" }}>
              <Card className="value-card">

                <Grid>
                  <Typography className="total">
                    <strong style={{
                      textDecorationLine: "underline",
                      textDecorationColor: "#0764D1",
                    }}>
                      Total</strong> User
                  </Typography>

                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "18px",
                      justifyContent: "space-between",
                      marginLeft: "5%",
                      marginRight: "80px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#E8F3FF",
                        width: "60px",
                        height: "60px",
                        borderRadius: "50px",
                      }}
                    >
                      <center>
                        <img
                          src={usr}
                          alt=""
                          style={{
                            height: "36px",
                            width: "36px",
                            marginTop: "10px",
                          }}
                        />
                      </center>
                    </div>
                    <Typography className="numb1">
                      {this.state.count}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Link>
          </Grid>

          <Grid style={{ marginLeft: "2%" }}>
            <Link to={"/Category"} style={{ textDecoration: "none" }}>
              <Card className="value-card">
                <Grid>
                  <Typography className="total">

                    <strong
                      style={{
                        textDecorationLine: "underline",
                        textDecorationColor: "#FEC400",
                      }}>  Total</strong> Category
                  </Typography>

                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "18px",
                      justifyContent: "space-between",
                      marginLeft: "5%",
                      marginRight: "80px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#E8F3FF",
                        width: "60px",
                        height: "60px",
                        borderRadius: "50px",
                      }}
                    >
                      <center>
                        <img
                          src={cat}
                          alt=""
                          style={{
                            height: "36px",
                            width: "36px",
                            marginTop: "10px",
                          }}
                        />
                      </center>
                    </div>
                    <Typography className="numb2">

                      {this.state.count1}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Link>
          </Grid>

          <Grid style={{ marginLeft: "2%" }}>
            <Link to={"/Subscriber"} style={{ textDecoration: "none" }}>
              <Card className="value-card">
                <Grid>
                  <Typography className="total">
                    <strong
                      style={{
                        textDecorationLine: "underline",
                        textDecorationColor: "#29CC97",
                      }}
                    >
                      Total
                    </strong>{" "}
                    Subscribers
                  </Typography>

                  <Grid
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "18px",
                      justifyContent: "space-between",
                      marginLeft: "5%",
                      marginRight: "80px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#E8F3FF",
                        width: "60px",
                        height: "60px",
                        borderRadius: "50px",
                      }}
                    >
                      <center>
                        <img
                          src={sub}
                          alt=""
                          style={{
                            height: "36px",
                            width: "36px",
                            marginTop: "10px",
                          }}
                        />
                      </center>
                    </div>
                    <Typography className="numb3">

                      {this.state.count2}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Link>
          </Grid>
          {/* <Grid style={{ marginLeft: "2%" }}>
            <Card className="value-card">
              <Grid>
                <Typography className="total">
                  <strong
                    style={{
                      textDecorationLine: "underline",
                      textDecorationColor: "#FE4C00",
                    }}
                  >
                    Total
                  </strong>{" "}
                  Unsubscribers
                </Typography>

                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "18px",
                    justifyContent: "space-between",
                    marginLeft: "5%",
                    marginRight: "80px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#E8F3FF",
                      width: "60px",
                      height: "60px",
                      borderRadius: "50px",
                    }}
                  >
                    <center>
                      <img
                        src={sub}
                        alt=""
                        style={{
                          height: "36px",
                          width: "36px",
                          marginTop: "10px",
                        }}
                      />
                    </center>
                  </div>
                  <Typography className="numb4">
                    768
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid> */}
        </Grid>

        {/* <Button
          style={{ width: "200px", height: "48px" }}
          variant="contained"
          onClick={() => {
            this.setState({ addsummary: true });
          }}
          className="button-dept"
        >
          + Department
        </Button> */}









        <Dialog
          maxWidth="xl"
          // fullScreen
          open={this.state.addsummary}
          onClose={() => {
            this.setState({ addsummary: false });
          }}
        >
          <Grid
            style={{ width: "1200px", marginBottom: "20px", padding: "10px" }}
          >
            <Grid
              style={{
                backgroundColor: "var(--TB_BrandColor-Grey-10, #F9F9F9)",
                width: "100%",
                height: "100%",
                paddingBottom: "10px",
              }}
            >
              {/* <Typography style={{ padding: "3%" }} className="Department">Department</Typography> */}












              {/* ///1st sec////////////////////////////////////////////////////////////// */}
              <Grid style={{ marginLeft: "3%" }}>
                <Typography className="unlock">
                  Unlock Your Next Career Move with<strng> [Technoboot]</strng>
                </Typography>
              </Grid>

              <Grid
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "2%",
                }}
              >


                <Card style={{ width: "520px", height: "auto", paddingBottom: "20px", marginTop: "20px", boxShadow: 'none' }}>
                  <Grid>
                    <Grid
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        flexDirection: "row",
                        // marginLeft: "2%",
                        // marginRight:"4%",
                        justifyContent: "space-evenly",

                      }}
                    >
                      <Typography className="summaryemail">
                        Send mail:
                      </Typography>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        style={{ width: "350px", }}
                        type="Email"
                      // placeholder=' Enter Email Id'
                      //   onChange={(e) => this.setState({ org_name: e.target.value })}
                      />
                    </Grid>
                    <Grid
                      style={{
                        marginTop: "5px",
                        display: "flex",
                        flexDirection: "row",
                        // marginLeft: "2%",
                        // marginRight:"4%",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Typography
                        style={{ marginTop: "10px" }}
                        className="summaryemail"
                      >
                        Subject:
                      </Typography>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        style={{ width: "350px", }}
                        type="Email"
                      // placeholder=' Enter Email Id'
                      //   onChange={(e) => this.setState({ org_name: e.target.value })}
                      />
                    </Grid>
                    <Grid
                      style={{
                        marginTop: "5px",
                        display: "flex",
                        flexDirection: "row",
                        // marginLeft: "2%",
                        // marginRight:"4%",
                        justifyContent: "space-evenly",

                      }}
                    >
                      <Typography
                        style={{ marginTop: "10px", }}
                        className="summaryemail"
                      >
                        Sending <br />
                        account:
                      </Typography>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        style={{ width: "350px", marginTop: "10px", }}
                        type="Email"
                      // placeholder=' Enter Email Id'
                      //   onChange={(e) => this.setState({ org_name: e.target.value })}
                      />
                    </Grid>
                    <Grid
                      style={{
                        marginTop: "5px",
                        display: "flex",
                        flexDirection: "row",
                        // marginLeft: "2%",
                        // marginRight:"4%",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Typography
                        style={{ marginTop: "10px" }}
                        className="summaryemail"
                      >
                        Category:
                      </Typography>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        style={{ width: "350px", }}
                      // type="Email"
                      // placeholder=' Enter Email Id'
                      //   onChange={(e) => this.setState({ org_name: e.target.value })}
                      />
                    </Grid>
                    <Grid
                      style={{
                        marginTop: "5px",
                        display: "flex",
                        flexDirection: "row",
                        // marginLeft: "2%",
                        // marginRight:"4%",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Typography
                        style={{ marginTop: "10px" }}
                        className="summaryemail"
                      >
                        Attachment:
                      </Typography>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        style={{ width: "350px", }}
                        type="Email"
                      // placeholder=' Enter Email Id'
                      //   onChange={(e) => this.setState({ org_name: e.target.value })}
                      />
                    </Grid>
                    <Grid
                      style={{
                        marginTop: "5px",
                        display: "flex",
                        flexDirection: "row",
                        // marginLeft: "2%",
                        // marginRight:"4%",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Typography
                        style={{ marginTop: "10px" }}
                        className="summaryemail"
                      >
                        Template:
                      </Typography>
                      {/* <TextField
                        id="standard-basic"
                        variant="standard"
                        style={{ width: "350px", }}
                        type="Email"
                      
                      /> */}
                    </Grid>
                  </Grid>



                </Card>



















                {/* ///////////////////////////2nd side cadssssssssssssssssssss */}

                <Grid>
                  <Grid className="main-container1">
                    <Grid>
                      <Card className="value-card1">
                        <Grid>
                          <Typography className="total">Total User</Typography>

                          <Grid
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: "18px",
                              justifyContent: "space-between",
                              marginLeft: "5%",
                              marginRight: "80px",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "#E8F3FF",
                                width: "60px",
                                height: "60px",
                                borderRadius: "50px",
                              }}
                            >
                              <center>
                                <img
                                  src={usr}
                                  alt=""
                                  style={{
                                    height: "36px",
                                    width: "36px",
                                    marginTop: "10px",
                                  }}
                                />
                              </center>
                            </div>
                            <Typography className="numb1">
                              696
                              {/* {this.props.dashboard.users_length} */}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>

                    <Grid style={{ marginLeft: "2%" }}>
                      <Card className="value-card1">
                        <Grid>
                          <Typography className="total">


                            Total

                            Sent
                          </Typography>

                          <Grid
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: "18px",
                              justifyContent: "space-between",
                              marginLeft: "5%",
                              marginRight: "80px",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "#E8F3FF",
                                width: "60px",
                                height: "60px",
                                borderRadius: "50px",
                              }}
                            >
                              <center>
                                <img
                                  src={cat}
                                  alt=""
                                  style={{
                                    height: "36px",
                                    width: "36px",
                                    marginTop: "10px",
                                  }}
                                />
                              </center>
                            </div>
                            <Typography className="numb2">
                              890
                              {/* {this.props.dashboard.users_length} */}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>




                  {/* //////////////////2nd side 2nd col.cards................... */}

                  <Grid className="main-container1"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      // marginLeft: "3%",
                      marginTop: "18px",
                    }}
                  >
                    <Grid style={{}}>
                      <Card className="value-card1">
                        <Grid>
                          <Typography className="total">
                            Total Undelivered
                          </Typography>

                          <Grid
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: "18px",
                              justifyContent: "space-between",
                              marginLeft: "5%",
                              marginRight: "80px",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "#E8F3FF",
                                width: "60px",
                                height: "60px",
                                borderRadius: "50px",
                              }}
                            >
                              <center>
                                <img
                                  src={sub}
                                  alt=""
                                  style={{
                                    height: "36px",
                                    width: "36px",
                                    marginTop: "10px",
                                  }}
                                />
                              </center>
                            </div>
                            <Typography className="numb3">
                              496
                              {/* {this.props.dashboard.users_length} */}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid style={{ marginLeft: "2%" }}>
                      <Card className="value-card1">
                        <Grid>
                          <Typography className="total">
                            Total Unsubscribers
                          </Typography>

                          <Grid
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: "18px",
                              justifyContent: "space-between",
                              marginLeft: "5%",
                              marginRight: "80px",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "#E8F3FF",
                                width: "60px",
                                height: "60px",
                                borderRadius: "50px",
                              }}
                            >
                              <center>
                                <img
                                  src={sub}
                                  alt=""
                                  style={{
                                    height: "36px",
                                    width: "36px",
                                    marginTop: "10px",
                                  }}
                                />
                              </center>
                            </div>
                            <Typography className="numb4">
                              768
                              {/* {this.props.dashboard.users_length} */}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>


                  {/* //////////////////2nd side textfield/......................................... */}
                  <Grid style={{ marginLeft: "8%" }}>
                    <Grid
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        flexDirection: "row",
                        // marginLeft: "2%",
                        // marginRight:"4%",
                        justifyContent: "space-evenly",

                      }}
                    >
                      <Typography className="summaryemail">
                        Status:
                      </Typography>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        style={{ width: "350px", }}
                        type="text"
                      // placeholder=' Enter Email Id'
                      //   onChange={(e) => this.setState({ org_name: e.target.value })}
                      />
                    </Grid>
                    <Grid
                      style={{
                        marginTop: "5px",
                        display: "flex",
                        flexDirection: "row",
                        // marginLeft: "2%",
                        // marginRight:"4%",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Typography
                        style={{ marginTop: "10px" }}
                        className="summaryemail"
                      >
                        Date:
                      </Typography>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        style={{ width: "350px", }}
                        type="date"
                      // placeholder=' Enter Email Id'
                      //   onChange={(e) => this.setState({ org_name: e.target.value })}
                      />
                    </Grid>
                    <Grid
                      style={{
                        marginTop: "5px",
                        display: "flex",
                        flexDirection: "row",
                        // marginLeft: "2%",
                        // marginRight:"4%",
                        justifyContent: "space-evenly",

                      }}
                    >
                      <Typography
                        style={{ marginTop: "10px", }}
                        className="summaryemail"
                      >
                        Time
                      </Typography>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        style={{ width: "350px", marginTop: "10px", }}
                        type="time"
                      // placeholder=' Enter Email Id'
                      //   onChange={(e) => this.setState({ org_name: e.target.value })}
                      />
                    </Grid>
                    <Grid
                      style={{
                        marginTop: "5px",
                        display: "flex",
                        flexDirection: "row",
                        // marginLeft: "2%",
                        // marginRight:"4%",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Typography
                        style={{ marginTop: "10px" }}
                        className="summaryemail"
                      >
                        Subscriber:
                      </Typography>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        style={{ width: "350px", }}
                      // type="Email"
                      // placeholder=' Enter Email Id'
                      //   onChange={(e) => this.setState({ org_name: e.target.value })}
                      />
                    </Grid>

                  </Grid>












                </Grid>
              </Grid>


              <center>
                <Button
                  style={{ width: "100px", height: "48px", marginTop: "20px", color: "rgb(43, 63, 84)", borderColor: "rgb(43, 63, 84)", fontSize: "15px" }}
                  variant="outlined"
                  onClick={() => {
                    this.setState({ addsummary: false });
                  }}
                // className="button-dept"
                >
                  Close
                </Button>
              </center>

            </Grid>
          </Grid>
        </Dialog>











        {/* <Grid style={{ marginTop: "2%", marginLeft: "3%" }}>
          <Tabs>
            <TabList>
              <Tab style={{ width: "100px" }}>

                Overall{" "}

              </Tab>
              <Tab style={{ width: "130px" }}>


                Last Week

              </Tab>
              <Tab style={{ width: "130px" }}>

                Last Month

              </Tab>

            </TabList>

            <TabPanel>

              <Chart style={{ width: "300px" }} options={this.state.options}
                series={this.state.series}
                type="bar" width={200} height={320} />
            </TabPanel>

            <TabPanel>
              ghjkl
            </TabPanel>


            <TabPanel>
              htyjukl
            </TabPanel>


          </Tabs>
        </Grid> */}


        {/* //Complain Section */}






      </>
    );
  }
}
