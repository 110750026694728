// redux/reducers.js

import {
  ADMIN_USER
} from './Constant';

const initialState = {
  admin_user: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADMIN_USER:
      return state = { ...state, admin_user: action.payload };
    default:
      return state;
  }
};
