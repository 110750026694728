// redux/reducers.js

import { 
  ALL_CATEGORY,
  CATEGORY_LENGTH,
  SEARCH_CATEGORY
} from './Constant';

const initialState = {
    allCategory: [],
    catlength: '',
    searchCategory: [],
  };

  export default function reducer(state = initialState, action) {
    switch (action.type) {
    case ALL_CATEGORY:
      return {
        ...state,
        allCategory: action.payload, searchCat: action.payload,
      };
    case CATEGORY_LENGTH:
      return {
        ...state,
        catlength: action.payload
      };
    case SEARCH_CATEGORY:
      return {
        ...state,
        searchCat: action.payload,
      };
    default:
      return state;
  }
};
