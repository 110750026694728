

import {
    DATA_LENGTH,  
} from './Constant';

const initialState = {
    category_length: '',
    subscriber_length:''
};

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case DATA_LENGTH:
            console.log("total categoryyy",action.payload)
            return {
                ...state,
                category_length: action.payload.category,
                subscriber_length:action.payload.subscriber
            };

        default:
            return state;
    }
};
