import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import Lg_Cont from "../containers/Lg_Cont";
import Logo from "../../pages/image/email3-1-300x300.jpg"
import "../styles/drawer.css";
import { useLocation } from 'react-router-dom'
import Collapse from '@mui/material/Collapse';
import { Tooltip } from "@mui/material"
import {
  all_admin_option,
  all_superadmin_option
} from "../constant";
import { Avatar, Grid } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { useState } from 'react';
import { Modal, Button } from '@material-ui/core';
import LGCont from "../containers/Lg_Cont";
const drawerWidth = "15em";

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: 50,
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    // backgroundColor:'red',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


export default function MiniDrawer(props) {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const [expandopen, setExpand] = React.useState("");
  const [openValue, setValue] = React.useState(true);
  const [countexp, setExp] = React.useState(0);
  const setIndex = (bkb, index) => {
    if (bkb > 0 && expandopen === index) {
      setExpand("")
    }
  };

  function sideBar(type, open) {


    if (type === "SA") {
      return (
        all_superadmin_option.map((option) => (location.pathname === option.link ?
          <div style={{
            alignItems: 'left',
            background: 'rgb(255,255,255)',
            backgroundColor: 'white',
            marginTop: "5%"
          }}>
            <ListItem style={{ display: "flex", justifyContent: "flex-start" }} button key={option.name} className="drawer_texts" >
              <Typography style={{ color: "white", fontSize: "17px", textAlign: "left", backgroundColor: "white" }}>
                {option.name}
              </Typography>
            </ListItem>
          </div>
          :
          <Link key={option.link} to={option.link} style={{ textDecoration: "none", color: "white", backgroundColor: 'white', }}   >
            <center style={{ marginTop: "5%", }}>
              <ListItem style={{ display: "flex", justifyContent: "flex-start", backgroundColor: "white" }} button key={option.name} className="drawer_text" >
                <Typography style={{ fontSize: "17px", color: "white" }}>
                  {option.name}
                </Typography>
              </ListItem>
            </center>
          </Link>
        ))

      );
    }
    else if (type === "Owner" || type === "A") {
      return (
        all_superadmin_option.map((option, index) => (
          <Link
            key={option.link} to={option.link} style={{ backgroundColor: "red", fontFamily: 'Georgia', color: "white", fontWeight: 400, fontSize: "17px", textDecoration: "none" }}   >
            {true ?
              option.expand ?
                <div key={option.id}
                >
                  <div style={{}}>
                    <ListItem button onClick={() => {
                      setExpand(index)
                      setExp(countexp + 1)
                      setIndex(countexp, index)
                    }} className="drawertext"  >
                      <ListItemIcon style={{ marginRight: 10, }} ><Icon className="drawer_icon">{option.icon}</Icon></ListItemIcon>
                      {option.name}
                    </ListItem>
                  </div>
                  {expandopen === index &&
                    <Collapse in={expandopen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding style={{ marginLeft: "5px" }}>
                        {option.item.map((item) => (
                          <Link to={item.link} style={{ backgroundColor: "red", fontFamily: 'Georgia', color: "white", fontWeight: 200, fontSize: "17px", textDecoration: "none" }}>
                            <ListItemButton id={item.id} style={{
                            }} className="subdrawertext">
                              <Icon style={{ color: "gray", marginRight: "4px" }}>{item.icon}</Icon>{item.name}
                            </ListItemButton>
                          </Link>
                        ))}
                      </List>
                    </Collapse>}
                </div>
                :
                <ListItem button key={option.name} className="drawertext" style={{ background: location.pathname === option.link ? "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgb(43, 63, 84) 100%)" : null, color: location.pathname === option.link ? "black" : null }} >
                  <ListItemIcon style={{ marginRight: 5, }} ><Icon className="drawer_icon" style={{ color: location.pathname === option.link ? "black" : null }}>{option.icon}</Icon></ListItemIcon>
                  {option.name}
                </ListItem> :
              <div key={option.id}>
                <ListItem button key={option.name} className="drawertext"  >
                  <Tooltip style={{ color: "red", }} title={option.name}>
                    <Icon className="drawer_icon">{option.icon}</Icon>
                  </Tooltip>
                </ListItem>
              </div>
            }
          </Link>
        ))

      );
    }
    else if (type === "Admin") {
      return (
        all_admin_option.map((option, index) => (
          <Link
            key={option.link} to={option.link} style={{ backgroundColor: "red", fontFamily: 'Georgia', color: "white", fontWeight: 400, fontSize: "17px", textDecoration: "none" }}   >
            {true ?
              option.expand ?
                <div key={option.id}>
                  <div style={{ marginLeft: "13px", }}>
                    <ListItem button onClick={() => {
                      setExpand(index)
                      setExp(countexp + 1)
                      setIndex(countexp, index)
                    }} className="drawertext"  >
                      <center>
                        <div style={{}}>{option.name}</div>
                      </center>
                    </ListItem>
                  </div>
                  {expandopen === index &&
                    <Collapse in={expandopen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding style={{ marginLeft: "25px" }}>
                        {option.item.map((item) => (
                          <Link to={item.link} style={{ textDecoration: 'none', display: "contents" }}>
                            <ListItemButton id={item.id} style={{}} className="subdrawertext">
                              {item.name}
                            </ListItemButton>
                          </Link>
                        ))}
                      </List>
                    </Collapse>}
                </div> :
                <ListItem button key={option.name} className="drawertext" style={{ background: location.pathname === option.link ? "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgb(43, 63, 84) 100%)" : null, color: location.pathname === option.link ? "black" : null }} >
                  <ListItemIcon style={{ marginRight: 5, }} ><Icon className="drawer_icon" style={{ color: location.pathname === option.link ? "black" : null }}>{option.icon}</Icon></ListItemIcon>
                  {option.name}
                </ListItem> :
              <div key={option.id}>
                <ListItem button key={option.name} className="drawertext"  >
                  <Tooltip style={{ color: "red", }} title={option.name}>
                    <Icon className="drawer_icon">{option.icon}</Icon>
                  </Tooltip>
                </ListItem>
              </div>
            }
          </Link>
        ))

      );
    }




  }
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <Box sx={{ display: 'flex', width: "12em" }}>
      <MuiAppBar position="fixed" style={{ background: "#fff", width: "100%", zIndex: 1 }}>
        <Toolbar style={{}}>
          <div style={{ flexGrow: 1 }} />


          <img alt='' width={'75px'} src="" />
          <Avatar
            alt="User Avatar"
            src={props.login.profile_pic}
            sx={{ width: 50, height: 50, mb: 0 }}
            style={{ cursor: "pointer" }}
            onClick={openModal}
          />

          <Box >
            {/* <Tooltip title="Open Profile"> */}
            {/* <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}> */}
            {/* </IconButton> */}

          </Box>
        </Toolbar>
      </MuiAppBar>
      <CssBaseline />
      <Drawer variant="permanent" open={true}  >
        <Grid style={{ width: "100%", backgroundColor: 'rgb(43 63 84)', flexDirection: "column", height: '100%', display: "flex", justifyContent: "space-evenly" }}>
          <DrawerHeader style={{ display: 'flex', flexDirection: "column" }} >
            <Avatar
              src={Logo}
              style={{ width: 55, height: 55 }} />
            <Typography style={{ color: "gray", fontSize: "14px" }}>Email Karo</Typography>
            {/* <Box className='name'>
              <Typography style={{ color: "white ", marginTop: "6px", textAlign: 'center' }}>{props.name}</Typography>
            </Box> */}
          </DrawerHeader>
          <List >
            {sideBar(props.type, open)}
          </List>
          {/* <LG_Cont /> */}
        </Grid>

      </Drawer>


      <Modal
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '80px',
            right: '50px',
            width: 300,
            backgroundColor: 'white',
            padding: 2,
            borderRadius: 2,
            boxShadow: 24,
          }}
        >
          <center>
            <Typography variant="h6">{props.login.email}</Typography>
            <Avatar
              alt="User Avatar"
              src={props.login.profile_pic}
              sx={{ width: 75, height: 75, mb: 2 }}
            />
            <Typography variant="subtitle1">{props.login.name}</Typography>
          </center>
          <center>
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                justifyContent: 'space-between',
                gap: 2,
              }}
            >
              {/* <Link to="/">
                <Button
                  variant="contained"
                  sx={{
                    width: 200,
                    height: 40,
                    backgroundColor: '#3D3B3B',
                    color: 'white',
                  }}
                >
                  Switch Organization
                </Button>
              </Link> */}
               <LGCont />
              <Link to="/">
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: 1,
                    backgroundColor: '#3D3B3B',
                    width: 150,
                    height: 40,
                    color: 'white',
                  }}
                  onClick={() => {
                    props.logOutByToken(props.login.token)
                  }}
                >
                  Logout
                </Button>
              </Link>
            </Box>

          </center>
        </Box>
      </Modal>
    </Box>
  );
}
