import {
    ALL_SENDEMAIL,
    SENDEMAIL_LENGTH,
    SEARCH_SENDEMAIL,
    DOCUMENT
} from "./Constant";

// Utility function to handle GraphQL requests
import { handleGraphQLRequest } from '../../utils/utils';
import { getSendEmailQuery, getAllSendEmailQuery } from '../../graphql/query/sendEmailq';
import { setSendEmailmutation, setExcelSendEmailmutation, delSendEmailmutation, resetSendEmailmutation, setSendIndividualEmailmutation, setSendCampaignEmailmutation, setSendBulkEmailmutation } from '../../graphql/mutation/sendEmailm';
import { set_snack_bar } from "../../common/snackbar/action";
import 'firebase/storage';
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { setLoader, unsetLoader, setProgress, unsetProgress } from "../../common/loader/action";

// Action creators
export function setAllSendEmail(sendEmailData) {
    return {
        type: ALL_SENDEMAIL,
        payload: sendEmailData,
    };
}

export function setSendEmailLength(sendEmailLength) {
    return {
        type: SENDEMAIL_LENGTH,
        payload: sendEmailLength,
    };
}

export function setDocument(url) {
    return {
        type: DOCUMENT,
        payload: url,
    };
}
export function uploadDocument(document) {
    return dispatch => {
        console.log(document)
        dispatch(setProgress())
        const storageRef = ref(getStorage(), "/document/" + document.name)
        const uploadimage = uploadBytesResumable(storageRef, document);
        uploadimage.on(
            "state_changed",
            function (snapshot) {
            },
            function (error) {
                console.log(error)
                //   dispatch(setalert(true, "Image Could Not Be Uploaded"));
                dispatch(unsetProgress())
            },

            function () {
                getDownloadURL(uploadimage.snapshot.ref).then(function (document) {
                    if (getDownloadURL(uploadimage.snapshot.ref) !== null) {
                        console.log(document)
                        dispatch(setDocument(document))
                        dispatch(unsetProgress())
                        dispatch(set_snack_bar(true, "Image Uploaded"))
                    }
                    else {
                        dispatch(unsetProgress())
                        dispatch(set_snack_bar(true, "Image not Uploaded"));
                    }
                });
            }
        );
    }

}
//---------------------------------------------------VIEWALL----------------------------------------------------------//
export function viewAllSendEmail(orgId, page, rowPerPage, search) {
    return async (dispatch) => {
        let requestBody = getSendEmailQuery(orgId, page, rowPerPage, search);
        const response = await handleGraphQLRequest(dispatch, requestBody, 'SendEmail Found', false);
        console.log(response)
        if (!response.errors && response.data) {
            console.log('viewwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww', response)
            const sendEmailData = response.data.campaignsPagination.campaign;
            dispatch(setAllSendEmail(sendEmailData));
            const sendEmailLength = response.data?.campaignsPagination?.totalCampaign;
            dispatch(setSendEmailLength(sendEmailLength));
        }
        else {
            const sendEmailData = []
            dispatch(setAllSendEmail(sendEmailData));
            const sendEmailLength = 0
            dispatch(setSendEmailLength(sendEmailLength));
        }
    };
}
export function viewAllOrgSendEmail(orgId) {
    return async (dispatch) => {
        let requestBody = getAllSendEmailQuery(orgId);
        const response = await handleGraphQLRequest(dispatch, requestBody, 'SendEmail Found', false);
        console.log('viewwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww', response)
        if (!response.errors && response.data) {
            console.log('viewwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww', response)
            const sendEmailData = response.data.sendEmails;
            dispatch(setAllSendEmail(sendEmailData));
        }
        else {
            const sendEmailData = []
            dispatch(setAllSendEmail(sendEmailData));
        }
    };
}


//---------------------------------------------------ADD----------------------------------------------------------//
export function createSendEmail(orgId, name, email, phone, category,) {
    return async (dispatch) => {
        let requestBody = setSendEmailmutation(orgId, name, email, phone, category);
        const response = await handleGraphQLRequest(dispatch, requestBody, 'SendEmail created successfully', true);
        console.log('createeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', response)
        if (response.errors?.length === 0) {
            dispatch(set_snack_bar(response.errors[0].message))
        }
        else {
            dispatch(viewAllSendEmail(orgId, 1, 10, ""));
        }
    };
}

export function createExcelSendEmail(orgId, category, data) {
    return async (dispatch) => {
        let requestBody = setExcelSendEmailmutation(orgId, category, data);
        const response = await handleGraphQLRequest(dispatch, requestBody, 'SendEmail created successfully', true);
        console.log('createeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', response)
        if (response.errors?.length === 0) {
            dispatch(set_snack_bar(response.errors[0].message))
        }
        else {
            dispatch(viewAllSendEmail(orgId, 1, 10, ""));
        }
    };
}

//---------------------------------------------------EDIT----------------------------------------------------------//
export function editSendEmail(orgId, sendEmailId, name, email, phone, category, page, rowPerPage) {
    return async (dispatch) => {
        console.log(orgId, sendEmailId, name, email, phone, category, page, rowPerPage)
        let requestBody = resetSendEmailmutation(sendEmailId, name, email, phone, category);
        const response = await handleGraphQLRequest(dispatch, requestBody, 'Changes made successfully', true);
        console.log('editttttttttttttttttttttttttttttttttttttttttttttttttttttt', response)
        if (response.errors?.length === 0) {
            dispatch(set_snack_bar(response.errors[0].message))
        }
        else {
            dispatch(viewAllSendEmail(orgId, page, rowPerPage, ""));
        }
    };
}

//---------------------------------------------------DELETE----------------------------------------------------------//
export function deleteSendEmail(orgId, sendEmailId, page, rowPerPage) {
    return async (dispatch) => {
        console.log(orgId, sendEmailId);
        let requestBody = delSendEmailmutation(sendEmailId);
        const response = await handleGraphQLRequest(dispatch, requestBody, 'SendEmail deleted successfully', true);
        console.log('deleteeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', response)
        if (response.errors?.length === 0) {
            dispatch(set_snack_bar(response.errors[0].message))
        }
        else {
            dispatch(viewAllSendEmail(orgId, page, rowPerPage, ""));
        }
    };
}

//---------------------------------------------------SEARCH----------------------------------------------------------//
export function searchSendEmail(allSendEmail, e) {
    return (dispatch) => {
        if (e === "") {
            dispatch({ type: SEARCH_SENDEMAIL, payload: allSendEmail });
        }
        else {
            const newArray = allSendEmail.filter((el) => {
                return (el.name.toLowerCase().includes(e.toLowerCase()));
            })
            dispatch({ type: SEARCH_SENDEMAIL, payload: newArray })
        }
    };
}

//--------------------------------------------------Send-Email-Account-----------------------------------------------//
export function sendCampaignIndividual(name, subject, email_type, reciver_id, sending_account_id, template, template_id, category, org_id, attachmentType, attachment) {
    return async (dispatch) => {
        let requestBody = setSendIndividualEmailmutation(name, subject, email_type,
            reciver_id, sending_account_id, template,
            template_id, category, org_id, attachmentType, attachment);
        const response = await handleGraphQLRequest(dispatch, requestBody, 'SendEmail created successfully', true);
        console.log('createeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', response)
        if (response.errors?.length === 0) {
            dispatch(set_snack_bar(response.errors[0].message))
        }
        else {
            dispatch(viewAllSendEmail(org_id, 0, 10, ""));
        }
    };
}
export function sendCampaignCategory(name, subject, email_type, reciver_id, sending_account_id, template, template_id, category, org_id, attachmentType, attachment) {
    return async (dispatch) => {
        let requestBody = setSendCampaignEmailmutation(name, subject, email_type,
            reciver_id, sending_account_id, template,
            template_id, category, org_id, attachmentType, attachment);
        const response = await handleGraphQLRequest(dispatch, requestBody, 'SendEmail created successfully', true);
        console.log('createeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', response)
        if (response.errors?.length === 0) {
            dispatch(set_snack_bar(response.errors[0].message))
        }
        else {
            dispatch(viewAllSendEmail(org_id, 0, 10, ""));
        }
    };
}
export function sendCampaignBulk(name, subject, email_type, reciver_id, sending_account_id, template, template_id, category, org_id, attachmentType, attachment) {
    return async (dispatch) => {
        let requestBody = setSendBulkEmailmutation(name, subject, email_type,
            reciver_id, sending_account_id, template,
            template_id, category, org_id, attachmentType, attachment);
        const response = await handleGraphQLRequest(dispatch, requestBody, 'SendEmail created successfully', true);
        console.log('createeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', response)
        if (response.errors?.length === 0) {
            dispatch(set_snack_bar(response.errors[0].message))
        }
        else {
            dispatch(viewAllSendEmail(org_id, 0, 10, ""));
        }
    };
}

