import { FormControl } from '@mui/base'
import { Box, Button, Grid, Modal, Avatar, Card, Container, List, ListItem, ListItemAvatar, InputLabel, ListItemText, MenuItem, Select, TextField, Typography, Dialog } from '@mui/material'
import { width } from '@mui/system'
import "./style.css";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
// import Loader from '../../../common/loader/components/loader';
import React, { Component } from 'react'

export default class Accountsetting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            select: "",
            display: false,
            edit: false,
            Add: false,
            item: 0,
            list: [
                {
                    mail: "gogole",
                    Email: "hh",
                    Password: "****",
                    CC: "category@gmail.com",
                },
                {
                    mail: "yahoo",
                    Email: "hh",
                    Password: "****",
                    CC: "category@gmail.com",
                },
                {
                    mail: "outlook",
                    Email: "hh",
                    Password: "****",
                    CC: "category@gmail.com",
                },

            ]
            ,
            transpoter: "",
            Password: "",
            cc: "",
            email: "",
            account_id: "",
            deleteModal:false
        }
    }
    componentDidMount() {
        this.props.viewAccount(this.props.login.org_id)
        console.log(this.props.mail.account)
    }
    render() {
        const { select, display, edit, item, Add } = this.state
        return (
            <Container style={{
                // height:"100vh",
                // width:"50%",
                // boxShadow: "1px -1px 10px",
                // padding:"1em",
                // display:"flex",flexDirection:"row",
                // backgroundColor:"aliceblue"
                marginTop: "6em"
            }} maxWidth="lg">

                <div style={{
                    display: "flex",
                    // flexDirection:'flex-start'
                    alignItems: "center",
                }}>

                    <Typography sx={{
                        padding: " 1em"
                    }} variant="h6" gutterBottom>
                        Account Setting
                    </Typography>
                    <Button sx={{
                        // marginBottom: "3em"
                        height: "3em",
                        width: "6em",
                    }} onClick={() => {

                        // this.setState({ display: false })
                        this.setState({ Add: true })
                    }

                    } style={{
                        backgroundColor: "rgb(26 161 1)",
                        width: "23px",
                        height: " 34px",
                        margin: "1em"

                    }} variant="contained">Add</Button>
                </div>


                {
                    this.props.mail.account.map((i, index) => (
                        <div key={index} style={{
                            margin: " 0px 20px ",
                            //   display: display ? `flex`:`none` 
                            display: "flex"
                        }} >
                            <Typography sx={{
                                padding: " 1em 2em"
                            }} variant="h6" gutterBottom>
                                {index + 1}
                            </Typography>
                            <ListItemText primary="Mail Transpoter" secondary={i.mail_transpoter} />
                            <ListItemText primary=" Email" secondary={i.email} />
                            <ListItemText primary=" Password" secondary={i.password} />
                            <ListItemText primary=" CC" secondary={i.cc} />
                            <Button sx={{
                                marginBottom: "3em"
                            }} onClick={() => {

                                // this.setState({ display: false })
                                this.setState({ edit: true, account_id: i._id.toString(), transpoter: i.mail_transpoter, email: i.email, Password: i.password, cc: i.cc })
                            }

                            } style={{
                                backgroundColor: "darkslategrey",
                                width: "23px",
                                height: " 34px",
                                margin: "1em"

                            }} variant="contained">edit</Button>
                            <Button sx={{
                                marginBottom: "3em"
                            }} onClick={() => {
                                this.setState({ deleteModal: true, account_id: i._id.toString() })

                            }

                            } style={{
                                backgroundColor: "#ff5b4f",
                                width: "81px",
                                height: " 34px",
                                margin: "1em"

                            }} variant="contained">Delete</Button>
                        </div>
                    ))
                }
                <Dialog
                    open={this.state.deleteModal} style={{}}>
                    <Grid style={{ height: "250px", width: "550px" }}>
                        <button
                            style={{
                                marginLeft: "440px",
                                marginTop: "20px",
                                backgroundColor: "transparent",
                                border: "none",
                            }}
                        >
                            <CloseOutlinedIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    this.setState({ deleteModal: false, cursor: "pointer" });
                                }}
                            />
                        </button>
                        <Box>
                            <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                style={{
                                    // fontFamily: "Inter",
                                    display: "flex",
                                    justifyContent: "center",
                                    // fontFamily:"Poppins",
                                    fontWeight: 600,
                                }}
                            >
                                Are you sure you want to delete ?
                            </Typography>
                            <Typography
                                id="modal-modal-description"
                                style={{
                                    marginTop: "2%",
                                    fontSize: "1rem",
                                    // fontFamily: "Inter",
                                    display: "flex",
                                    justifyContent: "center",
                                    // fontFamily:"Poppins",
                                    color: "black",
                                }}
                            >
                                This process will lead to loss of entire data, proceed at yours
                                own risk
                            </Typography>
                            <Box
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "6%",
                                }}
                            >
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        this.setState({ deleteCourse: false });
                                    }}
                                    style={{
                                        backgroundColor: "darkslategrey",
                                        marginTop: "10px",
                                        color:"white"
                                    }}
                                >
                                    No
                                </Button>
                                <Button
                                    variant="text"

                                    onClick={() => {
                                        this.setState({ deleteModal: false });
                                        this.props.deleteAccount(this.props.login.org_id, this.state.account_id)
                                    }}
                                    // style={{
                                    //     marginLeft: 10,
                                    //     height: "2rem",
                                    //     backgroundColor: "#0000B3",
                                    //     color: "white",
                                    //     borderRadius: "8px",
                                    // }}
                                    style={{
                                        // backgroundColor: "darkslategrey",
                                        marginTop: "10px",
                                        // color:"white"
                                    }}
                                >
                                    Yes
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Dialog>

                <Modal
                    open={Add} style={{

                        // backgroundColor: "white"

                    }}>


                    <Box style={{
                        position: 'absolute',
                        top: '50%',
                        // Width: "97em",
                        // Width: "100%",
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        height: "50%",
                        //  // alignSelf:"center",
                        // //  width: "60%", 
                        //  alignItems: "flex-start",
                        flexDirection: "column",
                        display: "flex",
                        // //  marginLeft:"2em",
                        //  gap:"1em",
                        justifyContent: "space-between",
                        padding: "20px",
                        backgroundColor: "aliceblue",
                    }}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",

                            width: "100%",
                            alignItems: " flex-start",
                        }}>

                            <Typography color="black" variant="body1" component="h2" > Mail Transpoter</Typography>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                <Select
                                    style={{
                                        // color: "black",
                                        width: "25em"
                                    }}
                                    // fullWidth
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.transpoter}
                                    variant="standard"
                                    onChange={(e) => this.setState({ transpoter: e.target.value })}
                                >
                                    <MenuItem value={"google"}>Google</MenuItem>
                                    <MenuItem value={"yandex"}>Yendex</MenuItem>
                                    <MenuItem value={"zoho"}>Zoho</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",

                            width: "100%",
                            alignItems: " flex-start",
                        }}>
                            <Typography color="black" variant="body1" component="h2">
                                Email
                            </Typography>
                            <TextField

                                type="text"
                                // value={otp}
                                onChange={(e) => this.setState({ email: e.target.value })}
                                variant="standard"
                                fullWidth
                            />
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",

                            width: "100%",
                            alignItems: " flex-start",
                        }}>
                            <Typography color="black" variant="body1" component="h2">
                                Password
                            </Typography>
                            <TextField

                                type="text"
                                // value={otp}
                                onChange={(e) => this.setState({ Password: e.target.value })}
                                variant="standard"
                                fullWidth
                            />
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",

                            width: "100%",
                            alignItems: " flex-start",
                        }}>
                            <Typography color="black" variant="body1" component="h2">
                                CC
                            </Typography>
                            <TextField

                                type="text"
                                // value={otp}
                                onChange={(e) => this.setState({ cc: e.target.value })}
                                variant="standard"
                                fullWidth
                            />
                        </Box>
                        <div>
                        <Button
                            variant="contained"
                            // color="primary"
                            // fullWidth
                            style={{
                                backgroundColor: "darkslategrey",
                                marginTop: "10px"
                            }}
                            onClick={() => {
                                this.setState({
                                    Add: false,
                                    transpoter: "",
                                    email: "",
                                    Password: "",
                                    cc: "",
                                })
                                this.props.addAccount(this.props.login.org_id, this.state.transpoter, this.state.email, this.state.Password, this.state.cc)
                            }}
                        >
                            ADD
                        </Button>
                        <Button
                            variant="text"
                            // color="primary"
                            // fullWidth
                            style={{
                                // backgroundColor: "darkslategrey",
                                marginTop: "10px"
                            }}
                            onClick={() => {
                                this.setState({
                                    Add: false,

                                })
                            }}
                        >
                            Cancel
                        </Button>
                        </div>
                        {/* <Button sx={{
            margin:"23px"

          }} 
          onClick={()=>{
            this.setState({
                display: false,
                
            })
          }}

          
          variant="text" color="primary" type="submit">
           Cancel
          </Button> */}


                    </Box>


                </Modal>
                <Modal
                    open={edit} style={{

                        // backgroundColor: "white"

                    }}>


                    <Box style={{
                        position: 'absolute',
                        top: '50%',
                        // Width: "97em",
                        // Width: "100%",
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        height: "50%",
                        //  // alignSelf:"center",
                        // //  width: "60%", 
                        //  alignItems: "flex-start",
                        flexDirection: "column",
                        display: "flex",
                        // //  marginLeft:"2em",
                        //  gap:"1em",
                        justifyContent: "space-between",
                        padding: "20px",
                        backgroundColor: "aliceblue",
                    }}>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",

                            width: "100%",
                            alignItems: " flex-start",
                        }}>

                            <Typography color="black" variant="body1" component="h2" > Mail Transpoter</Typography>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                <Select
                                    style={{
                                        // color: "black",
                                        width: "25em"
                                    }}
                                    // fullWidth
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.transpoter}
                                    variant="standard"
                                    onChange={(e) => this.setState({ transpoter: e.target.value })}
                                >
                                    <MenuItem value={"google"}>Google</MenuItem>
                                    <MenuItem value={"yandex"}>Yandex</MenuItem>
                                    <MenuItem value={"zoho"}>Zoho</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",

                            width: "100%",
                            alignItems: " flex-start",
                        }}>
                            <Typography color="black" variant="body1" component="h2">
                                Email
                            </Typography>
                            <TextField

                                type="text"
                                value={this.state.email}
                                onChange={(e) => this.setState({ email: e.target.value })}
                                variant="standard"
                                fullWidth
                            />
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",

                            width: "100%",
                            alignItems: " flex-start",
                        }}>
                            <Typography color="black" variant="body1" component="h2">
                                Password
                            </Typography>
                            <TextField

                                type="text"
                                value={this.state.Password}
                                onChange={(e) => this.setState({ Password: e.target.value })}
                                variant="standard"
                                fullWidth
                            />
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexDirection: "column",

                            width: "100%",
                            alignItems: " flex-start",
                        }}>
                            <Typography color="black" variant="body1" component="h2">
                                CC
                            </Typography>
                            <TextField

                                type="text"
                                value={this.state.cc}
                                onChange={(e) => this.setState({ cc: e.target.value })}
                                variant="standard"
                                fullWidth
                            />
                        </Box>
                        <div>
                        <Button
                            variant="contained"
                            // color="primary"
                            // fullWidth
                            style={{
                                backgroundColor: "darkslategrey",
                                marginTop: "10px"
                            }}
                            onClick={() => {
                                this.props.updateAccount(this.props.login.org_id, this.state.account_id, this.state.email, this.state.transpoter, this.state.Password, this.state.cc)
                                this.setState({
                                    edit: false,
                                })
                            }}
                        >
                            Save Changes
                        </Button>
                        <Button
                            variant="text"
                            // color="primary"
                            // fullWidth
                            style={{
                                // backgroundColor: "darkslategrey",
                                marginTop: "10px"
                            }}
                            onClick={() => {
                                this.setState({
                                    edit: false,

                                })
                            }}
                        >
                            Cancel
                        </Button>
                        </div>
                        {/* <Button sx={{
            margin:"23px"

          }} 
          onClick={()=>{
            this.setState({
                display: false,
                
            })
          }}

          
          variant="text" color="primary" type="submit">
           Cancel
          </Button> */}


                    </Box>





                    {/* <Box sx={{
                            flexDirection: "row",
                            display: "flex"
                        }}>
                    <Typography variant="body1" > Email</Typography>
                    <TextField
                        label="Email"
                        type="text"
                        // value=
                        // onChange={(e) => this.setState({ otp: e.target.value })}
                        variant="outlined"
                        fullWidth
                    />


                </Box>
           


             */}

                </Modal>
                
            {/* <Loader/> */}

            </Container>
        )
    }
}
