import React, { Component } from 'react'
import {
  Grid,
  Typography,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  CircularProgress
} from "@mui/material";
import SearchIcon from '../../image/search.png';
import upload from "../../image/upload.png";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import Icon from "@mui/material/Icon";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import '../../../common/styles.scss';
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import '../../../common/introCss.scss';
import { debounce } from 'lodash';
import * as XLSX from 'xlsx';


export default class Subscriber extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      searching: false,
      addsubscriber: false,
      editsubscriber: false,
      view: false,
      Subscriberdelete: false,
      user_id: '',
      user_name: '',
      page: 0,
      rowsPerPage: 10,
      sub_email: "",
      sub_mob: "",
      sub_name: "",
      category: "",
      userdata: [
        {
          category: " Marketing ",
          UserName: "subh",
          email: "subh.technoboot.co",
          mobile: "9178888606"

        },
        {
          category: " HR ",
          UserName: "jhbh",
          email: "subh.technoboot.co",
          mobile: "9178888606"

        },
        {

          category: " Owner ",
          UserName: "bh",
          email: "subh.technoboot.co",
          mobile: "9178888606"


        },
      ],
      mailData: [],
      subscriberId: "",
    };
    this.debouncedViewAllSubscriber = debounce(this.props.viewAllSubscriber, 500);
  }


  startIntro = () => {
    const intro = introJs();
    intro.setOptions({
      steps: [
        {
          intro: 'Welcome to Subscriber!',
        },
        {
          element: '#tableUser', // Replace with the actual element ID or class
          intro: 'this is a list of Subscriber',
        },
        {
          element: '#addUser',
          intro: 'You can add your own Subscriber',
        },
        {
          element: '#editUser',
          intro: 'You can also make changes to particular Subscriber according to your needs',
        },
        {
          element: '#deleteUser',
          intro: 'You can delete unnecessary Subscriber',
        },
        {
          element: '#searchUser',
          intro: 'Search your department by Subscriber',
        },
      ],
    });
    intro.start();
    localStorage.setItem("sub_intro", true)
  };

  componentDidMount() {
    // this.props.viewAllUser(this.props.login.org_id, this.state.page, this.state.rowsPerPage);
    if (localStorage.getItem('sub_intro')) {
      this.startIntro();
    }
    this.props.viewAllSubscriber(this.props.login.org_id, this.state.page, this.state.rowsPerPage, "")
    this.props.viewAllOrgCategory(this.props.login.org_id)
  }

  handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    this.setState({ rowsPerPage: newRowsPerPage, page: 0 });
    this.props.viewAllSubscriber(this.props.login.org_id, 0, newRowsPerPage, "");
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    // Fetch data with the new page value
    this.props.viewAllSubscriber(this.props.login.org_id, newPage, this.state.rowsPerPage, "");
  };

  downloadSampleFile = () => {
    // Define your sample data
    const data = [
      ['NAME', 'EMAIL', 'PHONE'],
      ['SWADHIN DAS', 'ABC@GMAIL.COM', '917438082965'],
      ['SOUMYARANJAN ROUT', 'XYZ@GMAIL.COM', '917684566854'],
      // Add more rows as needed
    ];

    // Create a new workbook and set the data
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SampleSheet');

    // Write the workbook to a file
    XLSX.writeFile(wb, 'sample_file.xlsx');
  };
  handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      this.parseExcelData(file);
    }
  };
  parseExcelData = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0]; // Assuming you're interested in the first sheet
      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet);
      const mailArray = excelData.map((item) => ({ name: item.NAME, email: item.EMAIL, phone: String(item.PHONE) }));
      this.setState({ mailData: mailArray });
    };

    reader.readAsBinaryString(file);
  };

  render() {
    const { snackbar, close_snack_bar } = this.props
    return (
      <Grid>
        <Grid container justifyContent={'left'}>
          <Typography className='topic'>Subscribers</Typography>
        </Grid>
        <LoaderCon />
        <Grid container className='page'>
          <Grid item xs={12} className='box'>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={10}>
                <FormControl fullWidth variant="standard" id='searchCat'>
                  <OutlinedInput
                    type={'text'}
                    className='textfield'
                    sx={{
                      '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                        boxShadow: '0px 2px 0px lightgray',
                      },
                      '.MuiOutlinedInput-notchedOutline': { border: '1px solid #E2E0DD' },
                      height: '48px',
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <LoadingButton
                            loading={this.state.searching}
                            loadingPosition="center"
                            variant="text"
                            onClick={() => {
                              this.setState({
                                add: true,
                              });
                            }}
                          >
                            {!this.state.searching && <img src={SearchIcon} alt="Search Category" style={{ font: '24px' }} />}
                          </LoadingButton>
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Search"
                    onChange={(event) => {
                      this.setState({ searching: event.target.value === '' ? true : false });
                      const searchTerm = event.target.value;
                      this.setState({ searching: searchTerm !== '' ? true : false });

                      // Cancel the previous API call and schedule a new one
                      this.debouncedViewAllSubscriber(this.props.login.org_id, this.state.page, this.state.rowsPerPage, searchTerm);
                      // {event.target.value === '' ? this.props.viewAllSubscriber(this.props.login.org_id, this.state.page, this.state.rowsPerPage, "") :
                      // this.props.viewAllSubscriber(this.props.login.org_id, this.state.page, this.state.rowsPerPage, event.target.value)
                      // }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={2} >
                {/* Adjust the xs value according to your layout */}
                <LoadingButton
                  id="addCat"
                  fullWidth
                  style={{ height: '48px' }}
                  className='add'
                  startIcon={this.props.loader.progress ? '' : <AddRoundedIcon />}
                  loading={this.props.loader.progress}
                  loadingPosition='center'
                  variant='contained'
                  onClick={() => {
                    this.setState({
                      addsubscriber: true,
                    });
                  }}
                >
                  {this.props.loader.progress ? (
                    <CircularProgress
                      size={24} // Adjust the size of the circular loader as needed
                      style={{ color: 'white' }}
                    />
                  ) :
                    <Typography>Subscriber</Typography>
                  }
                </LoadingButton>
              </Grid>
            </Grid>
            <Grid>
              <TableContainer id="tableCat" >
                <Table stickyHeader aria-label="sticky table" style={{ marginTop: '20px' }}>
                  <TableHead className="customTableHead">
                    <TableRow>
                      <TableCell className='headtext'

                      >
                        Sl.No
                      </TableCell>
                      <TableCell className='headtext' >
                        Name
                      </TableCell>
                      <TableCell className='headtext' >
                        Email
                      </TableCell>
                      <TableCell className='headtext'>
                        Mobile
                      </TableCell>
                      <TableCell className='headtext'>
                        Category
                      </TableCell>
                      <TableCell style={{ marginLeft: "30%" }} className='headtext' >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(this.props.subscriber.searchSub) && this.props.subscriber.searchSub.map((item, index) => {
                      return (
                        <TableRow hover tabIndex={-1}>
                          <TableCell justifyContent={'center'} className='celltext'>
                            {index + 1}
                          </TableCell>
                          <TableCell justifyContent={'center'} className='celltext'
                          >
                            {item.name}
                          </TableCell>
                          <TableCell justifyContent={'center'} className='celltext'>
                            {item.email}
                          </TableCell>
                          <TableCell justifyContent={'center'} className='celltext'>
                            {item.phone}
                          </TableCell>
                          <TableCell justifyContent={'center'} className='celltext'>
                            {item?.category_details?.name}
                          </TableCell>

                          <TableCell justifyContent={'center'}
                          >
                            <Grid style={{ display: 'flex', marginLeft: "-17%" }}>
                              <Icon
                                id='editCat'
                                class="material-symbols-outlined"
                                onClick={() => {
                                  this.setState({
                                    editsubscriber: true,
                                    subscriberId: item._id,
                                    name: item.name,
                                    email: item.email,
                                    phone: item.phone,
                                    category: item.category_details?._id
                                    // department_id: row._id,
                                    // department_name: row.name,
                                  });
                                }}
                                style={{
                                  color: "#FFA741",
                                  cursor: "pointer",
                                  padding: '8px',
                                  backgroundColor: 'rgba(255, 231, 203, 0.7)',
                                  borderRadius: 50,
                                  fontSize: '22px',
                                  marginLeft: "10%",
                                }}
                              >
                                edit_square
                              </Icon>
                              <Icon
                                id='deleteCat'
                                class="material-symbols-outlined"
                                onClick={() => {
                                  this.setState({
                                    Subscriberdelete: true,
                                    subscriberId: item._id,
                                  });
                                }}
                                style={{
                                  color: "#C43232",
                                  cursor: "pointer",
                                  padding: '8px',
                                  backgroundColor: 'rgba(255, 234, 227, 0.7)',
                                  borderRadius: 50,
                                  fontSize: '22px',
                                  marginLeft: "10%",
                                }}
                              >
                                delete
                              </Icon>
                            </Grid>
                          </TableCell>

                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 50]}
                  component="div"
                  count={this.props.subscriber.sublength}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}
        {/*--------------------Add-------------------------*/}
        <Dialog
          sx={{

            backdropFilter: "blur(10px)",
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
          maxWidth="lg"
          open={this.state.addsubscriber}
          onClose={() => {
            this.setState({ addsubscriber: false });
          }} >
          <DialogContent>
            <Tabs>
              <TabList
                style={{
                  display: "flex",
                  flexDirection: "row", // Set to "row" to make tabs appear in a row
                }}
              >
                <Tab
                  style={{ backgroundColor: "#FFF4E8", color: "rgb(43, 63, 84)", fontWeight: 700, fontSize: "20px", height: "50px", textAlign: "center" }}
                >  Single User
                </Tab>
                <Tab
                  style={{ backgroundColor: "#FFF4E8", color: "rgb(43, 63, 84)", fontWeight: 700, fontSize: "20px", height: "50px", textAlign: "center" }}
                >Upload From Excel
                </Tab>
              </TabList>
              <TabPanel>
                <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "8%" }}>Category</Typography>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 480, marginLeft: "8%" }}>
                  <Select
                    onChange={(e) => {
                      this.setState({ category: e.target.value })
                    }}
                  >
                    {Array.isArray(this.props.category.allCategory) && this.props.category.allCategory.map(element => {
                      return (<MenuItem value={element._id}>{element.name}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
                <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "8%" }}>User Name</Typography>
                <TextField id="standard-basic" variant="standard" style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
                  type="text"
                  placeholder=' Enter User Name'

                  onChange={(e) => this.setState({ sub_name: e.target.value })}
                />
                <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "8%" }}>Enter Email</Typography>
                <TextField id="standard-basic" variant="standard" style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
                  type="email"
                  placeholder=' Enter Email Id'
                  onChange={(e) => this.setState({ sub_email: e.target.value })}
                />
                <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "8%" }}>Enter Mobile</Typography>
                <TextField id="standard-basic" variant="standard" style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
                  type="number"
                  placeholder=' Enter Mobile Number'
                  onChange={(e) => this.setState({ sub_mob: e.target.value })}
                />

                <Grid style={{ marginTop: "20px", marginLeft: "8%", marginRight: "3%", display: "flex", flexDirection: "row", }}>

                  <Button variant="outlined"
                    style={{ fontSize: "15px", height: "48px", width: "249px", borderRadius: "4px", color: "#161412", borderColor: "rgb(43, 63, 84)" }}

                    onClick={() => { this.setState({ addsubscriber: false }) }}
                  >

                    Cancel
                  </Button>
                  <Button variant="contained"
                    disabled={this.state.sub_name === "" || this.state.sub_email === "" || this.state.category === ""}
                    style={{ backgroundColor: "rgb(43, 63, 84)", fontSize: "15px", height: "48px", width: "249px", borderRadius: "4px", marginLeft: "10px" }}
                    onClick={() => {
                      this.props.createSubscriber(this.props.login.org_id, this.state.sub_name, this.state.sub_email, this.state.sub_mob, this.state.category)
                      this.setState({ addsubscriber: false, sub_email: "", sub_mob: "", category: "", });
                    }}

                  >

                    Submit
                  </Button>
                </Grid>



              </TabPanel>

              <TabPanel>
                <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "8%" }}>Category</Typography>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 480, marginLeft: "8%" }}>
                  <Select
                    onChange={(e) => {
                      this.setState({ category: e.target.value })
                    }}
                  >

                    {Array.isArray(this.props.category.allCategory) && this.props.category.allCategory.map(element => {
                      return (<MenuItem value={element._id}>{element.name}</MenuItem>)
                    })}
                  </Select>
                </FormControl>

                <center style={{ marginTop: "20px" }} >
                  <input style={{ display: "none" }} type="file" id="file" accept=".xls, .xlsx" onChange={this.handleFileUpload} />
                  <label htmlFor="file">
                    <div style={{
                      borderRadius: "48px", height: "60x", width: "36px",
                      background: "var(--TB_BrandColor-Primary-10, #FFF4E8)"
                    }}>
                      <img style={{}} alt='' src={upload} />
                    </div>
                  </label>
                  {this.state.mailData.length === 0 ?
                    <Typography style={{ marginTop: "5px", fontSize: "18px", color: "#161412", fontWeight: 500 }}>Click here to upload</Typography>
                    :
                    <Typography style={{ marginTop: "5px", fontSize: "18px", color: "#161412", fontWeight: 500 }}>Selected</Typography>
                  }
                  <Typography style={{ marginTop: "5px", fontSize: "14px", color: "#87817B", fontWeight: 400, }}>(File format: xsl/xlsx format)
                    <Button
                      onClick={this.downloadSampleFile}
                    >Click here to download format</Button></Typography>
                </center>
                <Grid style={{ marginTop: "20px", marginLeft: "8%", marginRight: "3%", display: "flex", flexDirection: "row", }}>

                  <Button variant="outlined"
                    style={{ fontSize: "15px", height: "48px", width: "249px", borderRadius: "4px", color: "#161412", borderColor: "rgb(43, 63, 84)" }}

                    onClick={() => { this.setState({ addsubscriber: false }) }}
                  >

                    Cancel
                  </Button>
                  <Button variant="contained"
                    style={{ backgroundColor: "rgb(43, 63, 84)", fontSize: "15px", height: "48px", width: "249px", borderRadius: "4px", marginLeft: "10px" }}
                    onClick={() => {
                      this.props.createExcelSubscriber(this.props.login.org_id, this.state.category, this.state.mailData)
                      this.setState({ addsubscriber: false });
                    }}

                  >

                    Submit
                  </Button>
                </Grid>
              </TabPanel>
            </Tabs>









          </DialogContent>
        </Dialog >

        {/*--------------------Edit-------------------------*/}
        <Dialog
          sx={{

            backdropFilter: "blur(10px)",
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
          maxWidth="xl"
          open={this.state.editsubscriber}
          onClose={() => {
            this.setState({ editsubscriber: false });
          }}

          style={{}}>
          <Grid style={{ width: "700px", marginBottom: "2%", padding: "10px", }}>

            <Tabs>

              {/* <TabList
                style={{

                  width: "600px",

                }}>

                <Tab
                  style={{ backgroundColor: "#FFF4E8", fontWeight: 700, color: "rgb(43, 63, 84)", fontSize: "20px", height: "50px", textAlign: "center" }}
                >  Edit User
                </Tab>

              </TabList> */}



              <TabPanel>
                <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "8%" }}>Category</Typography>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 480, marginLeft: "8%" }}>
                  <Select
                    value={this.state.category}
                    onChange={(e) => {
                      this.setState({ category: e.target.value })
                    }}
                  >
                    {Array.isArray(this.props.category.allCategory) && this.props.category.allCategory.map(element => {
                      return (<MenuItem value={element._id}>{element.name}</MenuItem>)
                    })}
                  </Select>
                </FormControl>
                <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "8%" }}>User Name</Typography>
                <TextField id="standard-basic" variant="standard" style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
                  type="text"
                  value={this.state.name}
                  placeholder=' Enter User Name'
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
                <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "8%" }}>Enter Email</Typography>
                <TextField id="standard-basic" variant="standard" style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
                  type="Email"
                  value={this.state.email}
                  placeholder=' Enter Email Id'
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
                <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "8%" }}>Enter Mobile</Typography>
                <TextField id="standard-basic" variant="standard" style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
                  type="number"
                  value={this.state.phone}
                  placeholder=' Enter Mobile Number'
                  onChange={(e) => this.setState({ phone: e.target.value })}
                />

                <Grid style={{ marginTop: "20px", marginLeft: "8%", marginRight: "3%", display: "flex", flexDirection: "row", }}>

                  <Button variant="outlined"
                    style={{ fontSize: "15px", height: "48px", width: "249px", borderRadius: "4px", color: "#161412", borderColor: "rgb(43, 63, 84)" }}

                    onClick={() => { this.setState({ editsubscriber: false }) }}
                  >

                    Cancel
                  </Button>
                  <Button variant="contained"
                    style={{ backgroundColor: "rgb(43, 63, 84)", fontSize: "15px", height: "48px", width: "249px", borderRadius: "4px", marginLeft: "10px" }}
                    onClick={() => {
                      // this.props.deleteSpace(this.state.space_id, this.props.login.org_details2._id)
                      this.setState({ editsubscriber: false });
                      this.props.editSubscriber(this.props.login.org_id,
                        this.state.subscriberId,
                        this.state.name,
                        this.state.email,
                        this.state.phone,
                        this.state.category,
                        this.state.page,
                        this.state.rowsPerPage)
                    }}

                  >

                    Update
                  </Button>
                </Grid>
              </TabPanel>
            </Tabs>






          </Grid>
        </Dialog>


        {/*--------------------Delete-------------------------*/}
        <Dialog

          open={this.state.Subscriberdelete}
          maxWidth="sm"
          fullWidth
          sx={{

            backdropFilter: "blur(10px)",
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
        >
          <DialogTitle className='titlespace'>
            <Grid container marginTop={1}>
              <Grid item xs={.5} />
              <Grid item>
                <Typography className='title'>Delete Subscriber</Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container >
              <Grid item xs={.5} />
              <Grid item xs={11} direction='column'>
                <Typography >
                  Are you sure do you want to delete this Subscriber?
                </Typography>
              </Grid>
              <Grid item xs={.5} />
            </Grid>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'center', marginBottom: '5%', }} >
            <Button
              variant="outlined"
              className='cancel'
              onClick={() => {
                this.setState({ Subscriberdelete: false });
              }}
            >
              No, Cancel it.
            </Button>
            <Button
              variant="contained"
              className='submit'
              onClick={() => {
                this.setState({
                  Subscriberdelete: false
                });
                this.props.deleteSubscriber(this.props.login.org_id, this.state.subscriberId, this.state.page, this.state.rowsPerPage)
              }}
            >
              Yes, Delete it.
            </Button>
          </DialogActions>
        </Dialog >

      </Grid >
    )
  }
}
