import React, { Component } from 'react'
import {
  Grid, Typography, Button
} from '@mui/material';
import "../../SendMail/Components/SendMail.css"
import SearchIcon from '../../image/search.png'
import SendIcon from '@mui/icons-material/Send';
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import {
  OutlinedInput,
  InputAdornment,
  DialogContent,
  DialogActions,
  CircularProgress,
  TablePagination,
  Box,
  TableContainer
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import upload from "../../image/upload.png";
import LoadingButton from '@mui/lab/LoadingButton';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { debounce } from 'lodash';
import LoaderCon from "../../../common/loader/containers/loader_cont";
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import { green } from '@mui/material/colors';
import { Link } from "react-router-dom";
import Icon from "@mui/material/Icon";
import TabletMacOutlinedIcon from '@mui/icons-material/TabletMacOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import DesktopMacOutlinedIcon from '@mui/icons-material/DesktopMacOutlined';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import Paper from '@mui/material/Paper';
export default class Mail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      success: false,
      addsendmail: false,
      receivers: false,
      emaildelete: false,
      password: '',
      showPassword: false,
      isValidEmail: true,
      isValidEmailcc: true,
      selectedOption: 'option1',
      sendmaildata: [
        {
          date: "10/12/22",
          time: "10AM",
          emails: "priti@gmail.com",
          campaign: "campaign num-1",
          status: "Send",
          spam: "No"

        },
        {
          date: "10/12/22",
          time: "10AM",
          emails: "priti@gmail.com",
          campaign: "campaign num-1",
          status: "Bounced",
          spam: "No"

        },
        {

          date: "10/12/22",
          time: "10AM",
          emails: "priti@gmail.com",
          campaign: "campaign num-1",
          status: "Delivered",
          spam: "No"


        },
      ],
      individualopen: true,
      checkAttachment: false,
      document: "",
      mailData: [],
      page: 0,
      rowsPerPage: 10,
      reciver_id: '',
      isHTML: RegExp.prototype.test.bind(/(<([^>]+)>)/i),
      view: 'desktop',
      summary: false,
      item_object: {}
    };
    this.timer = React.createRef();
    this.debouncedViewAllSubscriber = debounce(this.props.viewAllSendEmail, 500);
  }

  componentWillUnmount() {
    clearTimeout(this.timer.current);
  }

  handleButtonClick = () => {

    if (!this.state.loading) {
      this.setState({ success: false, loading: true });
      this.props.uploadDocument(this.state.document)
      this.timer.current = window.setTimeout(() => {
        this.setState({ success: true, loading: false });
      }, 2000);
    }
  };

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };
  handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      this.parseExcelData(file);
    }
  };
  parseExcelData = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0]; // Assuming you're interested in the first sheet
      const sheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(sheet);
      console.log(excelData)
      const mailArray = excelData.map((item) => (item.EMAIL));
      this.setState({ mailData: mailArray });
      console.log(mailArray)
    };

    reader.readAsBinaryString(file);
  };

  downloadSampleFile = () => {
    // Define your sample data
    const data = [
      ['NAME', 'EMAIL', 'PHONE'],
      ['SWADHIN DAS', 'ABC@GMAIL.COM', '917438082965'],
      ['SOUMYARANJAN ROUT', 'XYZ@GMAIL.COM', '917684566854'],
      // Add more rows as needed
    ];

    // Create a new workbook and set the data
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'SampleSheet');

    // Write the workbook to a file
    XLSX.writeFile(wb, 'sample_file.xlsx');
  };
  toggleShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.setState({
      email: enteredEmail,
      isValidEmail: emailRegex.test(enteredEmail),
    });
  };


  handlecc = (e) => {
    const enteredEmail = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.setState({
      email: enteredEmail,
      isValidEmailcc: emailRegex.test(enteredEmail),
    });
  };


  handleOptionChange = (event) => {
    console.log(event.target.value)
    this.setState({
      selectedOption: event.target.value,
      document: ''
    });
  };

  componentDidMount() {
    this.props.viewAllSendEmail(this.props.login.org_id, this.state.page, this.state.rowsPerPage, "")
    this.props.viewAllOrgTemplate(this.props.login.org_id)
    console.log(this.props.template.allTemplate)
  }


  handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    this.setState({ rowsPerPage: newRowsPerPage, page: 0 });
    this.props.viewAllSendEmail(this.props.login.org_id, 0, newRowsPerPage, "")
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    // Fetch data with the new page value
    this.props.viewAllSendEmail(this.props.login.org_id, newPage, this.state.rowsPerPage, "")
  };

  render() {
    const { selectedOption, view, isHTML } = this.state;
    return (
      <Grid>
        <Grid container justifyContent={'left'}>
          <Typography className='topic'>Campaign</Typography>
        </Grid>
        <LoaderCon />
        <Grid container className='page'>
          <Grid item xs={12} className='box'>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={10}>
                <FormControl fullWidth variant="standard" id='searchCat'>
                  <OutlinedInput
                    type={'text'}
                    className='textfield'
                    sx={{
                      '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                        boxShadow: '0px 2px 0px lightgray',
                      },
                      '.MuiOutlinedInput-notchedOutline': { border: '1px solid #E2E0DD' },
                      height: '48px',
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <LoadingButton
                            loading={this.state.searching}
                            loadingPosition="center"
                            variant="text"
                            onClick={() => {
                              this.setState({
                                add: true,
                              });
                            }}
                          >
                            {!this.state.searching && <img src={SearchIcon} alt="Search Category" style={{ font: '24px' }} />}
                          </LoadingButton>
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder='Search'
                    onChange={(event) => {
                      this.setState({ searching: event.target.value !== '' ? true : false });
                      this.debouncedViewAllSubscriber(this.props.login.org_id, this.state.page, this.state.rowsPerPage, event.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={2}>
                <LoadingButton
                  id="addCat"
                  fullWidth
                  style={{ height: '48px' }}
                  className='add'
                  startIcon={this.props.loader.progress ? '' : <SendIcon />}
                  loading={this.props.loader.progress}
                  loadingPosition='center'
                  variant='contained'
                  onClick={() => {
                    this.props.viewAllOrgCategory(this.props.login.org_id)
                    this.props.viewAllOrgAccounts(this.props.login.org_id)
                    this.setState({ addsendmail: true });
                  }}
                >
                  {this.props.loader.progress ? (
                    <CircularProgress
                      size={24} // Adjust the size of the circular loader as needed
                      style={{ color: 'white' }}
                    />
                  ) :
                    <Typography>Send Mail</Typography>
                  }
                </LoadingButton>
              </Grid>
            </Grid>
            <Grid>
              <TableContainer id="tableCat" >
                <Table stickyHeader aria-label="sticky table" style={{ marginTop: '20px' }}>
                  <TableHead className="customTableHead">
                    <TableRow >
                      <TableCell justifyContent={'center'}>
                        <Typography className='headtext' textAlign={'center'}>Date</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className='headtext' textAlign={'center'}>Time</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className='headtext' textAlign={'center'}>Emails</Typography>
                      </TableCell>
                      <TableCell align='center'>
                        <Typography className='headtext' textAlign={'center'}>Confirm Mail</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className='headtext' textAlign={'center'}>Campaign Name</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className='headtext' textAlign={'center'}>Status</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className='headtext' textAlign={'center'}>Email Type</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className='headtext' textAlign={'center'}>Action</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(this.props.sendEmail.searchEmail) && this.props.sendEmail.searchEmail.map((item, index) => {
                      return (
                        <TableRow hover tabIndex={-1}>
                          <TableCell justifyContent={'center'}>
                            <Typography className='celltext'>{moment(item.createdAt).format('YYYY-MM-DD')}</Typography>
                          </TableCell>
                          <TableCell justifyContent={'center'}>
                            <Typography className='celltext'>{moment(item.createdAt).format('HH:mm:ss')}</Typography>
                          </TableCell>
                          <TableCell justifyContent={'center'}>
                            <Button style={{ backgroundColor: '#FFF4E8', borderColor: 'yellow', borderRadius: 50 }}
                              variant="outlined"
                              onClick={() => {
                                this.setState({
                                  receivers: true,
                                  reciver_id: item.reciver_id,
                                });
                              }} >
                              <Typography className='celltext' fontWeight={'bold'} >{Array.isArray(item.reciver_id) ? item.reciver_id.length : 0}</Typography>
                            </Button>
                          </TableCell>
                          <TableCell justifyContent={'center'}>
                            <Button style={{ backgroundColor: '#FFF4E8', borderColor: 'yellow', borderRadius: 50 }}
                              variant="outlined" >
                              <Typography className='celltext' fontWeight={'bold'} >{Array.isArray(item.mail_status) ? item.mail_status.length : 0}</Typography>
                            </Button>
                          </TableCell>
                          <TableCell justifyContent={'center'}>
                            <Typography className='celltext' textAlign={'center'}>{item.name}</Typography>
                          </TableCell>
                          <TableCell style={{ textAlign: 'center' }}>
                            <Button style={{
                              backgroundColor: item.status === 'Sent' ? '#EDFFFF' : item.status === 'On Queue' ? '#FFF0EB' : item.status === 'Delivered' ? '#E8F3FF' : "",
                              color: item.status === 'Sent' ? '#005757' : item.status === 'On Queue' ? '#FF795C' : item.status === 'Delivered' ? '#0764D1' : "",
                              border: item.status === 'Sent' ? '#EDFFFF' : item.status === 'On Queue' ? '#FFF0EB' : item.status === 'Delivered' ? '#E8F3FF' : "",
                              borderRadius: "20px",
                            }}
                              variant="outlined" >{item.status}
                            </Button>
                          </TableCell>
                          <TableCell align='center' style={{ textAlign: 'center' }}>
                            <Button style={{
                              backgroundColor: "#E2E0DD",
                              color: "#5A544E",
                              border: "#E2E0DD",
                              borderRadius: "20px",

                            }}
                              variant="outlined" >{item.email_type === "individual" ? "Individual" : item.email_type === "bulk" ? "Bulk" : "Category"}
                            </Button>
                          </TableCell>
                          <TableCell align='center' style={{ textAlign: 'center' }}>
                            {/* <Link to="/Summary"> */}
                            <Button
                              variant="contained"
                              onClick={() => {
                                this.setState({ summary: true, item_object: item });
                              }}
                              className="summary-button"
                              style={{ color: "white" }}
                            >
                              Summary
                            </Button>
                            {/* </Link> */}
                          </TableCell>


                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 50]}
                  component="div"
                  count={this.props.sendEmail.emaillength}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>

        {/* --------------------send mail-------------------- */}
        <Dialog
          open={this.state.addsendmail}
          maxWidth="md"
          fullWidth
          style={{
            backdropFilter: "blur(10px)",
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
        >
          {/* <DialogTitle className='titlespace'>
            <Grid container marginTop={1}>
              <Grid item xs={.5} />
              <Grid item>
                <Typography className='title'>Send Email</Typography>
              </Grid>
            </Grid>
          </DialogTitle> */}
          <DialogContent>
            <Grid container >
              <Grid item xs={.5} />
              <Grid item xs={11} direction='column'>
                <Tabs>
                  <TabList
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly"
                    }}>
                    <Tab
                      style={{ backgroundColor: "#FFF4E8", color: "rgb(43, 63, 84)", fontWeight: 700, fontSize: "17px", height: "50px", textAlign: "center" }}
                      onClick={() => {
                        this.setState({
                          individualopen: true,
                          checkAttachment: false,
                          selectedOption: "",
                          document: "",
                        })
                      }}
                    >
                      Individual
                    </Tab>
                    <Tab
                      style={{ backgroundColor: "#FFF4E8", color: "rgb(43, 63, 84)", fontWeight: 700, fontSize: "17px", height: "50px", textAlign: "center" }}
                      onClick={() => {
                        this.setState({
                          categoryopen: true,
                          checkAttachment: false,
                          selectedOption: "",
                          document: "",
                        })
                      }}
                    >
                      Category
                    </Tab>
                    <Tab
                      style={{ backgroundColor: "#FFF4E8", color: "rgb(43, 63, 84)", fontWeight: 700, fontSize: "17px", height: "50px", textAlign: "center" }}
                      onClick={() => {
                        this.setState({
                          bulkmailopen: true,
                          checkAttachment: false,
                          selectedOption: "",
                          document: "",
                        })
                      }}
                    >
                      Bulk mail
                    </Tab>
                  </TabList>
                  {/* ----individual------ */}
                  <TabPanel >
                    <Grid container>
                      <Grid item xs={2} />
                      <Grid item xs={8} direction='column'>
                        <Typography style={{ fontWeight: 'bold' }} >
                          Email ID
                        </Typography>
                        <TextField
                          variant='outlined'
                          type="text"
                          fullWidth
                          value={this.state.reciver_id}
                          placeholder='Enter email'
                          className='textfield'
                          sx={{
                            '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                              boxShadow: '0px 2px 0px lightgray',
                            },
                          }}
                          onChange={(event) => {
                            this.setState({ reciver_id: event.target.value });

                          }}
                        />

                        <Typography style={{ marginTop: 15, fontWeight: 'bold' }} >
                          Subject
                        </Typography>
                        <TextField
                          variant='outlined'
                          type="text"
                          fullWidth
                          value={this.state.subject}
                          placeholder='Enter Subject Name'
                          className='textfield'
                          sx={{
                            '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                              boxShadow: '0px 2px 0px lightgray',
                            },
                          }}
                          onChange={(event) => {
                            this.setState({ subject: event.target.value });

                          }}
                        />

                        <Typography style={{ marginTop: 15, fontWeight: 'bold' }} >
                          Sending Account
                        </Typography>
                        <Select
                          fullWidth
                          value={this.state.sending_account_id}  // Changed from this.state.dept to this.state.category_dept
                          onChange={(e) => {
                            this.setState({ sending_account_id: e.target.value });
                          }}
                          className='select'
                          sx={{
                            '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                              boxShadow: '0px 2px 0px lightgray',
                            },
                          }}
                        >
                          <MenuItem disabled value="">
                            {'Select Account'}
                          </MenuItem>
                          {Array.isArray(this.props.account.all_account) && this.props.account.all_account.map((element) => {
                            return (
                              <MenuItem value={element._id}>{element.email}</MenuItem>
                            )

                          })
                          }
                        </Select>

                        <Typography style={{ marginTop: 15, fontWeight: 'bold' }} >
                          Template
                        </Typography>
                        <Select
                          fullWidth
                          value={this.state.template_id}  // Changed from this.state.dept to this.state.category_dept
                          onChange={(e) => {
                            this.setState({ template_id: e.target.value });
                          }}
                          className='select'
                          sx={{
                            '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                              boxShadow: '0px 2px 0px lightgray',
                            },
                          }}
                        >
                          <MenuItem disabled value="">
                            {'Select Template'}
                          </MenuItem>
                          {
                            Array.isArray(this.props.template.allTemplate) && this.props.template.allTemplate.filter(element => element.type === 'email').map((element, index) => {
                              return (
                                <MenuItem value={element._id}
                                  onClick={() => {
                                    this.setState({ template: element.temp_body })
                                  }}
                                >{element.temp_name}</MenuItem>
                              )
                            })
                          }
                        </Select>

                        <FormGroup style={{ marginTop: 15 }}>
                          <FormControlLabel control={<Checkbox
                            checked={this.state.checkAttachment}
                            onChange={() => { this.setState({ checkAttachment: !this.state.checkAttachment }) }}
                          />} label="Additional Attachment" />
                        </FormGroup>





                      </Grid>
                      <Grid item xs={2} />
                    </Grid>
                    <Grid container>
                      <Grid item xs={1} />
                      <Grid item xs={10} direction='row'>
                        {this.state.checkAttachment &&
                          <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "2%" }}>Select any one</Typography>}
                        {this.state.checkAttachment &&
                          <FormControl component="fieldset" fullWidth style={{ marginLeft: "2%" }}>
                            <RadioGroup
                              aria-label="options"
                              name="options"
                              value={selectedOption}
                              onChange={this.handleOptionChange}
                              style={{ width: "100%" }} // Set width to 100% to take full width
                            >
                              <Grid container style={{ justifyContent: 'space-between', width: "100%" }}>
                                <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                                  <Grid style={{ display: 'flex' }}>
                                    <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                                      <FormControlLabel value="Upload attachment" control={<Radio />} label="Upload attachment" />
                                      {this.state.selectedOption === "Upload attachment" &&
                                        <center>
                                          <input style={{ display: "none" }} type="file" id="file"
                                            onChange={(e) => {
                                              this.setState({ document: e.target.files[0] })
                                              console.log(this.state.document.name)
                                            }
                                            }
                                          />
                                          <label htmlFor="file">
                                            <div style={{
                                              borderRadius: "48px",
                                              background: "var(--TB_BrandColor-Primary-10, #FFF4E8)"
                                            }}>
                                              {this.state.document === "" ? <Typography>Click here to Upload</Typography> : <Typography>Selected</Typography>}
                                            </div>
                                          </label>

                                        </center>
                                      }
                                    </Grid>
                                    {(this.state.selectedOption === "Upload attachment" && this.state.document !== "") &&
                                      <>
                                        <Box style={{ margin: 5, position: 'relative', borderRadius: '50%', }}>
                                          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                            <Fab
                                              aria-label="save"
                                              style={{ backgroundColor: 'rgb(43, 63, 84)', borderRadius: '50%' }}
                                              onClick={this.handleButtonClick}
                                            >
                                              {this.props.sendEmail.url === "" ? <CheckIcon style={{ color: 'white' }} /> : <SaveIcon style={{ color: 'white' }} />}
                                            </Fab>
                                          </div>
                                          {this.props.loader.progress && (
                                            <CircularProgress
                                              size={68}
                                              sx={{
                                                color: green[500],
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-34px', // half of the CircularProgress size
                                                marginLeft: '-34px', // half of the CircularProgress size
                                                zIndex: 1,
                                              }}
                                            />
                                          )}
                                        </Box>


                                      </>
                                    }
                                  </Grid>
                                  <Grid style={{ display: 'flex' }}>

                                  </Grid>
                                </Grid>
                                <Grid>
                                  <FormControlLabel value="Send template as pdf as well" control={<Radio />} label="Send template as pdf as well" />
                                  {this.state.selectedOption === "Send template as pdf as well" &&
                                    <Grid item xs={12}>
                                      <Select
                                        fullWidth
                                        value={this.state.document}  // Changed from this.state.dept to this.state.category_dept
                                        onChange={(e) => {
                                          this.setState({ document: e.target.value })
                                        }}
                                        className='select'
                                        sx={{
                                          '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                                            boxShadow: '0px 2px 0px lightgray',
                                          },
                                        }}
                                      >
                                        <MenuItem disabled value="">
                                          {'Select Template'}
                                        </MenuItem>
                                        {
                                          Array.isArray(this.props.template.allTemplate) && this.props.template.allTemplate.filter(element => element.type === 'document').map((element, index) => {
                                            return (
                                              <MenuItem value={element.temp_body}
                                                onClick={() => {
                                                  this.setState({ document: element.temp_body })
                                                }}
                                              >{element.temp_name}</MenuItem>
                                            )
                                          })
                                        }
                                      </Select>
                                    </Grid>}
                                </Grid>
                              </Grid>
                            </RadioGroup>
                          </FormControl>}


                      </Grid>
                      <Grid item xs={1} />
                    </Grid>
                    <Grid container>
                      <Grid item xs={2} />
                      <Grid item xs={8} direction='row'>
                        <DialogActions style={{ display: 'flex', justifyContent: 'center' }} >
                          <Button
                            variant="outlined"
                            className='cancel'
                            onClick={() => {
                              this.setState({
                                addsendmail: false,
                                reciver_id: "",
                                sending_account_id: "",
                                selectedOption: "",
                                template_id: "",
                                template: "",
                                checkAttachment: false
                              });
                              this.props.setDocument("")
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={this.state.subject === "" || this.state.reciver_id === ""
                              || this.state.template_id === ""}
                            variant="contained"
                            className={(this.state.subject === "" || this.state.reciver_id === ""
                              || this.state.template_id === "") ? 'disabled' : 'submit'}
                            onClick={() => {
                              // this.props.deleteSpace(this.state.space_id, this.props.login.org_details2._id)
                              this.setState({ addsendmail: false });
                              if (this.state.selectedOption === "Send template as pdf as well") {
                                this.props.sendCampaignIndividual(this.state.subject, this.state.subject,
                                  "individual", [this.state.reciver_id], this.state.sending_account_id,
                                  this.state.template, this.state.template_id, "", this.props.login.org_id, this.state.selectedOption, this.state.document)
                              }
                              else if (this.state.selectedOption === "Upload attachment") {
                                this.props.sendCampaignIndividual(this.state.subject, this.state.subject,
                                  "individual", [this.state.reciver_id], this.state.sending_account_id,
                                  this.state.template, this.state.template_id, "", this.props.login.org_id, this.state.selectedOption, this.props.sendEmail.url)
                              }
                              else if (!this.state.checkAttachment) {
                                this.props.sendCampaignIndividual(this.state.subject, this.state.subject,
                                  "individual", [this.state.reciver_id], this.state.sending_account_id,
                                  this.state.template, this.state.template_id, "", this.props.login.org_id, " ")
                              }
                              this.setState({
                                reciver_id: "",
                                sending_account_id: "",
                                selectedOption: "",
                                template_id: "",
                                template: "",
                                checkAttachment: false,
                              })
                              this.props.setDocument("")
                            }}
                          >
                            Send Mail
                          </Button>
                        </DialogActions>
                      </Grid>
                      <Grid item xs={2} />
                    </Grid>

                  </TabPanel>

                  {/* ----Category------ */}
                  <TabPanel >
                    <Grid container>
                      <Grid item xs={2} />
                      <Grid item xs={8} direction='column'>
                        <Typography style={{ marginTop: 15, fontWeight: 'bold' }} >
                          Subject
                        </Typography>
                        <TextField
                          variant='outlined'
                          type="text"
                          fullWidth
                          value={this.state.subject}
                          placeholder='Enter Subject Name'
                          className='textfield'
                          sx={{
                            '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                              boxShadow: '0px 2px 0px lightgray',
                            },
                          }}
                          onChange={(event) => {
                            this.setState({ subject: event.target.value });

                          }}
                        />

                        <Typography style={{ marginTop: 15, fontWeight: 'bold' }} >
                          Category
                        </Typography>
                        <Select
                          fullWidth
                          value={this.state.category}  // Changed from this.state.dept to this.state.category_dept
                          onChange={(e) => {
                            this.setState({ category: e.target.value });
                          }}
                          className='select'
                          sx={{
                            '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                              boxShadow: '0px 2px 0px lightgray',
                            },
                          }}
                        >
                          <MenuItem disabled value="">
                            {'Select Category'}
                          </MenuItem>
                          {Array.isArray(this.props.category.allCategory) && this.props.category.allCategory.map(element => {
                            return (<MenuItem value={element._id}>{element.name}</MenuItem>)
                          })}
                        </Select>

                        <Typography style={{ marginTop: 15, fontWeight: 'bold' }} >
                          Sending Account
                        </Typography>
                        <Select
                          fullWidth
                          value={this.state.sending_account_id}  // Changed from this.state.dept to this.state.category_dept
                          onChange={(e) => {
                            this.setState({ sending_account_id: e.target.value });
                          }}
                          className='select'
                          sx={{
                            '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                              boxShadow: '0px 2px 0px lightgray',
                            },
                          }}
                        >
                          <MenuItem disabled value="">
                            {'Select Account'}
                          </MenuItem>
                          {Array.isArray(this.props.account.all_account) && this.props.account.all_account.map((element) => {
                            return (
                              <MenuItem value={element._id}>{element.email}</MenuItem>
                            )

                          })
                          }
                        </Select>

                        <Typography style={{ marginTop: 15, fontWeight: 'bold' }} >
                          Template
                        </Typography>
                        <Select
                          fullWidth
                          value={this.state.template_id}  // Changed from this.state.dept to this.state.category_dept
                          onChange={(e) => {
                            this.setState({ template_id: e.target.value });
                          }}
                          className='select'
                          sx={{
                            '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                              boxShadow: '0px 2px 0px lightgray',
                            },
                          }}
                        >
                          <MenuItem disabled value="">
                            {'Select Template'}
                          </MenuItem>
                          {
                            Array.isArray(this.props.template.allTemplate) && this.props.template.allTemplate.filter(element => element.type === 'email').map((element, index) => {
                              return (
                                <MenuItem value={element._id}
                                  onClick={() => {
                                    this.setState({ template: element.temp_body })
                                  }}
                                >{element.temp_name}</MenuItem>
                              )
                            })
                          }
                        </Select>

                        <FormGroup style={{ marginTop: 15 }}>
                          <FormControlLabel control={<Checkbox
                            checked={this.state.checkAttachment}
                            onChange={() => { this.setState({ checkAttachment: !this.state.checkAttachment }) }}
                          />} label="Additional Attachment" />
                        </FormGroup>





                      </Grid>
                      <Grid item xs={2} />
                    </Grid>
                    <Grid container>
                      <Grid item xs={1} />
                      <Grid item xs={10} direction='row'>
                        {this.state.checkAttachment &&
                          <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "2%" }}>Select any one</Typography>}
                        {this.state.checkAttachment &&
                          <FormControl component="fieldset" fullWidth style={{ marginLeft: "2%" }}>
                            <RadioGroup
                              aria-label="options"
                              name="options"
                              value={selectedOption}
                              onChange={this.handleOptionChange}
                              style={{ width: "100%" }} // Set width to 100% to take full width
                            >
                              <Grid container style={{ justifyContent: 'space-between', width: "100%" }}>
                                <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                                  <Grid style={{ display: 'flex' }}>
                                    <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                                      <FormControlLabel value="Upload attachment" control={<Radio />} label="Upload attachment" />
                                      {this.state.selectedOption === "Upload attachment" &&
                                        <center>
                                          <input style={{ display: "none" }} type="file" id="file"
                                            onChange={(e) => {
                                              this.setState({ document: e.target.files[0] })
                                              console.log(this.state.document.name)
                                            }
                                            }
                                          />
                                          <label htmlFor="file">
                                            <div style={{
                                              borderRadius: "48px",
                                              background: "var(--TB_BrandColor-Primary-10, #FFF4E8)"
                                            }}>
                                              {this.state.document === "" ? <Typography>Click here to Upload</Typography> : <Typography>Selected</Typography>}
                                            </div>
                                          </label>

                                        </center>
                                      }
                                    </Grid>
                                    {(this.state.selectedOption === "Upload attachment" && this.state.document !== "") &&
                                      <>
                                        <Box style={{ margin: 5, position: 'relative', borderRadius: '50%', }}>
                                          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                            <Fab
                                              aria-label="save"
                                              style={{ backgroundColor: 'rgb(43, 63, 84)', borderRadius: '50%' }}
                                              onClick={this.handleButtonClick}
                                            >
                                              {this.state.success ? <CheckIcon style={{ color: 'white' }} /> : <SaveIcon style={{ color: 'white' }} />}
                                            </Fab>
                                          </div>
                                          {this.state.loading && (
                                            <CircularProgress
                                              size={68}
                                              sx={{
                                                color: green[500],
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-34px', // half of the CircularProgress size
                                                marginLeft: '-34px', // half of the CircularProgress size
                                                zIndex: 1,
                                              }}
                                            />
                                          )}
                                        </Box>


                                      </>
                                    }
                                  </Grid>
                                  <Grid style={{ display: 'flex' }}>

                                  </Grid>
                                </Grid>
                                <Grid>
                                  <FormControlLabel value="Send template as pdf as well" control={<Radio />} label="Send template as pdf as well" />
                                  {this.state.selectedOption === "Send template as pdf as well" &&
                                    <Grid item xs={12}>
                                      <Select
                                        fullWidth
                                        value={this.state.document}  // Changed from this.state.dept to this.state.category_dept
                                        onChange={(e) => {
                                          this.setState({ document: e.target.value })
                                        }}
                                        className='select'
                                        sx={{
                                          '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                                            boxShadow: '0px 2px 0px lightgray',
                                          },
                                        }}
                                      >
                                        <MenuItem disabled value="">
                                          {'Select Template'}
                                        </MenuItem>
                                        {
                                          Array.isArray(this.props.template.allTemplate) && this.props.template.allTemplate.filter(element => element.type === 'document').map((element, index) => {
                                            return (
                                              <MenuItem value={element.temp_body}
                                                onClick={() => {
                                                  this.setState({ document: element.temp_body })
                                                }}
                                              >{element.temp_name}</MenuItem>
                                            )
                                          })
                                        }
                                      </Select>
                                    </Grid>}
                                </Grid>
                              </Grid>
                            </RadioGroup>
                          </FormControl>}


                      </Grid>
                      <Grid item xs={1} />
                    </Grid>
                    <Grid container>
                      <Grid item xs={2} />
                      <Grid item xs={8} direction='row'>
                        <DialogActions style={{ display: 'flex', justifyContent: 'center' }} >
                          <Button
                            variant="outlined"
                            className='cancel'
                            onClick={() => {
                              this.setState({
                                addsendmail: false,
                                reciver_id: "",
                                sending_account_id: "",
                                selectedOption: "",
                                template_id: "",
                                template: "",
                                checkAttachment: false
                              });
                              this.props.setDocument("")
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={this.state.subject === "" || this.state.category === ""
                              || this.state.template_id === ""
                            }
                            variant="contained"
                            className={(this.state.subject === "" || this.state.reciver_id === ""
                              || this.state.template_id === "") ? 'disabled' : 'submit'}
                            onClick={() => {
                              // this.props.deleteSpace(this.state.space_id, this.props.login.org_details2._id)
                              this.setState({ addsendmail: false });
                              if (this.state.selectedOption === "Send template as pdf as well") {
                                this.props.sendCampaignCategory(this.state.subject, this.state.subject,
                                  "category", "", this.state.sending_account_id,
                                  this.state.template, this.state.template_id, this.state.category, this.props.login.org_id, this.state.selectedOption, this.state.document)
                              }
                              else if (this.state.selectedOption === "Upload attachment") {
                                this.props.sendCampaignCategory(this.state.subject, this.state.subject,
                                  "category", "", this.state.sending_account_id,
                                  this.state.template, this.state.template_id, this.state.category, this.props.login.org_id, this.state.selectedOption, this.props.sendEmail.url)
                              }
                              else if (!this.state.checkAttachment) {
                                this.props.sendCampaignCategory(this.state.subject, this.state.subject,
                                  "category", "", this.state.sending_account_id,
                                  this.state.template, this.state.template_id, this.state.category, this.props.login.org_id, " ")
                              }
                              this.setState({
                                reciver_id: "",
                                sending_account_id: "",
                                selectedOption: "",
                                template_id: "",
                                template: "",
                                checkAttachment: false,
                              })
                              this.props.setDocument("")
                            }}
                          >
                            Send Mail
                          </Button>
                        </DialogActions>
                      </Grid>
                      <Grid item xs={2} />
                    </Grid>

                  </TabPanel>

                  {/* ----Bulk Mail------ */}
                  <TabPanel >
                    <Grid container>
                      <Grid item xs={2} />
                      <Grid item xs={8} direction='column'>
                        <Typography style={{ marginTop: 15, fontWeight: 'bold' }} >
                          Subject
                        </Typography>
                        <TextField
                          variant='outlined'
                          type="text"
                          fullWidth
                          value={this.state.subject}
                          placeholder='Enter Subject Name'
                          className='textfield'
                          sx={{
                            '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                              boxShadow: '0px 2px 0px lightgray',
                            },
                          }}
                          onChange={(event) => {
                            this.setState({ subject: event.target.value });

                          }}
                        />

                        <Typography style={{ marginTop: 15, fontWeight: 'bold' }} >
                          Sending Account
                        </Typography>
                        <Select
                          fullWidth
                          value={this.state.sending_account_id}  // Changed from this.state.dept to this.state.category_dept
                          onChange={(e) => {
                            this.setState({ sending_account_id: e.target.value });
                          }}
                          className='select'
                          sx={{
                            '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                              boxShadow: '0px 2px 0px lightgray',
                            },
                          }}
                        >
                          <MenuItem disabled value="">
                            {'Select Account'}
                          </MenuItem>
                          {Array.isArray(this.props.account.all_account) && this.props.account.all_account.map((element) => {
                            return (
                              <MenuItem value={element._id}>{element.email}</MenuItem>
                            )

                          })
                          }
                        </Select>

                        <Typography style={{ marginTop: 15, fontWeight: 'bold' }} >
                          Template
                        </Typography>
                        <Select
                          fullWidth
                          value={this.state.template_id}  // Changed from this.state.dept to this.state.category_dept
                          onChange={(e) => {
                            this.setState({ template_id: e.target.value });
                          }}
                          className='select'
                          sx={{
                            '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                              boxShadow: '0px 2px 0px lightgray',
                            },
                          }}
                        >
                          <MenuItem disabled value="">
                            {'Select Template'}
                          </MenuItem>
                          {
                            Array.isArray(this.props.template.allTemplate) && this.props.template.allTemplate.filter(element => element.type === 'email').map((element, index) => {
                              return (
                                <MenuItem value={element._id}
                                  onClick={() => {
                                    this.setState({ template: element.temp_body })
                                  }}
                                >{element.temp_name}</MenuItem>
                              )
                            })
                          }
                        </Select>

                        <center style={{ marginTop: "20px" }} >
                          <input style={{ display: "none" }} type="file" id="file" onChange={this.handleFileUpload} />
                          <label htmlFor="file">
                            <div style={{
                              borderRadius: "48px", height: "60x", width: "36px",
                              background: "var(--TB_BrandColor-Primary-10, #FFF4E8)"
                            }}>
                              <img alt='' style={{}} src={upload} />
                            </div>
                          </label>

                        </center>
                        <center>
                          <Typography style={{ marginTop: "5px", fontSize: "18px", color: "#161412", fontWeight: 500, }}>{this.state.mailData.length > 0 ? "Uploaded" : "Click here to upload"}</Typography>
                        </center>
                        <center>
                          <Typography style={{ marginTop: "5px", fontSize: "14px", color: "#87817B", fontWeight: 400, }}>(File format: xsl/xlsx format)
                            <Button
                              onClick={this.downloadSampleFile}
                            >Click here to download format</Button>
                          </Typography>
                        </center>

                        <FormGroup style={{ marginTop: 15 }}>
                          <FormControlLabel control={<Checkbox
                            checked={this.state.checkAttachment}
                            onChange={() => { this.setState({ checkAttachment: !this.state.checkAttachment }) }}
                          />} label="Additional Attachment" />
                        </FormGroup>





                      </Grid>
                      <Grid item xs={2} />
                    </Grid>
                    <Grid container>
                      <Grid item xs={1} />
                      <Grid item xs={10} direction='row'>
                        {this.state.checkAttachment &&
                          <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "2%" }}>Select any one</Typography>}
                        {this.state.checkAttachment &&
                          <FormControl component="fieldset" fullWidth style={{ marginLeft: "2%" }}>
                            <RadioGroup
                              aria-label="options"
                              name="options"
                              value={selectedOption}
                              onChange={this.handleOptionChange}
                              style={{ width: "100%" }} // Set width to 100% to take full width
                            >
                              <Grid container style={{ justifyContent: 'space-between', width: "100%" }}>
                                <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                                  <Grid style={{ display: 'flex' }}>
                                    <Grid style={{ display: 'flex', flexDirection: 'column' }}>
                                      <FormControlLabel value="Upload attachment" control={<Radio />} label="Upload attachment" />
                                      {this.state.selectedOption === "Upload attachment" &&
                                        <center>
                                          <input style={{ display: "none" }} type="file" id="file"
                                            onChange={(e) => {
                                              this.setState({ document: e.target.files[0] })
                                              console.log(this.state.document.name)
                                            }
                                            }
                                          />
                                          <label htmlFor="file">
                                            <div style={{
                                              borderRadius: "48px",
                                              background: "var(--TB_BrandColor-Primary-10, #FFF4E8)"
                                            }}>
                                              {this.state.document === "" ? <Typography>Click here to Upload</Typography> : <Typography>Selected</Typography>}
                                            </div>
                                          </label>

                                        </center>
                                      }
                                    </Grid>
                                    {(this.state.selectedOption === "Upload attachment" && this.state.document !== "") &&
                                      <>
                                        <Box style={{ margin: 5, position: 'relative', borderRadius: '50%', }}>
                                          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                                            <Fab
                                              aria-label="save"
                                              style={{ backgroundColor: 'rgb(43, 63, 84)', borderRadius: '50%' }}
                                              onClick={this.handleButtonClick}
                                            >
                                              {this.state.success ? <CheckIcon style={{ color: 'white' }} /> : <SaveIcon style={{ color: 'white' }} />}
                                            </Fab>
                                          </div>
                                          {this.state.loading && (
                                            <CircularProgress
                                              size={68}
                                              sx={{
                                                color: green[500],
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-34px', // half of the CircularProgress size
                                                marginLeft: '-34px', // half of the CircularProgress size
                                                zIndex: 1,
                                              }}
                                            />
                                          )}
                                        </Box>


                                      </>
                                    }
                                  </Grid>
                                  <Grid style={{ display: 'flex' }}>

                                  </Grid>
                                </Grid>
                                <Grid>
                                  <FormControlLabel value="Send template as pdf as well" control={<Radio />} label="Send template as pdf as well" />
                                  {this.state.selectedOption === "Send template as pdf as well" &&
                                    <Grid item xs={12}>
                                      <Select
                                        fullWidth
                                        value={this.state.document}  // Changed from this.state.dept to this.state.category_dept
                                        onChange={(e) => {
                                          this.setState({ document: e.target.value })
                                        }}
                                        className='select'
                                        sx={{
                                          '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': {
                                            boxShadow: '0px 2px 0px lightgray',
                                          },
                                        }}
                                      >
                                        <MenuItem disabled value="">
                                          {'Select Template'}
                                        </MenuItem>
                                        {
                                          Array.isArray(this.props.template.allTemplate) && this.props.template.allTemplate.filter(element => element.type === 'document').map((element, index) => {
                                            return (
                                              <MenuItem value={element.temp_body}
                                                onClick={() => {
                                                  this.setState({ document: element.temp_body })
                                                }}
                                              >{element.temp_name}</MenuItem>
                                            )
                                          })
                                        }
                                      </Select>
                                    </Grid>}
                                </Grid>
                              </Grid>
                            </RadioGroup>
                          </FormControl>}


                      </Grid>
                      <Grid item xs={1} />
                    </Grid>
                    <Grid container>
                      <Grid item xs={2} />
                      <Grid item xs={8} direction='row'>
                        <DialogActions style={{ display: 'flex', justifyContent: 'center' }} >
                          <Button
                            variant="outlined"
                            className='cancel'
                            onClick={() => {
                              this.setState({
                                addsendmail: false,
                                reciver_id: "",
                                sending_account_id: "",
                                subject: "",
                                category: "",
                                selectedOption: "",
                                template_id: "",
                                template: "",
                                checkAttachment: false,
                              });
                              this.props.setDocument("")
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={this.state.subject === "" || this.state.mailData === ""
                              || this.state.template_id === ""
                            }
                            variant="contained"
                            className={(this.state.subject === "" || this.state.reciver_id === ""
                              || this.state.template_id === "") ? 'disabled' : 'submit'}
                            onClick={() => {
                              // this.props.deleteSpace(this.state.space_id, this.props.login.org_details2._id)
                              if (this.state.selectedOption === "Send template as pdf as well") {
                                this.props.sendCampaignBulk(this.state.subject, this.state.subject,
                                  "bulk", this.state.mailData, this.state.sending_account_id,
                                  this.state.template, this.state.template_id, this.state.category, this.props.login.org_id, this.state.selectedOption, this.state.document)
                              }
                              else if (this.state.selectedOption === "Upload attachment") {
                                this.props.sendCampaignBulk(this.state.subject, this.state.subject,
                                  "bulk", this.state.mailData, this.state.sending_account_id,
                                  this.state.template, this.state.template_id, "", this.props.login.org_id, this.state.selectedOption, this.props.sendEmail.url)
                              }
                              else if (!this.state.checkAttachment) {
                                this.props.sendCampaignBulk(this.state.subject, this.state.subject,
                                  "bulk", this.state.mailData, this.state.sending_account_id,
                                  this.state.template, this.state.template_id, "", this.props.login.org_id, " ")
                              }
                              this.setState({
                                reciver_id: "",
                                subject: "",
                                mailData: [],
                                sending_account_id: "",
                                selectedOption: "",
                                template_id: "",
                                template: "",
                                checkAttachment: false,
                                addsendmail: false,
                              })
                              this.props.setDocument("")
                            }}
                          >
                            Send Mail
                          </Button>
                        </DialogActions>
                      </Grid>
                      <Grid item xs={2} />
                    </Grid>
                  </TabPanel>
                  <Typography></Typography>

                </Tabs>

              </Grid>
              <Grid item xs={.5} />
            </Grid>
          </DialogContent>
          <Grid style={{ display: "flex", justifyContent: 'center' }}>
            {/* {this.state.individualopen && <Grid style={{ backgroundColor: "var(--TB_BrandColor-Grey-10, #E2E0EE)", width: "100%", height: "100%", paddingBottom: "40px", marginTop: "3%", borderRadius: "15px", marginRight: "4%" }}>
              <Typography className="Preview">Preview</Typography>

              <Grid style={{ display: "flex" }}>
                <Typography style={{ marginTop: "3%", marginLeft: "3%", color: "#87817B" }}>Send Mail</Typography>
                <Grid style={{ backgroundColor: "var(--TB_BrandColor-Grey-10, #fff)", width: "50%", height: "10px", paddingBottom: "40px", borderRadius: "3px", marginLeft: "13%", marginTop: "2%" }}>
                  <Typography style={{ textAlign: "center", marginTop: "1%", color: "#87817B" }}>example@gmail.com</Typography>
                </Grid>
              </Grid>

              <Grid style={{ display: "flex" }}>
                <Typography style={{ marginTop: "3%", marginLeft: "3%", color: "#87817B" }}>Subject</Typography>
                <Grid style={{ backgroundColor: "var(--TB_BrandColor-Grey-10, #fff)", width: "50%", height: "10px", paddingBottom: "40px", borderRadius: "3px", marginLeft: "15%", marginTop: "2%" }}>
                  <Typography style={{ textAlign: "center", marginTop: "1%", color: "#87817B" }}>Unluck your nexr career move with ABC company</Typography>
                </Grid>
              </Grid>

              <Grid style={{ display: "flex" }}>
                <Typography style={{ marginTop: "3%", marginLeft: "3%", color: "#87817B" }}>Sending account</Typography>
                <Grid style={{ backgroundColor: "var(--TB_BrandColor-Grey-10, #fff)", width: "50%", height: "10px", paddingBottom: "40px", borderRadius: "3px", marginLeft: "6.5%", marginTop: "2%" }}>
                  <Typography style={{ textAlign: "center", marginTop: "1%", color: "#87817B" }}>abc@gmail.com</Typography>
                </Grid>
              </Grid>
             
              <Grid style={{ display: "flex" }}>
                <Typography style={{ marginTop: "3%", marginLeft: "3%", color: "#87817B" }}>Templates</Typography>
               <Grid style={{ backgroundColor: "var(--TB_BrandColor-Grey-10, #fff)", width: "50%",height:"10px", paddingBottom: "40px",borderRadius: "3px",marginLeft:"6.5%",marginTop:"2%"}}>
                <Typography style={{textAlign:"center",marginTop:"1%"}}>abc@gmail.com</Typography>
               </Grid>
              </Grid> 

              <Grid style={{ display: "flex" }}>
                <Typography style={{ marginTop: "3%", marginLeft: "3%", color: "#87817B" }}>Category</Typography>
                <Grid style={{ backgroundColor: "var(--TB_BrandColor-Grey-10, #fff)", width: "50%", height: "10px", paddingBottom: "40px", borderRadius: "3px", marginLeft: "13.5%", marginTop: "2%" }}>
                  <Typography style={{ textAlign: "center", marginTop: "1%", color: "#87817B" }}>Recruitment</Typography>
                </Grid>
              </Grid>

              <Grid style={{ display: "flex" }}>
                <Typography style={{ marginTop: "3%", marginLeft: "3%", color: "#87817B" }}>Attachment</Typography>
                <Grid style={{ backgroundColor: "var(--TB_BrandColor-Grey-10, #fff)", width: "50%", height: "10px", paddingBottom: "40px", borderRadius: "3px", marginLeft: "11%", marginTop: "2%" }}>
                  <Typography style={{ textAlign: "center", marginTop: "1%", color: "#87817B" }}>No attachment yet</Typography>
                </Grid>
              </Grid>

              <Grid style={{ display: "flex" }}>
                <Typography style={{ marginTop: "3%", marginLeft: "3%", color: "#87817B" }}>Send PDF</Typography>
                <Grid style={{ backgroundColor: "var(--TB_BrandColor-Grey-10, #fff)", width: "50%", height: "10px", paddingBottom: "40px", borderRadius: "3px", marginLeft: "13%", marginTop: "2%" }}>
                  <Typography style={{ textAlign: "center", marginTop: "1%", color: "#87817B" }}>Send template as pdf</Typography>
                </Grid>
              </Grid>


            </Grid>} */}


            {/* {this.state.categoryopen && <Grid style={{ backgroundColor: "var(--TB_BrandColor-Grey-10, #E2E0EE)", width: "100%", height: "100%", paddingBottom: "40px", marginTop: "3%", borderRadius: "15px", marginRight: "4%" }}>
              <Typography className="Preview">category</Typography>
            </Grid>} */}

          </Grid>
        </Dialog>

        {/* --------------------receivers-------------------- */}
        <Dialog
          open={this.state.receivers}
          maxWidth='xs'
          fullWidth
          style={{
            backdropFilter: "blur(10px)",
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
          onClose={() => {
            this.setState({ receivers: false });
          }}
        >
          <DialogContent>
            <Table stickyHeader aria-label="sticky table">
              {Array.isArray(this.state.reciver_id) && this.state.reciver_id.map((item, index) => {
                return (
                  <TableRow hover tabIndex={-1}>
                    <TableCell justifyContent={'center'}>
                      <Typography className='celltext'>{index + 1}</Typography>
                    </TableCell>
                    <TableCell justifyContent={'center'}>
                      <Typography className='celltext'>{item}</Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Table>
          </DialogContent>
        </Dialog>
        <Dialog
          open={this.state.summary}
          maxWidth={this.state.view === 'desktop' ? 'md' : (this.state.view === 'tablet' ? 'md' : 'sm')} // Adjust the sizes as needed
          fullScreen
          sx={{

            backdropFilter: "blur(10px)",
            "& .MuiDialog-paper": {
              borderRadius: "8px",
              // backgroundImage: 'linear-gradient(360deg, hsla(211, 70%, 22%, 1) 0%, hsla(211, 48%, 29%, 1) 22%, hsla(213, 40%, 40%, 1) 59%, hsla(216, 40%, 58%, 1) 92%)',
            },
          }}
        >
          <DialogContent>
            <Grid container >
              <Grid item xs={0.2} />
              <Grid item xs={5.6}>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant='h5' fontWeight={600}>Email Summary</Typography>
                    </Grid>
                    <Grid item xs={12} mt={1}>
                      <Grid container justifyContent={'flex-start'}>
                        <Grid item xs={3.5} >
                          <Typography color={'#383735'} fontWeight={600}>Campaign Name: {this.state.item_object.name}</Typography>
                          <Typography color={'#383735'} fontWeight={600}>Subject: {this.state.item_object.subject}</Typography>
                        </Grid>
                        <Grid item xs={8.5} >
                          <Grid container >
                            <Grid item xs={4}>
                              <Button style={{ backgroundColor: '#FFF4E8', borderColor: 'yellow', borderRadius: 50 }}
                                variant="outlined">
                                <Typography color={'#383735'} fontWeight={600} fontSize={14}>Total Mail: {Array.isArray(this.state.item_object.reciver_id) ? this.state.item_object.reciver_id.length : 0}
                                </Typography>
                              </Button>
                            </Grid>
                            <Grid item xs={4}>
                              <Button style={{ backgroundColor: '#FFF4E8', borderColor: 'yellow', borderRadius: 50 }} variant="outlined">

                                <Typography color={'#383735'} fontWeight={600} fontSize={14}>Total Send: {Array.isArray(this.state.item_object.mail_status) ? this.state.item_object.mail_status.filter((a) => a.status === "sent").length : 0}
                                </Typography>
                              </Button>
                            </Grid>
                            <Grid item xs={4}>
                              <Button style={{ backgroundColor: '#FFF4E8', borderColor: 'yellow', borderRadius: 50 }}
                                variant="outlined" >
                                <Typography color={'#383735'} fontWeight={600} fontSize={14}>Total Failed:{Array.isArray(this.state.item_object.mail_status) ? this.state.item_object.mail_status.filter((a) => a.status !== "sent").length : 0}
                                </Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container mt={2} justifyContent={'flex-start'}>
                    <TableContainer component={Paper} sx={{ maxHeight: 550, overflowY: 'auto', '&::-webkit-scrollbar': { width: '0.1em' }, '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgb(43 63 84)' } }}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Sl No</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell align="right">Status</TableCell>
                            <TableCell align="right">Message Id</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Array.isArray(this.state.item_object.mail_status) && this.state.item_object.mail_status.map((row, index) => (
                            <TableRow
                              key={row.name}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {index + 1}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                {row.email}
                              </TableCell>
                              <TableCell align="center" sx={{textTransform:'uppercase', color:`${row.status==="sent"?"green":"red"}`, }}>{row.status}</TableCell>
                              <TableCell align="right">{row.messageId}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={0.2} />
              <Grid item xs={5} justifyContent={'center'}>
                <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div className="view-selector">
                    <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => { this.setState({ view: 'desktop' }); }}>
                      <DesktopMacOutlinedIcon />
                    </button>
                    <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => this.setState({ view: 'tablet' })}>
                      <TabletMacOutlinedIcon />
                    </button>
                    <button style={{ backgroundColor: 'transparent', border: 'none' }} onClick={() => { this.setState({ view: 'mobile' }); }}>
                      <PhoneAndroidOutlinedIcon />
                    </button>
                  </div>
                  <Icon
                    class="material-symbols-outlined"
                    onClick={() => {
                      this.setState({
                        summary: false
                      });
                    }}
                    style={{

                      color: "black",
                      cursor: "pointer",
                      padding: '8px',
                      fontSize: '22px',
                    }}
                  >
                    close
                  </Icon>
                </Grid>

                <iframe
                  className={`container ${view}`}
                  title="External Page"
                  allowFullScreen
                  srcDoc={isHTML(this.state.item_object.template) ? this.state.item_object.template : null}
                  style={{ border: 'none' }}
                />
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </DialogContent>
        </Dialog >
      </Grid>
    )
  }
}
