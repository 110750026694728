import {
  CATEGORY,
  ACCOUNT,
  TEMPLATE,
  USER,
  EMAIL
} from "./constant";
const initial_state = {
  category: [],
  account:[],
  template:[],
  user:[],
  email:[],

};
export default function reducer(state = initial_state, action) {
  switch (action.type) {

    case CATEGORY:
      console.log("data", action.payload)
      return state = { ...state, category: action.payload };
    case ACCOUNT:
      console.log("data", action.payload)
      return state = { ...state, account: action.payload };
    case TEMPLATE:
      console.log("data", action.payload)
      return state = { ...state, template: action.payload };
    case USER:
      console.log("data", action.payload)
      return state = { ...state, user: action.payload };
    case EMAIL:
      console.log("data", action.payload)
      return state = { ...state, email: action.payload };
    default:
      return state;
  }
}
