const UNIVERSAL = {
  // BASEURL: "http://localhost:7002/",
  BASEURL: "https://newsletterbackend2-a7otoh6gpq-el.a.run.app/",
  // SSO_URL: "https://ssomvcbackend-zpicrijtna-el.a.run.app/",
  // SSO_URL: "https://ssomvcbackend-zpicrijtna-el.a.run.app/",
  // SSO_URL: "http://localhost:8001/",
  SSO_URL: "https://ssoapi.technoboot.co/"
  // SSO_URL: "https://75d1-103-39-242-54.ngrok-free.app/",

};
export default UNIVERSAL;