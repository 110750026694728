import {
  ALL_ACCOUNTS,
  ACCOUNT_LENGTH,
  SEARCH_ACCOUNT
} from "./Constant";

// Utility function to handle GraphQL requests
import { handleGraphQLRequest } from '../../utils/utils';
import { getAccountsQuery, getOrgAccountsQuery } from '../../graphql/query/accountsq';
import { setAccountmutation, resetAccountmutation, delAccountmutation } from '../../graphql/mutation/accountsm';
import { set_snack_bar } from "../../common/snackbar/action";

//---------------------------------------------------ADD Account----------------------------------------------------------//
export function createAccount(org_id, mail_transporter, email, password, cc, host, page, rowPerPage) {
  return async (dispatch) => {
    let requestBody = setAccountmutation(org_id, mail_transporter, email, password, cc, host);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Account Added successfully', true);
    if (response.errors?.length === 0) {
      dispatch(set_snack_bar(response.errors[0].message))
    }
    else {
      dispatch(viewAllAccounts(org_id, page, rowPerPage));
    }
  };
}

// ---------------------------------------------view accounts------------------------------------------------------------

export function viewAllAccounts(org_id, page, rowPerPage) {
  return async (dispatch) => {
    let requestBody = getAccountsQuery(org_id, page, rowPerPage);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Accounts Found', false);
    if (!response.errors && response.data) {
      const accountData = response?.data.accountsPagination.accounts;
      dispatch(setAllAccounts(accountData));
      const accountLength = response?.data?.accountsPagination.accounts.length;
      dispatch(setAccountLength(accountLength));
    }
    else{
      const accountData = [];
      dispatch(setAllAccounts(accountData));
      const accountLength = 0;
      dispatch(setAccountLength(accountLength));
    }
  };
}


export function viewAllOrgAccounts(orgId) {
  return async (dispatch) => {
    let requestBody = getOrgAccountsQuery(orgId);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Category Found', false);
    if (!response.errors && response.data) {
      const accountData = response.data.accounts;
      dispatch(setAllAccounts(accountData));
    }
    else{
      const accountData = [];
      dispatch(setAllAccounts(accountData));
    }
  };
}



export function setAllAccounts(accountData) {
  return {
    type: ALL_ACCOUNTS,
    payload: accountData,
  };
}

export function setAccountLength(accountLength) {
  return {
    type: ACCOUNT_LENGTH,
    payload: accountLength,
  };
}

//   -----------------------------update-----------------------------

export function updateAccount(org_id, account_id, mail_transporter, email, password, cc, host, page, rowPerPage) {
  return async (dispatch) => {
    let requestBody = resetAccountmutation(org_id, account_id, mail_transporter, email, password, cc, host, page, rowPerPage);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Changes made successfully', true);
    if (response.errors?.length === 0) {
      dispatch(set_snack_bar(response.errors[0].message))
    }
    else {
      dispatch(viewAllAccounts(org_id, page, rowPerPage));
    }
  };
}

// --------------------------search-------------------------
export function searchAccounts(all_account, e) {
  return (dispatch) => {
    if (e === "") {
      dispatch({ type: SEARCH_ACCOUNT, payload: all_account });
    }
    else {
      const newArray = all_account.filter((el) => {
        return (el.email.toLowerCase().includes(e.toLowerCase()));
      })
      dispatch({ type: SEARCH_ACCOUNT, payload: newArray })
    }
  };
}

// --------------------------delete----------------------------
export function deleteAccount(org_id, accountId, page, rowPerPage) {
  return async (dispatch) => {
    let requestBody = delAccountmutation(accountId);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Account deleted successfully', true);
    if (response.errors?.length === 0) {
      dispatch(set_snack_bar(response.errors[0].message))
    }
    else {
      dispatch(viewAllAccounts(org_id, page, rowPerPage));
    }
  };
}