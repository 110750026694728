import {
  ALL_SUBSCRIBER,
  SUBSCRIBER_LENGTH,
  SEARCH_SUBSCRIBER
} from "./Constant";

// Utility function to handle GraphQL requests
import { handleGraphQLRequest } from '../../utils/utils';
import { getSubscriberQuery, getAllSubscriberQuery } from '../../graphql/query/subscriberq';
import { setSubscribermutation, setExcelSubscribermutation, delSubscribermutation, resetSubscribermutation } from '../../graphql/mutation/subscriberm';
import { set_snack_bar } from "../../common/snackbar/action";



// Action creators
export function setAllSubscriber(subscriberData) {
  return {
    type: ALL_SUBSCRIBER,
    payload: subscriberData,
  };
}

export function setSubscriberLength(subscriberLength) {
  return {
    type: SUBSCRIBER_LENGTH,
    payload: subscriberLength,
  };
}

//---------------------------------------------------VIEWALL----------------------------------------------------------//
export function viewAllSubscriber(orgId, page, rowPerPage, search) {
  return async (dispatch) => {
    let requestBody = getSubscriberQuery(orgId, page, rowPerPage, search);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Subscriber Found', false);
    console.log(response)
    if (!response.errors && response.data) {
      const subscriberData = response.data.subscribersPagination.subscribers;
      dispatch(setAllSubscriber(subscriberData));
      const subscriberLength = response.data?.subscribersPagination?.totalSubscribers;
      dispatch(setSubscriberLength(subscriberLength));
    }
    else {
      const subscriberData = []
      dispatch(setAllSubscriber(subscriberData));
      const subscriberLength = 0
      dispatch(setSubscriberLength(subscriberLength));
    }
  };
}
export function viewAllOrgSubscriber(orgId) {
  return async (dispatch) => {
    let requestBody = getAllSubscriberQuery(orgId);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Subscriber Found', false);
    if (!response.errors && response.data) {
      const subscriberData = response.data.subscribers;
      dispatch(setAllSubscriber(subscriberData));
    }
    else {
      const subscriberData = []
      dispatch(setAllSubscriber(subscriberData));
    }
  };
}


//---------------------------------------------------ADD----------------------------------------------------------//
export function createSubscriber(orgId, name, email, phone, category,) {
  return async (dispatch) => {
    let requestBody = setSubscribermutation(orgId, name, email, phone, category);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Subscriber created successfully', true);
    if (response.errors?.length === 0) {
      dispatch(set_snack_bar(response.errors[0].message))
    }
    else {
      dispatch(viewAllSubscriber(orgId, 1, 10, ""));
    }
  };
}

export function createExcelSubscriber(orgId, category, data) {
  return async (dispatch) => {
    let requestBody = setExcelSubscribermutation(orgId, category, data);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Subscriber created successfully', true);
    console.log(response)
    if (response.errors?.length === 0) {
      dispatch(set_snack_bar(response.errors[0].message))
    }
    else {
      dispatch(viewAllSubscriber(orgId, 1, 10, ""));
    }
  };
}

//---------------------------------------------------EDIT----------------------------------------------------------//
export function editSubscriber(orgId, subscriberId, name, email, phone, category, page, rowPerPage) {
  return async (dispatch) => {
    let requestBody = resetSubscribermutation(subscriberId, name, email, phone, category);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Changes made successfully', true);
    if (response.errors?.length === 0) {
      dispatch(set_snack_bar(response.errors[0].message))
    }
    else {
      dispatch(viewAllSubscriber(orgId, page, rowPerPage, ''));
    }
  };
}

//---------------------------------------------------DELETE----------------------------------------------------------//
export function deleteSubscriber(orgId, subscriberId, page, rowPerPage) {
  return async (dispatch) => {
    let requestBody = delSubscribermutation(subscriberId);
    const response = await handleGraphQLRequest(dispatch, requestBody, 'Subscriber deleted successfully', true);
    if (response.errors?.length === 0) {
      dispatch(set_snack_bar(response.errors[0].message))
    }
    else {
      dispatch(viewAllSubscriber(orgId, page, rowPerPage, ""));
    }
  };
}

//---------------------------------------------------SEARCH----------------------------------------------------------//
export function searchSubscriber(allSubscriber, e) {
  return (dispatch) => {
    if (e === "") {
      dispatch({ type: SEARCH_SUBSCRIBER, payload: allSubscriber });
    }
    else {
      const newArray = allSubscriber.filter((el) => {
        return (el.name.toLowerCase().includes(e.toLowerCase()));
      })
      dispatch({ type: SEARCH_SUBSCRIBER, payload: newArray })
    }
  };
}
