
// -------------------------------------add--------------------------------------
export const setAccountmutation = (org_id, mail_transporter,email,password,cc ,host) => {
    return {
        query: `
            mutation createAccount($accountInput: AccountInput!) {
                createAccount(accountInput: $accountInput) {
                    _id
                   email,
                 
                }
            }
        `,
        variables: {
            accountInput: {
                org_id: org_id,
                mail_transporter: mail_transporter,
                email: email,
                password:password,
                cc:cc,
                host:host
            },
        },
    };
};

// --------------------------------update----------------------------------
export const resetAccountmutation = (org_id,account_id,mail_transporter,email,password,cc,host) => {
    return {
        query: `
        mutation updateAccount {
            updateAccount(account: {
             accountId:"${account_id}",
             updatedAccount:{
                mail_transporter:"${mail_transporter}",
                email:"${email}",
                password:"${password}",
                cc:"${cc}",
                host:"${host}",           
              }
            }) {
              _id
              email  
            }
          }
        `,
        variables: {
            updatedAccount: {
                org_id: org_id,
                _id: account_id,
                mail_transporter: mail_transporter,
                email: email,
                password:password,
                cc:cc,
                host:host 
            },
        },
    };
};

// ----------------------------delete-----------------------------

export const delAccountmutation = (accountId) => {
    return {
        query: `
        mutation {
            deleteAccount(accountId: "${accountId}") {
              _id
              email
            }
          }
        `,
        variables: {
            accountId: accountId,
        },
    };
};