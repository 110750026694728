import React, { Component } from 'react'
import {
  Grid,
  Typography,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  TextField,
  DialogActions,
  Button,
  CircularProgress
} from "@mui/material";
import SearchIcon from '../../image/search.png'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import Icon from "@mui/material/Icon";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import '../../../common/styles.scss';
import Snackbar from "../../../common/snackbar/components/snackbar"
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import '../../../common/introCss.scss';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';
import "../../AccountSetting/Components/Accs.scss";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import googleIcon from "../../image/google.png"
import yandexIcon from "../../image/yandex.png"
import awsIcon from "../../image/aws.png"
import zohoIcon from "../../image/zoho.png"

export default class AccountS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acckey: false,
      loading: true,
      searching: false,
      add: false,
      edit: false,
      view: false,
      accdelete: false,
      category_id: '',
      category_name: '',
      category_dept: 'N',
      category_desc: '',
      category_dept_name: '',
      page: 0,
      rowsPerPage: 10,
      mail_transporter: '',
      email: '',
      password: '',
      cc: '',
      host: '',
      showPassword: false,
      isValidEmail: true,
      isValidEmailcc: true,
      isValidEditEmail: true,
      isValidEditEmailcc: true,
      accdata: [
        {
          deptname: "Google",
          password: "fghjk",
          Email: "subhs@tech.co",
          cc: "hr.tech.co"
        },
        {
          deptname: "Zoho ",
          password: "frtyujk",
          Email: "sgfhhs@tech.co",
          cc: "yur.tech.co"
        },
        {
          deptname: "fb",
          password: "frtyujk",
          Email: "sgfhhs@tech.co",
          cc: "yur.tech.co"
        },
      ],
      dropdownOptions: [
        { value: "google", icon: googleIcon, iconHeight: 20, iconWidth: 20, label: "Google" },
        { value: "zoho", icon: zohoIcon, iconHeight: 20, iconWidth: 20, label: "Zoho" },
        { value: "yandex", icon: yandexIcon, iconHeight: 20, iconWidth: 20, label: "Yandex" },
        { value: "aws", icon: awsIcon, iconHeight: 25, iconWidth: 25, label: "Aws" },
      ]
    };
  }

  startIntro = () => {
    const intro = introJs();
    intro.setOptions({
      steps: [
        {
          intro: 'Welcome to  Account!',
        },
        {
          element: '#tableCat', // Replace with the actual element ID or class
          intro: 'this is a list of  Account',
        },
        {
          element: '#addCat',
          intro: 'You can add your own  Account',
        },
        {
          element: '#editCat',
          intro: 'You can also make changes to particular  Account according to your needs',
        },
        {
          element: '#deleteCat',
          intro: 'You can delete unnecessary  Account',
        },
        {
          element: '#searchCat',
          intro: 'Search your  Account by name',
        },
      ],
    });
    intro.start();
    localStorage.setItem("acc_intro", true)
  };

  componentDidMount() {
    this.props.viewAllAccounts(this.props.login.org_id, this.state.page, this.state.rowsPerPage);
    if (localStorage.getItem("dept_intro")) {
      this.startIntro();
    }
  }

  handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    this.setState({ rowsPerPage: newRowsPerPage, page: 0 });
    this.props.viewAllAccounts(this.props.login.org_id, 0, newRowsPerPage);
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    // Fetch data with the new page value
    this.props.viewAllAccounts(this.props.login.org_id, newPage, this.state.rowsPerPage);
  };

  // password validation
  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  toggleShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  // email validation
  handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.setState({
      email: enteredEmail,
      isValidEmail: emailRegex.test(enteredEmail),
    });
  };


  handleccChange = (e) => {
    const enteredEmail = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.setState({
      cc: enteredEmail,
      isValidEmailcc: emailRegex.test(enteredEmail),
    });
  };


  handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    this.setState({ mail_transporter: selectedValue, host: '' });
    // If "Aws" is selected, set the default host value or perform any other logic
    if (selectedValue === 'Aws') {
      this.setState({ host: 'defaultAwsHost' });
    }
  };

  handleEditEmailChange = (e) => {
    const enteredEmail = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.setState({
      email: enteredEmail,
      isValidEmail: emailRegex.test(enteredEmail),
    });
  };


  handleEditcc = (e) => {
    const enteredEmail = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    this.setState({
      email: enteredEmail,
      isValidEmailcc: emailRegex.test(enteredEmail),
    });
  };

  render() {
    const { snackbar, close_snack_bar } = this.props
    const { password, showPassword } = this.state;
    const { isValidEmail, isValidEmailcc, isValidEditEmail} = this.state;
    return (
      <Grid>
        <Grid container justifyContent={'left'}>
          <Typography className='topic'> Account Setting</Typography>
        </Grid>
        <LoaderCon />
        <Grid container className='page'>
          <Grid item xs={12} className='box'>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={9}>
                <FormControl fullWidth variant="standard" id='searchCat'>
                  <OutlinedInput
                    type={'text'}
                    className='textfield'
                    sx={{
                      '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                        boxShadow: '0px 2px 0px lightgray',
                      },
                      '.MuiOutlinedInput-notchedOutline': { border: '1px solid #E2E0DD' },
                      height: '48px',
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <LoadingButton
                            loading={this.state.searching}
                            loadingPosition="center"
                            variant="text"
                            onClick={() => {
                              this.setState({
                                add: true,
                              });
                            }}
                          >
                            {/* {!this.state.searching && */}
                            <img src={SearchIcon} alt="Search Category" style={{ font: '24px' }} />
                            {/* } */}
                          </LoadingButton>
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Search"
                    onChange={(event) => {
                      this.props.searchAccounts(this.props.account.all_account, event.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={3}>
                <LoadingButton
                  id="addCat"
                  fullWidth
                  style={{ height: '48px', width: "240px", marginLeft: "30px" }}
                  className='add'
                  startIcon={this.props.loader.progress ? '' : <AddRoundedIcon />}
                  loading={this.props.loader.progress}
                  loadingPosition='center'
                  variant='contained'
                  onClick={() => {
                    this.setState({
                      addacc: true,
                    });
                  }}
                >
                  {this.props.loader.progress ? (
                    <CircularProgress
                      size={24} // Adjust the size of the circular loader as needed
                      style={{ color: 'white' }}
                    />
                  ) :
                    <Typography>Account Setting</Typography>
                  }
                </LoadingButton>
              </Grid>
            </Grid>
            <Grid>
              <TableContainer id="tableCat" >
                <Table stickyHeader aria-label="sticky table" style={{ marginTop: '20px' }}>
                  <TableHead className="customTableHead">
                    <TableRow >
                      <TableCell justifyContent={'center'}>
                        <Typography className='headtext'>S No</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className='headtext'> Mail Transporter</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className='headtext'> Password</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className='headtext'> Email</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className='headtext'> CC</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className='headtext' textAlign={'center'}>Action</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(this.props.account.search_account) && this.props.account.search_account.map((item, index) => {
                      return (
                        <TableRow hover tabIndex={-1}>
                          <TableCell justifyContent={'center'}>
                            <Typography className='celltext'>{index + 1}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className='celltext'> {item.mail_transporter}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className='celltext'> {item.password}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className='celltext'> {item.email}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography className='celltext'> {item.cc}</Typography>
                          </TableCell>
                          <TableCell>
                            <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                              <Icon
                                id='editCat'
                                class="material-symbols-outlined"
                                onClick={() => {
                                  this.setState({
                                    editacc: true,
                                    account_id: item._id,
                                    mail_transporter: item.mail_transporter,
                                    email: item.email,
                                    password: item.password,
                                    cc: item.cc
                                  });
                                }}
                                style={{
                                  color: "#FFA741",
                                  cursor: "pointer",
                                  padding: '8px',
                                  backgroundColor: 'rgba(255, 231, 203, 0.7)',
                                  borderRadius: 50,
                                  fontSize: '22px',
                                  marginLeft: "10%",
                                }}
                              >
                                edit_square
                              </Icon>
                              <Icon
                                id='deleteCat'
                                class="material-symbols-outlined"
                                onClick={() => {
                                  this.setState({
                                    accdelete: true,
                                    accountId: item._id,
                                  });
                                }}
                                style={{
                                  color: "#C43232",
                                  cursor: "pointer",
                                  padding: '8px',
                                  backgroundColor: 'rgba(255, 234, 227, 0.7)',
                                  borderRadius: 50,
                                  fontSize: '22px',
                                  marginLeft: "10%",
                                }}
                              >
                                delete
                              </Icon>
                              {/* <Icon
                                id='deleteCat'
                                class="material-symbols-outlined"
                                onClick={() => {
                                  this.setState({
                                    acckey: true,
                                  });
                                }}
                                style={{
                                  color: "#C43232",
                                  cursor: "pointer",
                                  padding: '8px',
                                  backgroundColor: 'rgba(255, 234, 227, 0.7)',
                                  borderRadius: 50,
                                  fontSize: '22px',
                                  marginLeft: "10%",
                                }}
                              >
                                Key
                              </Icon> */}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 50]}
                  component="div"
                  count={parseInt(this.props.account.account_length)}
                  rowsPerPage={this.state.rowsPerPage}
                  page={this.state.page}
                  onPageChange={this.handleChangePage}
                  onRowsPerPageChange={this.handleChangeRowsPerPage}
                />
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}
        {/*--------------------Add-------------------------*/}
        <Dialog
          sx={{
            backdropFilter: "blur(10px)",
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
          maxWidth="xl"
          open={this.state.addacc}
          onClose={() => {
            this.setState({ addacc: false });
          }} >
          <Grid style={{ width: "600px", marginBottom: "6%", padding: "10px", }}>
            <DialogTitle style={{ fontSize: "20px", color: "#161412", marginTop: "20px", marginLeft: "3%", fontWeight: 600 }}>Add Account Setting </DialogTitle>
            <Typography style={{ marginTop: "10px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "8%" }}>Mail Transporter</Typography>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 480, marginLeft: "8%" }}>
              <Select
                onChange={(e) => {
                  this.setState({ mail_transporter: e.target.value });
                }}
              >
                {this.state.dropdownOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Grid container alignItems="center">
                      <img src={option.icon} style={{ height: option.iconHeight, width: option.iconWidth, marginRight: "5%" }} />
                      {option.label}
                    </Grid>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "8%" }}> Email</Typography>
            <TextField id="standard-basic" variant="standard" style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
              type="Email"
              placeholder=' Enter Email Id'
              onChange={(e) => {
                this.setState({ email: e.target.value });
                this.handleEmailChange(e);
              }}
            />
            {!isValidEmail && <div style={{ color: 'red', fontSize: "10px", marginLeft: "8%" }}>Invalid email format</div>}
            <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "8%" }}> Password</Typography>
            <TextField
              id="standard-basic"
              variant="standard"
              style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => {
                this.setState({ password: e.target.value });
                // this.handlePasswordChange()
              }}
            />
            {showPassword ? (
              <VisibilityOffOutlinedIcon
                style={{ color: "black", cursor: "pointer", marginLeft: "2%" }}
                onClick={this.toggleShowPassword}
              />
            ) : (
              <VisibilityOutlinedIcon
                style={{ color: "black", cursor: "pointer", marginLeft: "2%" }}
                onClick={this.toggleShowPassword}
              />
            )}
            <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "8%" }}>CC</Typography>
            <TextField id="standard-basic" variant="standard" style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
              placeholder=' Enter your CC'
              onChange={(e) => {
                this.setState({ cc: e.target.value });
                this.handleccChange(e);
              }}
            />
            {!isValidEmailcc && <div style={{ color: 'red', fontSize: "10px", marginLeft: "8%" }}>Invalid email format</div>}
            {this.state.mail_transporter === 'Aws' && <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "8%" }}> Host</Typography>}
            {this.state.mail_transporter === 'Aws' && (
              <TextField id="standard-basic" variant="standard" style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
                onChange={(e) => {
                  this.setState({ host: e.target.value });
                }}
              />
            )}
            <Grid style={{ marginTop: "20px", marginLeft: "8%", marginRight: "3%", display: "flex", flexDirection: "row", }}>
              <Button
                variant="outlined"
                className='cancel'
                onClick={() => { this.setState({ addacc: false }) }}
              >
                Cancel
              </Button>
              <Button variant="contained"
                className={((this.state.mail_transporter === '') || (this.state.email === '') || (this.state.password === '') || (this.state.cc === '')) && (this.state.host === '') ? 'disabled' : 'submit'}
                style={{ backgroundColor: "rgb(43, 63, 84)", fontSize: "15px", height: "48px", width: "249px", borderRadius: "4px", marginLeft: "10px" }}
                disabled={((this.state.mail_transporter === '') || (this.state.email === '') || (this.state.password === ''))}
                onClick={() => {
                  this.setState({
                    addacc: false,
                    mail_transporter: '',
                    email: '',
                    password: '',
                    cc: '',
                    host: ''
                  }); 
                  this.props.createAccount(this.props.login.org_id, this.state.mail_transporter, this.state.email, this.state.password, this.state.cc, this.state.host, this.state.page, this.state.rowsPerPage);
                }}>
                Add
              </Button>
            </Grid>
          </Grid>
        </Dialog>
        {/*-------------------------------key Accc------------------------------ */}
        <Dialog
          sx={{
            backdropFilter: "blur(10px)",
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
          maxWidth="xl"
          open={this.state.acckey}
          onClose={() => {
            this.setState({ acckey: false });
          }} >
          <Grid style={{ width: "695px", marginBottom: "6%", height: "800px" }}>
            <Grid className="Gentlegrid">
              <Typography className="Gentle">
                Gentle remainder
              </Typography>
            </Grid>
            <Grid>
              <Typography className="Dear">
                Dear<strong> Nevaeh Smmons,</strong><br />
                We're excited to share an important update with you regarding the security of your<br />
                account. Following the successful completion of your verification process, we're<br />
                introducing a powerful feature called App Password.
              </Typography>
              <Typography className="What">
                What is App Password?
              </Typography>
              <Typography className="Dear">
                App Password serves as an additional protective layer for your account, ensuring<br />
                that your information remains secure and accessible only to you. It's a key element<br />
                in our commitment to providing you with a robust and trustworthy online<br />
                experience.
              </Typography>
              <Typography className="Dear">
                Your Guide to Setting Up App Password:
              </Typography>
              <Typography className="What">
                Step 1: Verification Completed
              </Typography>
              <Typography className="Dear">
                After successfully completing the verification process, you're now eligible to<br />
                leverage the enhanced security of Auto-Generated App Password.
              </Typography>
              <Typography className="What">
                Step 2: Look for the Prompt
              </Typography>
              <Typography className="Dear">
                Keep an eye out for a friendly prompt or notification guiding you through the<br />
                process. This will appear immediately after your verification, ensuring a seamless<br />
                experience.
              </Typography>
              <Typography className="What">
                Step 3: Accessing Your Auto-Generated App Password
              </Typography>
              <Typography className="Dear">
                Your unique App Password will be automatically generated for you. No need to<br />
                create one yourself! This personalized code will be your go-to for specific actions<br />
                within your account, adding an extra layer of security.
              </Typography>
            </Grid>

            <Grid style={{ marginTop: "20px", marginLeft: "170px", marginRight: "3%", display: "flex", flexDirection: "row", }}>
              <Link to="/AccountS">
                <Button
                  onClick={() => { this.setState({ acckey: false }) }}
                  style={{ fontSize: "15px", height: "48px", width: "149px", borderRadius: "4px", color: "#161412", borderColor: "rgb(43, 63, 84)" }}
                  variant="outlined" startIcon={<ArrowBackIcon />}>
                  Back
                </Button>
              </Link>
              <Button variant="contained"
                style={{ backgroundColor: "rgb(43, 63, 84)", fontSize: "15px", height: "48px", width: "209px", borderRadius: "4px", marginLeft: "10px" }}
                onClick={() => {
                  // this.props.deleteSpace(this.state.space_id, this.props.login.org_details2._id)
                  this.setState({ acckey: false });
                }}
              >
                Proceed to next step
              </Button>
            </Grid>
          </Grid>
        </Dialog>

        {/* ----------------------------delete---------------------------------- */}
        <Dialog
          open={this.state.accdelete}
          maxWidth="sm"
          fullWidth
          sx={{
            backdropFilter: "blur(10px)",
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
        >
          <DialogTitle className='titlespace'>
            <Grid container marginTop={1}>
              <Grid item xs={.5} />
              <Grid item>
                <Typography className='title'>Delete Account</Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container >
              <Grid item xs={.5} />
              <Grid item xs={11} direction='column'>
                <Typography >
                  Are you sure do you want to delete this Account?
                </Typography>
              </Grid>
              <Grid item xs={.5} />
            </Grid>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'center', marginBottom: '5%', }} >
            <Button
              variant="outlined"
              className='cancel'
              onClick={() => {
                this.setState({ accdelete: false });
              }}
            >
              No, Cancel it.
            </Button>
            <Button
              variant="contained"
              className='submit'
              onClick={() => {
                this.setState({
                  accdelete: false
                });
                this.props.deleteAccount(this.props.login.org_id, this.state.accountId, this.state.page, this.state.rowsPerPage);
              }}
            >
              Yes, delete it.
            </Button>
          </DialogActions>
        </Dialog >

        {/* ----------------------------edit---------------------------------- */}
        <Dialog
          sx={{
            backdropFilter: "blur(10px)",
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
          maxWidth="xl"
          open={this.state.editacc}
          onClose={() => {
            this.setState({ editacc: false });
          }} >
          <Grid style={{ width: "600px", marginBottom: "6%", padding: "10px", }}>
            <DialogTitle style={{ fontSize: "20px", color: "#161412", marginTop: "20px", marginLeft: "3%", fontWeight: 600 }}>Edit Account Setting </DialogTitle>
            <Typography style={{ marginTop: "10px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "8%" }}>Mail Transporter</Typography>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 480, marginLeft: "8%" }}>
              <Select
                value={this.state.mail_transporter}
                onChange={(event) => {
                  this.setState({ mail_transporter: event.target.value });
                }}
              >
                {this.state.dropdownOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Grid container alignItems="center">
                      <img src={option.icon} style={{ height: option.iconHeight, width: option.iconWidth, marginRight: "5%" }} />
                      {option.label}
                    </Grid>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "8%" }}> Email</Typography>

            <TextField id="standard-basic" variant="standard" style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
              type="Email"
              placeholder=' Enter Email Id'
              value={this.state.email}
              onChange={(e) => {
                this.setState({ email: e.target.value });
                this.handleEditEmailChange(e);
              }}
            />
            {!isValidEditEmail && <div style={{ color: 'red', fontSize: "10px", marginLeft: "8%" }}>Invalid email format</div>}

            <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "8%" }}> Password</Typography>
            <TextField
              id="standard-basic"
              variant="standard"
              style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => {
                this.setState({ password: e.target.value });
                // this.handlePasswordChange()
              }}
            />
            {showPassword ? (
              <VisibilityOffOutlinedIcon
                style={{ color: "#FF795C", cursor: "pointer", marginLeft: "2%" }}
                onClick={this.toggleShowPassword}
              />
            ) : (
              <VisibilityOutlinedIcon
                style={{ color: "#FF795C", cursor: "pointer", marginLeft: "2%" }}
                onClick={this.toggleShowPassword}
              />
            )}
            <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "8%" }}>CC</Typography>
            <TextField id="standard-basic" variant="standard" style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
              placeholder=' Enter your CC'
              value={this.state.cc}
              onChange={(event) => {
                this.setState({ cc: event.target.value });
              }}
            />

            {this.state.mail_transporter === 'Aws' && <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#161412", fontWeight: 500, marginLeft: "8%" }}>Host</Typography>}
            {this.state.mail_transporter === 'Aws' && (
              <TextField id="standard-basic" variant="standard" style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
                value={this.state.host}
                onChange={(e) => {
                  this.setState({ host: e.target.value });
                }}
              />
            )}

            <Grid style={{ marginTop: "20px", marginLeft: "8%", marginRight: "3%", display: "flex", flexDirection: "row", }}>
              <Button variant="outlined"
                style={{ fontSize: "15px", height: "48px", width: "249px", borderRadius: "4px", color: "#161412", borderColor: "rgb(43, 63, 84)" }}
                onClick={() => { this.setState({ editacc: false }) }}
              >
                Cancel
              </Button>
              <Button variant="contained"
                style={{ backgroundColor: "rgb(43, 63, 84)", fontSize: "15px", height: "48px", width: "249px", borderRadius: "4px", marginLeft: "10px" }}
                onClick={() => {
                  this.props.updateAccount(this.props.login.org_id, this.state.account_id, this.state.mail_transporter, this.state.email, this.state.password, this.state.cc, this.state.host, this.state.page, this.state.rowsPerPage);

                  this.setState({ editacc: false });
                }}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Dialog>
      </Grid>
    )
  }
}
