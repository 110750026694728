import React, { Component } from "react";
import { connect } from "react-redux";
import Department from "../Components/Department";
import { close_snack_bar } from "../../../common/snackbar/action";
import {
    createDepartment,
    editDepartment,
    deleteDepartment,
    viewAllDepartment,
    searchDepartment
} from "../Action";
export class DepartmentContainer extends Component {
    render() {
        return (
            <Department {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        department:store.department,
    };
};
export const mapDispatchToProps = dispatch => {
    return {

        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        createDepartment: (orgId, department_name, page, rowPerPage) => {
            dispatch(createDepartment(orgId, department_name, page, rowPerPage));
        },
        editDepartment: (orgId, department_id, department_name, page, rowPerPage) => {
            dispatch(editDepartment(orgId, department_id, department_name, page, rowPerPage));
        },
        deleteDepartment: (orgId, departmentId, page, rowPerPage) => {
            dispatch(deleteDepartment(orgId, departmentId, page, rowPerPage));
        },
        viewAllDepartment: (orgId, page, rowPerPage) => {
            dispatch(viewAllDepartment(orgId, page, rowPerPage));
        },
        searchDepartment: (alldepartment, e) => {
            dispatch(searchDepartment(alldepartment, e));
        },

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(DepartmentContainer);