import { Height, Padding } from '@mui/icons-material';
import { Avatar, Box, Button, Card, Container, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Modal, TextField, Typography } from '@mui/material';
import React, { Component } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
// import DeleteIcon from '@mui/icons-material/Delete';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import logo from '../../image/img3.jpg'
import { Link } from 'react-router-dom';
import Loader from '../../../common/loader/components/loader';

// import { color } from 'html2canvas/dist/types/css/types/color';
export default class Organization extends Component {

    constructor(props) {
        super(props);
        console.log(this.props);
        this.state = {
            openmodal: false,
            img: "",
            openmodalll: false,
            item: 0,
            // details:{
            orgName: "",
            gstinNo: "",
            Email: "",
            Phone: "",
            // },
            list: [
                {
                    orgName: "Organization1",
                    gstinNo: "hh",
                    Email: "Organization@gmail.com",
                },
                {
                    orgName: "Organization2",
                    gstinNo: "hh",
                    Email: "Organization2@gmail.com",
                },
                {
                    orgName: "Organization3",
                    gstinNo: "hh",
                    Email: "Organization3@gmail.com",
                },
            ]
        }
    }
    render() {
        const { Email, gstinNo, orgName, openmodalll, item, img, Phone } = this.state
        return (
            <Container style={{
                // backgroundColor:"aliceblue",
                // boxShadow: "0px 0px 4px;",
                // width:"50%",

                flexDirection: "row",
                display: "flex",
                marginTop: "6em",
                justifyContent: "space-around",
            }} maxWidth="lg">
                <div style={{
                    // width: "100vh",
                    // height: "95vh",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    display: "flex",
                    // gap: "2em"

                }} >
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // marginBottom: "2em",

                        // gap:"3em"
                    }} >



                    </Box>
                    <Box style={{
                        // height: "100vh",
                        // alignSelf:"center",
                        width: "100%", alignItems: "flex-start",
                        flexDirection: "column",
                        display: "flex",
                        // justifyContent: "space-between",
                        paddingTop: "1em",
                        
                    }}>
                        <Typography sx={{

                        }}  >
                            Organization
                        </Typography>
                        {/* <Box > */}

                        {/* <Divider /> */}
                        <nav style={{
                            color: "black",
                            overflowY: "auto",
                            maxHeight: "23em"
                        }} aria-label="secondary mailbox folders">
                            <List style={{
                                width: "37em"
                            }} >
                                {
                                    this.state.list.map((i, index) => (


                                        <Link to="/Addtemplate" style={{
                                            textDecoration: "none"
                                        }}>   <ListItem



                                            key={index}
                                            onClick={() => {
                                                this.setState({ openmodalll: !openmodalll })
                                                this.setState({ item: index })

                                            }}
                                            sx={{
                                                cursor: "pointer",

                                                // width: "156vh",
                                                // color:'white',
                                                borderBottom: "1px solid gray",
                                                // borderTop:"2px solid"
                                                //     gap: 5
                                                // justifyContent: "space-around"
                                                // justifyContent:"space-around"
                                            }}>
                                                {/* <Avatar size="sm">{index}</Avatar> */}



                                                <ListItemAvatar >
                                                    <Avatar sx={{
                                                        backgroundColor: "transparent",
                                                        color: "black"
                                                    }}>
                                                        {index + Number(1)}
                                                        {/* <ImageIcon /> */}
                                                    </Avatar>
                                                </ListItemAvatar>
                                                <ListItemText primary={i?.orgName?.length == 0 ? "N/A" : i?.orgName} secondary={i.Email.length == 0 ? "N/A" : i.Email} />



                                                {/* <Typography  fontWeight={600}  color="white" variant="subtitle1" component="h2">
                                        Name: {i?.orgName?.length == 0 ? "N/A" : i?.orgName}
                                        </Typography>
                                        <Typography level="body-xs" color="white" variant="subtitle1" component="h2">
                                        Email:{i.Email.length == 0 ? "N/A" : i.Email}
                                        </Typography> */}


                                            </ListItem>
                                        </Link>

                                    ))
                                }
                            </List>
                        </nav>

                       

                        <Button sx={{
                            marginBottom: "3em"
                        }} onClick={() => {
                            this.setState({ openmodal: true })
                        }

                        } style={{
                            backgroundColor: "darkslategrey",

                            margin: "1em"

                        }} variant="contained">+ Create Organization</Button>

                        {/* </Box> */}


                    </Box>











                    {/* <Modal
                    open={this.state.openmodalll}

                >

                    <Box
                        sx={
                            {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                display: "flex",
                                // Width:"100%",
                                gap: 3,
                                flexDirection: "column",

                                bgcolor: 'background.paper',
                                border: '2px solid #000',
                                boxShadow: 24,
                                color: "black",
                                p: 4,
                            }
                        }
                    >

                        <Typography color="black" sx={{
                            display: "flex",
                            gap: "1em",
                            alignItems: "center"
                        }} variant="body1" component="h2">
                            <Avatar>{item}</Avatar>
                            {
                                this?.state?.list?.[item]?.orgName == undefined ? null : this?.state?.list?.[item]?.orgName
                            }

                        </Typography>


                        <Box>

                            <Button sx={{ mt: 2 }} onClick={() => {

                                // this.setState({openmodal:false})
                                // this.state.list.push({Email,gstinNo,orgName});
                                this.setState({
                                    openmodalll: false,

                                })
                                console.log(this.state.list);

                            }
                            } variant="contained">close</Button>
                            <Button sx={{ mt: 2 }} onClick={() => {

                                // this.setState({openmodal:false})
                                // this.state.list.push({Email,gstinNo,orgName});
                                this.setState({
                                    openmodalll: false,

                                })

                                item == this?.state?.list?.length ? this?.state?.list?.splice(-1) : this?.state?.list?.splice(item, 1)


                                console.log(this.state.list);

                            }
                            } variant="text">Delete</Button>
                        </Box>

                    </Box>




                </Modal> */}



                    <Modal
                        open={this.state.openmodal}
                        //   onClose={()=>this.setState({open:false})}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={
                            {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                display: "flex",
                                // Width:"100%",
                                gap: 3,
                                flexDirection: "column",
                                // alignItems:"flex-start",
                                bgcolor: 'background.paper',
                                border: '2px solid #000',
                                boxShadow: 24,
                                color: "black",
                                p: 4,
                            }
                        }>
                            {/* {
                            img == "" ? <input type="file" onChange={(e) => {
                                this.setState({ img: e.target.value })
                                console.log(img)
                            }} id="avatar" name="avatar" accept="image/png, image/jpeg" /> : <Avatar alt="Remy Sharp" src={img} />
                        } */}

                            {
                                <Avatar src={img !== "" ? URL.createObjectURL(img) : img} style={{ marginLeft: "auto", marginRight: "auto" }}>
                                    <input style={{ display: "none" }} type="file" id="file" onChange={(e) => { this.setState({ img: e.target.files[0] }) }} />
                                    <label htmlFor="file"><CameraAltIcon style={{ marginTop: "25%" }} /></label>
                                </Avatar>
                            }



                            <TextField onChange={(e) => this.setState({ orgName: e.target.value })} value={orgName} id="standard-basic" label="Org Name" variant="standard" />
                            <TextField onChange={(e) => this.setState({ gstinNo: e.target.value })} value={gstinNo} id="standard-basic" label="Gstin No." variant="standard" />
                            <TextField onChange={(e) => this.setState({ Email: e.target.value })} value={Email} id="standard-basic" label="Email" variant="standard" />
                            <TextField onChange={(e) => this.setState({ Phone: e.target.value })} value={Phone} id="standard-basic" label="Phone" variant="standard" />


                            <Box sx={{
                                flexDirection: "row",
                                display: "flex"
                            }}>
                                <Button sx={{ mt: 2 }} onClick={() => {

                                    // this.setState({openmodal:false})
                                    this.state.list.push({ Email, gstinNo, orgName });
                                    this.setState({
                                        openmodal: false,
                                        Email: "",
                                        gstinNo: "",
                                        orgName: "",
                                    })
                                    console.log(this.state.list);

                                }
                                } variant="contained">Create</Button>
                                <Button sx={{ mt: 2 }} onClick={() => {

                                    // this.setState({openmodal:false})
                                    // this.state.list.push({Email,gstinNo,orgName});
                                    this.setState({
                                        openmodal: false,
                                        Email: "",
                                        gstinNo: "",
                                        orgName: "",
                                    })
                                    console.log(this.state.list);

                                }
                                } variant="text">Cancel</Button>
                            </Box>

                        </Box>
                    </Modal>
                </div>

                <img style={{
                    //    display: display ?  `none`: `block`
                    width: "30em"
                }} src={logo} />

                <Loader />

            </Container>
        )
    }
}
