import { query, mutation } from "../../utils/graphapi"
import { DATA_LENGTH } from './Constant'

export function ViewDashboardData(org_id) {
  return async (dispatch) => {
    let requestBody = `
        categorys(org_id: "${org_id}") {
            _id  
          },
          subscribers(org_id: "${org_id}") {
            _id     
          }
        `
    const response = await query(dispatch, requestBody, 'Data Found');
    console.log('view all data', response)
    if (!response.errors && response.data) {
      dispatch(setLength(response.data.categorys.length, response.data.subscribers.length));
    }
    else {
      dispatch(setLength(0, 0));

    }
  };
}


export function setLength(category, subscriber) {
  console.log("abcddd", category)
  return {
    type: DATA_LENGTH,
    payload: {
      category,
      subscriber,
    },
  };
}

