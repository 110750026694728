import React, { Component } from "react";
import { connect } from "react-redux";
import AccountS from "../Components/AccountS";
import { close_snack_bar } from "../../../common/snackbar/action";
import {
    createAccount,
    viewAllAccounts,
    updateAccount,
    deleteAccount
} from "../Action";
// import { viewDepartment } from '../../Department/Action'
export class CategoryContainer extends Component {
    render() {
        return (
            <AccountS {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        account:store.account
    };
};
export const mapDispatchToProps = dispatch => {
    return {

        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        createAccount: (org_id, mail_transporter, email, password, cc, host, page, rowPerPage) => {
            dispatch(createAccount(org_id, mail_transporter, email, password, cc, host, page, rowPerPage));
        },

        viewAllAccounts: (org_id, page, rowPerPage) => {
            dispatch(viewAllAccounts(org_id, page, rowPerPage));
        },

        updateAccount: (org_id, account_id, mail_transporter, email, password, cc, host, page, rowPerPage) => {
            dispatch(updateAccount(org_id, account_id, mail_transporter, email, password, cc, host, page, rowPerPage));
        },
        deleteAccount: (org_id, accountId, page, rowPerPage) => {
            dispatch(deleteAccount(org_id, accountId, page, rowPerPage));
        },
       
       
    
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(CategoryContainer);