export const setCategorymutation = (orgId, category_name, category_dept) => {
    return {
        query: `
            mutation CreateCategory($categoryInput: CategoryInput!) {
                createCategory(categoryInput: $categoryInput) {
                    _id
                    name
                }
            }
        `,
        variables: {
            categoryInput: {
                org_id: orgId,
                name: category_name,
                department_id: category_dept,
            },
        },
    };
};

export const resetCategorymutation = (orgId, category_id, category_name, category_dept) => {
    return {
        query: `
        mutation UpdateCategory {
            updateCategory(category: {
             categoryId:"${category_id}",
              updatedCategory:{
                name:"${category_name}",
                department_id:"${category_dept}",
              }
            }) {
              _id
              name
            }
          }
        `,
        variables: {
            categoryInput: {
                org_id: orgId,
                _id: category_id,
                name: category_name,
                department_id: category_dept,
            },
        },
    };
};

export const delCategorymutation = (categoryId) => {
    return {
        query: `
        mutation {
            deleteCategory(categoryId: "${categoryId}") {
              _id
              name
              deleted
            }
          }
        `,
        variables: {
            categoryId: categoryId,
        },
    };
};

