import React, { Component } from 'react'
import {
  Grid,
  Typography,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Select,
  MenuItem,
  TextField,
  DialogActions,
  Button,
  CircularProgress
} from "@mui/material";
import SearchIcon from '../../image/search.png'
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import Icon from "@mui/material/Icon";
import LoaderCon from "../../../common/loader/containers/loader_cont";
import '../../../common/styles.scss';
import Snackbar from "../../../common/snackbar/components/snackbar"
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import '../../../common/introCss.scss'

export default class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      searching: false,
      addUser: false,
      EditUser: false,
      view: false,
      Userdelete: false,
      user_id: '',
      user_name: '',
      page: 0,
      rowsPerPage: 10,
      errors: {
        access: '',
        name: '',
        email: '',
        mobile: ''
      },

      userdata: [
        {
          rolename: " Owner ",
          UserName: "subh",
          email: "subh.technoboot.co",
          mobile: "9178888606"

        },
        {
          rolename: " Owner ",
          UserName: "jhbh",
          email: "subh.technoboot.co",
          mobile: "9178888606"

        },
        {

          rolename: " Owner ",
          UserName: "bh",
          email: "subh.technoboot.co",
          mobile: "9178888606"


        },
      ],
    };
  }


  validateFields = () => {
    const { access, name, email, mobile } = this.state;
    let errors = {};

    if (!access) errors.access = 'Role is required';
    if (!name) errors.name = 'Name is required';
    if (!email) {
      errors.email = 'Email is required';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) errors.email = 'Invalid email format';
    }
    if (!mobile) {
      errors.mobile = 'Mobile number is required';
    } else {
      const mobileRegex = /^\d{10}$/;
      if (!mobileRegex.test(mobile)) errors.mobile = 'Mobile number must be 10 digits';
    }

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  handleSubmit = () => {
    if (this.validateFields()) {
      const { name, email, mobile, access } = this.state;
      this.props.addProductUser(this.props.login.token, this.props.login.user_id, this.props.login.product, this.props.login.org_id, name, email, mobile, access, "U");
      this.setState({ addUser: false });
    }
  };
  startIntro = () => {
    const intro = introJs();
    intro.setOptions({
      steps: [
        {
          intro: 'Welcome to User!',
        },
        {
          element: '#tableUser', // Replace with the actual element ID or class
          intro: 'this is a list of User',
        },
        {
          element: '#addUser',
          intro: 'You can add your own User',
        },
        {
          element: '#editUser',
          intro: 'You can also make changes to particular User according to your needs',
        },
        {
          element: '#deleteUser',
          intro: 'You can delete unnecessary User',
        },
        {
          element: '#searchUser',
          intro: 'Search your department by name',
        },
      ],
    });
    intro.start();
    localStorage.setItem("user_intro", true)
  };

  componentDidMount() {
    this.props.viewProductUser(this.props.login.org_id, this.props.login.user_id, this.props.login.token, this.props.login.product, "U");
    if (localStorage.getItem("user_intro")) {
      this.startIntro();
    }

  }

  handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    this.setState({ rowsPerPage: newRowsPerPage, page: 0 });
    this.props.viewAllUser(this.props.login.org_id, 0, newRowsPerPage);
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
    // Fetch data with the new page value
    this.props.viewAllUser(this.props.login.org_id, newPage, this.state.rowsPerPage);
  };



  render() {
    const { snackbar, close_snack_bar } = this.props
    const { addUser, access, name, email, mobile, errors } = this.state;
    return (
      <Grid>
        <Grid container spacing={2} className='topic' alignItems="center">
          <Grid item xs={12} sm={12} md={12} lg={10.5} >
            <Typography className='topics2'>User</Typography>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={1.5} paddingRight={1} >
            <LoadingButton
              id="addCat"
              fullWidth
              style={{ height: '48px' }}
              className='add'
              startIcon={this.props.loader.progress ? '' : <AddRoundedIcon />}
              loading={this.props.loader.progress}
              loadingPosition='center'
              variant='contained'
              onClick={() => {
                this.setState({
                  addUser: true,
                });
              }}
            >
              {this.props.loader.progress ? (
                <CircularProgress
                  size={24} // Adjust the size of the circular loader as needed
                  style={{ color: 'white' }}
                />
              ) :
                <Typography> User</Typography>
              }
            </LoadingButton>
          </Grid>
        </Grid>
        <LoaderCon />
        <Grid container className='page'>
          <Grid item xs={12} className='box'>

            <Grid container spacing={2}>

              <Grid item xs={12} sm={12} md={12} lg={10}>
                {/* <FormControl fullWidth variant="standard" id='searchCat'>
                  <OutlinedInput
                    type={'text'}
                    className='textfield'
                    sx={{
                      '.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                        boxShadow: '0px 2px 0px lightgray',
                      },
                      '.MuiOutlinedInput-notchedOutline': { border: '1px solid #E2E0DD' },
                      height: '48px', width: "57vw"
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <LoadingButton
                            loading={this.state.searching}
                            loadingPosition="center"
                            variant="text"
                            onClick={() => {
                              this.setState({
                                add: true,
                              });
                            }}
                          >
                            {!this.state.searching &&
                              <img src={SearchIcon} alt="Search User" style={{ font: '24px' }} />
                            }
                          </LoadingButton>
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Search"
                    onChange={(event) => {
                      this.setState({ searching: event.target.value !== '' ? true : false });
                      this.props.searchUser(this.props.user.allUser, event.target.value);
                    }}
                  />
                </FormControl> */}
              </Grid>



              <Grid item xs={3} sm={3} md={3} lg={2} >
                {/* Adjust the xs value according to your layout */}
                {/* <LoadingButton
                  id="addCat"
                  fullWidth
                  style={{ height: '48px' }}
                  className='add'
                  startIcon={this.props.loader.progress ? '' : <AddRoundedIcon />}
                  loading={this.props.loader.progress}
                  loadingPosition='center'
                  variant='contained'
                  onClick={() => {
                    this.setState({
                      addUser: true,
                    });
                  }}
                >
                  {this.props.loader.progress ? (
                    <CircularProgress
                      size={24} // Adjust the size of the circular loader as needed
                      style={{ color: 'white' }}
                    />
                  ) :
                    <Typography> User</Typography>
                  }
                </LoadingButton> */}
              </Grid>
            </Grid>
            <Grid>
              <TableContainer id="tableCat" style={{ marginTop: '-20px' }}>
                <Table stickyHeader aria-label="sticky table" >
                  <TableHead className="customTableHead">
                    <TableRow>
                      <TableCell className='headtext'>
                        Sl.No
                      </TableCell>
                      <TableCell className='headtext' >
                        Name
                      </TableCell>
                      <TableCell className='headtext' >
                        Email
                      </TableCell>
                      <TableCell className='headtext'>
                        User Mobile
                      </TableCell>
                      <TableCell className='headtext'>
                        Access
                      </TableCell>
                      <TableCell className='headtext' textAlign={'center'} >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(this.props.user.admin_user) && this.props.user.admin_user.map((item, index) => {
                      console.log(this.props.user.admin_user, "ssssssss");
                      return (
                        <TableRow hover tabIndex={-1}>
                          <TableCell justifyContent={'center'} className='celltext'>
                            {index + 1}
                          </TableCell>
                          <TableCell justifyContent={'center'} className='celltext'>
                            {item.name}
                          </TableCell>
                          <TableCell justifyContent={'center'} className='celltext'>
                            {item.email_id}
                          </TableCell>
                          <TableCell justifyContent={'center'} className='celltext'>
                            {item.mobile}
                          </TableCell>
                          <TableCell justifyContent={'center'} className='celltext'>
                            {item.accesstype}
                          </TableCell>
                          <TableCell>
                            <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                              {/* <Icon
                                id='editCat'
                                class="material-symbols-outlined"
                                onClick={() => {
                                  this.setState({
                                    EditUser: true,
                                    name: item.name,
                                    email_id: item.email_id,
                                    mobile: item.mobile,
                                    type: item.type,
                                    user_id: item._id,
                                    access: item.accesstype
                                  });
                                }}
                                style={{
                                  color: "#FFA741",
                                  cursor: "pointer",
                                  padding: '8px',
                                  backgroundColor: 'rgba(255, 231, 203, 0.7)',
                                  borderRadius: 50,
                                  fontSize: '22px',
                                  marginLeft: "10%",
                                }}
                              >
                                edit_square
                              </Icon> */}
                              <Icon
                                id='deleteCat'
                                class="material-symbols-outlined"
                                onClick={() => {
                                  this.setState({
                                    Userdelete: true,
                                    user_id: item._id,
                                    // department_id: row._id,
                                  });
                                }}
                                style={{
                                  color: "#C43232",
                                  cursor: "pointer",
                                  padding: '8px',
                                  backgroundColor: 'rgba(255, 234, 227, 0.7)',
                                  borderRadius: 50,
                                  fontSize: '22px',
                                  marginLeft: "-50%",
                                }}
                              >
                                delete
                              </Icon>
                            </Grid>
                          </TableCell>

                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {/* <TablePagination
                  rowsPerPageOptions={[5, 10, 20, 50]}
                  component="div"
                // count={this.props.user.deptlength}
                // rowsPerPage={this.state.rowsPerPage}
                // page={this.state.page}
                // onPageChange={this.handleChangePage}
                // onRowsPerPageChange={this.handleChangeRowsPerPage}
                /> */}
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
        {/* <Snackbar
          open={snackbar.response_received}
          close_snack_bar={close_snack_bar}
          message={snackbar.message}
        /> */}
        {/*--------------------Add-------------------------*/}
        <Dialog
          sx={{
            backdropFilter: "blur(10px)",
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
          onClose={() => this.setState({ addUser: false })}
          open={addUser}
        >
          <Grid style={{ width: "595px", marginBottom: "8%" }}>
            <DialogTitle className='titlespace'>
              <Grid container marginTop={1}>
                <Grid item xs={.5} />
                <Grid item>
                  <Typography className='title'>Add User</Typography>
                </Grid>
              </Grid>
            </DialogTitle>

            <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#102844", fontWeight: 500, marginLeft: "8%" }}>Assign Role <span style={{ color: 'red' }}>*</span></Typography>

            <FormControl variant="standard" sx={{ m: 1, minWidth: 480, marginLeft: "8%" }}>
              <Select
                value={access}
                onChange={(e) => this.setState({ access: e.target.value })}
                error={!!errors.access}
              >
                <MenuItem value={"Owner"}>Owner</MenuItem>
                <MenuItem value={"Admin"}>Admin</MenuItem>
              </Select>
              {errors.access && <Typography color="error">{errors.access}</Typography>}
            </FormControl>

            <Typography style={{ fontSize: "15px", color: "#102844", fontWeight: 500, marginLeft: "8%" }}>User Name <span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              id="standard-basic"
              variant="standard"
              style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
              placeholder=' Enter User Name'
              onChange={(e) => this.setState({ name: e.target.value })}
              error={!!errors.name}
              helperText={errors.name}
            />

            <Typography style={{ fontSize: "15px", color: "#102844", fontWeight: 500, marginLeft: "8%", marginTop: "10px" }}>Email Id <span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              id="standard-basic"
              variant="standard"
              style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
              type="Email"
              placeholder=' Enter Email Id'
              onChange={(e) => this.setState({ email: e.target.value })}
              error={!!errors.email}
              helperText={errors.email}
            />

            <Typography style={{ fontSize: "15px", color: "#102844", fontWeight: 500, marginLeft: "8%", marginTop: "10px" }}>Mobile Number <span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              id="standard-basic"
              variant="standard"
              style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
              type="Number"
              placeholder=' Enter Mobile Number'
              onChange={(e) => this.setState({ mobile: e.target.value })}
              error={!!errors.mobile}
              helperText={errors.mobile}
            />

            <Grid style={{ marginTop: "20px", marginLeft: "8%", marginRight: "3%", display: "flex", flexDirection: "row" }}>
              <Button
                variant="outlined"
                style={{ fontSize: "15px", height: "48px", width: "249px", borderRadius: "4px", color: "#161412", borderColor: "rgb(43, 63, 84)" }}
                onClick={() => this.setState({ addUser: false })}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: "rgb(43, 63, 84)", fontSize: "15px", height: "48px", width: "249px", borderRadius: "4px", marginLeft: "10px" }}
                onClick={this.handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Dialog>

        {/*--------------------Edit-------------------------*/}
        <Dialog
          sx={{

            backdropFilter: "blur(10px)",
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
          onClose={() => this.setState({ EditUser: false })}
          open={this.state.EditUser} style={{}}>
          <Grid style={{ width: "595px", marginBottom: "8%" }}>

            <DialogTitle className='titlespace'>
              <Grid container marginTop={1}>
                <Grid item xs={.5} />
                <Grid item>
                  <Typography className='title'>Edit User</Typography>
                </Grid>
              </Grid>
            </DialogTitle>

            <Typography style={{ marginTop: "20px", fontSize: "15px", color: "#102844", fontWeight: 500, marginLeft: "8%" }}>Assign Role</Typography>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 480, marginLeft: "8%" }}>
              <Select
                value={this.state.access}
                onChange={(e) => {
                  this.setState({ access: e.target.value })
                }}
              >

                <MenuItem value={"Owner"}>Owner</MenuItem>
                <MenuItem value={"Admin"}>Admin</MenuItem>
              </Select>
            </FormControl>
            <Typography style={{ fontSize: "15px", color: "#102844", fontWeight: 500, marginLeft: "8%" }}>User Name</Typography>

            {/* <TextField id="standard-basic" label="Standard" /> */}

            <TextField id="standard-basic" variant="standard" style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
              // type="Email"
              value={this.state.name}
              placeholder=' Enter User Name'
              onChange={(e) => this.setState({ name: e.target.value })}
            >

            </TextField>
            <Typography style={{ fontSize: "15px", color: "#102844", fontWeight: 500, marginLeft: "8%", marginTop: "10px", }}>Email Id</Typography>

            {/* <TextField id="standard-basic" label="Standard" /> */}

            <TextField id="standard-basic" variant="standard" style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
              type="Email"
              placeholder=' Enter Email Id'
              value={this.state.email_id}
              onChange={(e) => this.setState({ email_id: e.target.value })}
            >

            </TextField>
            <Typography style={{ fontSize: "15px", color: "#102844", fontWeight: 500, marginLeft: "8%", marginTop: "10px", }}>Mobile Number</Typography>

            {/* <TextField id="standard-basic" label="Standard" /> */}

            <TextField id="standard-basic" variant="standard" style={{ marginLeft: '8%', marginTop: "10px", width: "470px" }}
              type="Number"
              placeholder=' Enter Mobile Number'
              value={this.state.mobile}
              onChange={(e) => this.setState({ mobile: e.target.value })}
            >

            </TextField>


            <Grid style={{ marginTop: "20px", marginLeft: "8%", marginRight: "3%", display: "flex", flexDirection: "row", }}>

              <Button variant="outlined"
                style={{ fontSize: "15px", height: "48px", width: "249px", borderRadius: "4px", color: "#161412", borderColor: "rgb(43, 63, 84)" }}

                onClick={() => {
                  this.setState({
                    EditUser: false,
                    name: "",
                    type: "",
                    email_id: "",
                    mobile: "",
                  })
                }}
              >

                Cancel
              </Button>
              <Button variant="contained"
                style={{ backgroundColor: "rgb(43, 63, 84)", fontSize: "15px", height: "48px", width: "249px", borderRadius: "4px", marginLeft: "10px" }}
                onClick={() => {
                  this.props.updateProductUser(this.state.user_id, this.state.name, this.state.email_id, this.state.mobile, this.state.access, this.props.login.org_id, "email")
                  this.setState({
                    EditUser: false, name: "",
                    type: "",
                    email_id: "",
                    mobile: "",
                  });
                }}

              >

                Update
              </Button>
            </Grid>


          </Grid>
        </Dialog>

        {/*--------------------Delete-------------------------*/}
        <Dialog

          open={this.state.Userdelete}
          maxWidth="sm"
          fullWidth
          sx={{

            backdropFilter: "blur(10px)",
            // 👇 Another option to style Paper
            "& .MuiDialog-paper": {
              borderRadius: "8px",
            },
          }}
        >
          <DialogTitle className='titlespace'>
            <Grid container marginTop={1}>
              <Grid item xs={.5} />
              <Grid item>
                <Typography className='title'>Delete User</Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container >
              <Grid item xs={.5} />
              <Grid item xs={11} direction='column'>
                <Typography >
                  Are you sure do you want to delete this User?
                </Typography>
              </Grid>
              <Grid item xs={.5} />
            </Grid>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'center', marginBottom: '5%', }} >
            <Button
              variant="outlined"
              className='cancel'
              onClick={() => {
                this.setState({ Userdelete: false, user_id: "" });
              }}
            >
              No, Cancel it.
            </Button>
            <Button
              variant="contained"
              className='submit'
              onClick={() => {
                this.props.deleteProductUser(this.props.login.org_id, this.state.user_id, this.props.login.token, this.props.login.product, "U")
                this.setState({
                  Userdelete: false
                });
              }}
            >
              Yes, Delete it.
            </Button>
          </DialogActions>
        </Dialog >

      </Grid>
    )
  }
}
